import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import { EventCreateDataType } from '../../../services/api/types/card';
import { CardType } from '../../../features/Card/types';
import CardPreview from '../../../features/Card/components/CreateCard/components/CardPreview';
import CreateCardContainer from '../../../features/Card/components/CreateCard/components/CreateCardContainer';
import FormTitle from '../../../features/Card/components/CreateCard/components/FormTitle';
import { FormPageType } from '../../../features/Card/components/CreateCard/types';
import EventCardForm from '../../EventCardForm';

type PropsType = {
  onSubmit: (data: any) => void;
  submitError: string;
  card?: CardType;
  closeFormUrl?: string;
  currentPage?: FormPageType;
  goFirstPage?: () => void;
  onClosePress?: () => void;
  loading: boolean;
};

export default function CardEventFormContainer(props: PropsType) {
  const {
    onSubmit,
    submitError,
    card,
    closeFormUrl,
    currentPage,
    goFirstPage,
    onClosePress,
    loading,
  } = props;
  const methods = useForm<EventCreateDataType>({
    defaultValues: {
      title: card?.title,
      description: card?.description,
      categoryId: card?.categoryId,
      photoCover: card?.photoCover,
      address: card?.event?.address,
      endTime: card?.event?.endTime
        ? new Date(card?.event?.endTime)
        : new Date(),
      startTime: card?.event?.startTime
        ? new Date(card?.event?.startTime)
        : new Date(),
      websiteLink: card?.websiteLink,
      ecoparkRegionId: card?.event?.ecoparkRegionId,
    },
  });

  return (
    <FormProvider {...methods}>
      <CreateCardContainer
        title={
          <FormTitle
            closeFormUrl={closeFormUrl}
            onClosePress={onClosePress}
            cardType="CardEvent"
            currentPage={currentPage}
            goFirstPage={goFirstPage}
          />
        }
        form={
          <EventCardForm
            loading={loading}
            onSubmit={onSubmit}
            submitError={submitError}
            card={card}
          />
        }
        example={<CardPreview cardType="CardEvent" type="preview" />}
      />
    </FormProvider>
  );
}
