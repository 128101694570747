import React, { forwardRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import className from 'classnames';
import style from './style.module.scss';
import RequiredLabel from '../../../RequiredLabel';
import getErrorKey from '../../../../utils/error';

interface PropsType {
  id: string;
  name: string;
  labelText?: string;
  error: FieldError | undefined;
  autoComplete?: boolean;
  defaultValue?: string;
  maxLength?: number;
  type?: 'text' | 'url' | 'password' | 'number';
  useErrorMessage?: boolean;
  placeholder?: string;
  klassName?: string;
  value?: string;
}

const InputGroupTag = forwardRef<HTMLInputElement, PropsType>((params, ref) => {
  const {
    id,
    name,
    labelText,
    error,
    autoComplete,
    defaultValue,
    maxLength,
    type,
    useErrorMessage,
    placeholder,
    klassName,
    value,
  } = params;
  const [currentValue, setCurrentValue] = useState<string>(defaultValue || '');
  const isExceedLength = maxLength && currentValue.length > maxLength;
  const inputClass = isExceedLength
    ? style.error
    : className({ [style.error]: error });

  const title = maxLength
    ? `${labelText} (${currentValue.length}/${maxLength})`
    : labelText;

  return (
    <div className={className(style.formGroup, klassName)}>
      {title && (
        <RequiredLabel
          htmlFor={name}
          title={title}
          error={
            useErrorMessage && error && error.message
              ? error.message
              : getErrorKey(error)
          }
        />
      )}
      <input
        id={id}
        defaultValue={defaultValue}
        name={name}
        type={type}
        className={inputClass}
        placeholder={placeholder}
        autoComplete={autoComplete ? 'on' : 'off'}
        ref={ref}
        value={value}
        onChange={(e) => {
          setCurrentValue(e.target.value);
        }}
      />
    </div>
  );
});

export default InputGroupTag;
