import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FormPageType, SelectableCardType } from '../../types';
import style from './style.module.scss';

type PropsType = {
  title?: string;
  closeFormUrl?: string;
  cardType?: SelectableCardType;
  currentPage?: FormPageType;
  goFirstPage?: () => void;
  onClosePress?: () => void;
};

export default function FormTitle(props: PropsType) {
  const { t } = useTranslation();
  const {
    title,
    cardType,
    closeFormUrl,
    currentPage,
    goFirstPage,
    onClosePress,
  } = props;

  function getFormTitle() {
    if (cardType) {
      return t(CardsTypeTitles[cardType]);
    }
    return title;
  }

  return (
    <div className={style.formTitle}>
      {currentPage === 2 && (
        <span
          aria-hidden
          className={style.backIcon}
          onClick={goFirstPage}
          onKeyDown={goFirstPage}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </span>
      )}
      <span className={style.formName}>{getFormTitle()}</span>
      {closeFormUrl && (
        <Link to={closeFormUrl}>
          <FontAwesomeIcon icon={faTimes} />
        </Link>
      )}
      {onClosePress && (
        <div className={style.closeIcon} onClick={onClosePress} aria-hidden>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
    </div>
  );
}

const CardsTypeTitles = {
  CardEvent: 'features.createCard.formTitle.event',
  CardNews: 'features.createCard.formTitle.news',
  CardPromotion: 'features.createCard.formTitle.promotion',
  unselect: 'features.createCard.formTitle.newCard',
};
