import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeepMap, ErrorOption, FieldError } from 'react-hook-form';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import { isNumber } from 'util';
import Button from '../../../Button';
import RequiredLabel from '../../../RequiredLabel';
import getCroppedImg from './cropImage';
import {
  ImageRequiredValidate,
  ImageValidateOnUpdate,
} from '../../../../utils/form/validate';
import getErrorKey from '../../../../utils/error';
import resizeImageFile from '../../../../utils/file';
import style from './style.module.scss';

interface PropsType {
  name: string;
  type: 'merchant' | 'event' | 'news';
  title: string;
  setValue: (name: string, value: File) => void;
  setError: (name: string, error: ErrorOption) => void;
  defaultValue?: any;
  selectedFile?: File; // use to get the previous selected file
  error?: DeepMap<any, FieldError>;
  placeholder?: string;
}

// Use this component when need the upload button and view the photo
const UploadImageGroupTag = forwardRef<HTMLInputElement, PropsType>(
  (params, ref: any) => {
    const { t } = useTranslation();
    const {
      name,
      title,
      type,
      setValue,
      defaultValue,
      error,
      setError,
      selectedFile,
      placeholder,
    } = params;
    const inputRef = React.createRef<HTMLInputElement>();
    const [uploadedImageUrl, setUploadedImageUrl] = useState<string>();
    const [resizedImage, setResizedImage] = useState<File>();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(undefined);
    const [isCropping, setCropping] = useState<Boolean>(false);
    const aspect = ASPECT_RATIO_TYPES[type];

    const onUploadImageClick = () => {
      if (isCropping) {
        applyImageFile();
      } else {
        inputRef.current!.click();
      }
    };

    const selectImageFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
      setCropping(true);

      if (e && e.target && e.target.files) {
        setError(name, { type: undefined });
        const file = e.target.files[0];
        if (file) {
          setUploadedImageUrl(URL.createObjectURL(file));
        }
        e.target.value = '';
      }
    };

    const applyImageFile = async () => {
      setCropping(false);

      const croppedImage = await getCroppedImg({
        imageSrc: uploadedImageUrl!,
        pixelCrop: croppedAreaPixels,
      });
      const resizedImg = await resizeImageFile(croppedImage);
      setResizedImage(resizedImg);
      setValue(name, resizedImg);
    };

    const onCropComplete = useCallback((_croppedArea, croppedAP) => {
      setCroppedAreaPixels(croppedAP);
    }, []);

    const onViewImage = () => window.open(getPreviewImage());

    const getPreviewImage = () => {
      if (resizedImage) {
        return URL.createObjectURL(resizedImage);
      }
      if (selectedFile) {
        return URL.createObjectURL(selectedFile);
      }
      return defaultValue;
    };

    useEffect(() => {
      if (defaultValue) {
        ref(name, ImageValidateOnUpdate());
      } else {
        ref(name, ImageRequiredValidate());
      }
      // eslint-disable-next-line
    }, [defaultValue]);

    useEffect(() => {
      if (selectedFile) {
        setValue(name, selectedFile);
      }
      // eslint-disable-next-line
    }, [selectedFile]);

    return (
      <div className={style.formGroup}>
        <RequiredLabel
          htmlFor={name}
          title={title}
          error={getErrorKey(error)}
        />

        <input
          ref={inputRef}
          accept="image/*"
          type="file"
          onChange={selectImageFile}
          hidden
        />

        {isCropping && (
          <div>
            <div className={style.cropContainer}>
              <Cropper
                image={uploadedImageUrl}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
              />
            </div>

            <div className={style.cropControls}>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(_e, newZoom) => {
                  if (isNumber(newZoom)) setZoom(newZoom);
                }}
              />
            </div>
          </div>
        )}

        {!isCropping && getPreviewImage() && (
          <div>
            <img
              className={style.previewImage}
              src={getPreviewImage()}
              onClick={onViewImage}
              role="presentation"
              alt=""
            />
          </div>
        )}

        <Button
          title={isCropping ? t('actions.apply') : t('actions.upload')}
          outlined
          onClick={onUploadImageClick}
          type="button"
          klassName={style.button}
        />
        {placeholder && <div className={style.description}>{placeholder}</div>}
      </div>
    );
  },
);

export default UploadImageGroupTag;

export const ASPECT_RATIO_TYPES = {
  merchant: 1,
  event: 2,
  news: 16 / 9,
};
