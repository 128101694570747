import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { faCog, faGift, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { CardStatus, CardType } from '../../features/Card/types';
import style from './style.module.scss';
import Trigger from '../Trigger';
import { MenuPropTypes } from '../Menu';
import CardStatusBadge from '../Badge/CardStatusBadge';
import { useCardsState } from '../../features/Card/reducers';
import RouterPath from '../../constants/RouterPath';
import { DialogContext } from '../Dialog/provider';

type PropsType = {
  card: CardType;
};

export default function Card(props: PropsType) {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const { card } = props;
  const cardTypes = {
    CardMerchant: 'Merchant',
    CardEvent: t('features.createCard.selectCardType.event'),
    CardNews: t('features.createCard.selectCardType.news'),
    CardPromotion: t('features.createCard.selectCardType.promotion'),
  };
  const cardOptions: MenuPropTypes[] = generateCardOptions(card);
  const [, cardActions] = useCardsState();
  const history = useHistory();

  function generateCardOptions(contentCard: CardType): MenuPropTypes[] {
    const { status, id, type } = contentCard;
    let actions;

    switch (status) {
      case 'draft':
        return [
          {
            title: t('actions.publishCard'),
            onClick: () => onUpdateCardStatus(id, 'published'),
          },
          {
            title: t('actions.delete'),
            onClick: () => onDeleteCardPress(id),
            klassName: style.deleteOption,
          },
        ];
      case 'published':
        actions = [
          {
            title: t('actions.retractCard'),
            onClick: () => onUpdateCardStatus(id, 'draft'),
          },
          {
            title: t('actions.analytics'),
            onClick: goToPromotionAnalytics,
          },
          {
            title: t('actions.delete'),
            onClick: () => onDeleteCardPress(id),
            klassName: style.deleteOption,
          },
        ];
        // Analytics for promotion only
        if (type !== 'CardPromotion') actions.splice(1, 1);
        return actions;
      default:
        return [
          {
            title: t('actions.delete'),
            onClick: () => onDeleteCardPress(id),
            color: '#EE5B46',
          },
        ];
    }
  }

  function onUpdateCardStatus(id: number, status: CardStatus) {
    cardActions.updateCard({ id, merchantId: card?.merchantId, status });
  }

  function onDeleteCardPress(id: number) {
    dialogContext.current?.show({
      message: t('components.cardList.deleteCardConfirmMessage', {
        cardName: card.title,
      }),
      type: 'warning',
      positiveTitle: t('actions.yes'),
      negativeTitle: t('actions.no'),
      onPositivePress: () => {
        cardActions.deleteCard({
          cardId: id,
          merchantId: card?.merchantId,
        });
      },
    });
  }

  function onGoEditCard() {
    const editCardUrl = RouterPath.CARD_EDIT.replace(
      ':merchantId',
      card?.merchantId.toString(),
    ).replace(':id', card.id.toString());
    history.push(editCardUrl);
  }

  function goToPromotionAnalytics() {
    const cardStatsUrl = RouterPath.CARD_STATS.replace(
      ':merchantId',
      card?.merchantId.toString(),
    ).replace(':id', card.id.toString());
    history.push(cardStatsUrl);
  }

  return (
    <div className={style.container} onClick={onGoEditCard} aria-hidden>
      <div className={style.rowCentered}>
        <div className={style.cardType}>{cardTypes[card.type]}</div>
        <CardStatusBadge status={card.status} />
      </div>
      <div className={style.row}>
        <div className={style.titleContainer}>
          <div className={style.title} title={card.title}>
            {card.title}
          </div>
          <div className={style.subtitle}>
            {`${card.author?.firstName} ${card.author?.lastName}`}
          </div>
        </div>
        <div className={style.date}>
          {card.createdAt && new Date(card.createdAt).toLocaleDateString()}
        </div>
      </div>
      <div className={style.row}>
        <div className={style.infoContainer}>
          <div className={style.infoItem}>
            <div className={style.icon}>
              <FontAwesomeIcon icon={faBookmark} />
            </div>
            <div>{card.totalFavorites}</div>
          </div>
          {card.type === 'CardPromotion' && (
            <div className={style.infoItem}>
              <div className={style.icon}>
                <FontAwesomeIcon icon={faGift} />
              </div>
              <div>{card.totalPromotionRedemptions}</div>
            </div>
          )}
        </div>
        <div onClick={(e) => e.stopPropagation()} aria-hidden>
          <Trigger options={cardOptions}>
            <FontAwesomeIcon icon={faCog} className={style.options} size="lg" />
          </Trigger>
        </div>
      </div>
    </div>
  );
}
