import React from 'react';
import RCTrigger from 'rc-trigger';
import style from './style.module.css';
import Menu, { MenuPropTypes } from '../Menu';

interface TriggerPropTypes {
  children: React.ReactElement;
  options: MenuPropTypes[];
}

export default function Trigger(props: TriggerPropTypes) {
  const { children, options } = props;

  return (
    <RCTrigger
      destroyPopupOnHide
      action={['click']}
      popup={<Menu options={options} />}
      popupAlign={{
        points: ['tr', 'br'],
      }}
      popupClassName={style.popup}
    >
      <div className={style.children}>{children}</div>
    </RCTrigger>
  );
}
