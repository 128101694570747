import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useUserState } from '../User/reducers';
import { useCardsState } from '../Card/reducers';
import RouterPath from '../../constants/RouterPath';
import { MerchantUpdateParamsType } from '../../services/api/types/merchant';
import { createMerchant } from '../Card/actions';
import NavBar from '../../components/NavBar';
import LegalInformationForm from './components/LegalInformationForm';
import useBeforeUnload from '../../utils/hooks/useBeforeUnload';
import Footer from '../../components/Footer';
import { DialogContext } from '../../components/Dialog/provider';
import MerchantCardForm from '../../components/MerchantCardForm';
import CardMerchantFormContainer from '../../components/CardFormContainer/Merchant';
import setPageTitle from '../../utils/pageTitle';
import removeSpace from '../../utils/string';

export default function MerchantCreate() {
  const [userState] = useUserState();
  const { user } = userState;
  const [, cardsActions] = useCardsState();
  const closeFormUrl = RouterPath.HOME_PAGE;
  const history = useHistory();
  const [submitError, setSubmitError] = useState<string>('');
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [merchantParams, setMerchantParams] = useState<
    MerchantUpdateParamsType
  >();
  const dialogContext = useContext(DialogContext);

  useEffect(() => {
    setPageTitle(t('features.createMerchant.title'));
  }, [t]);

  async function onCreateMerchantSubmit(params: MerchantUpdateParamsType) {
    setLoading(true);
    const result = await cardsActions.createMerchant(params);
    if (createMerchant.fulfilled.match(result)) {
      setMerchantParams(undefined);
      history.push(closeFormUrl);
      dialogContext.current?.show({
        message: t('features.createMerchant.createMerchantSuccessfully'),
        positiveTitle: t('actions.ok'),
      });
    } else if (result.error.message) setSubmitError(result.error.message);
    setLoading(false);
  }

  // Set new values and move to next step if a step is present. Otherwise submit
  function onSubmitButton(data: MerchantUpdateParamsType) {
    const updatedParams = {
      ...merchantParams,
      ...data,
    };
    setMerchantParams(updatedParams);
    if (activeStep === STEPS.length - 1) {
      const coerceEmailParams: MerchantUpdateParamsType = {
        ...updatedParams,
        businessEmail: removeSpace(updatedParams.businessEmail),
        email: removeSpace(updatedParams.email),
      };
      onCreateMerchantSubmit(coerceEmailParams!);
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  function onClickStep(index: number) {
    // can go back to previous step
    if (index < activeStep) {
      setActiveStep(index);
    }
  }

  function onClosePress() {
    dialogContext.current?.show({
      message: t('dialogs.exitCreateMerchantMessage'),
      type: 'warning',
      negativeTitle: t('actions.back'),
      positiveTitle: t('actions.exit'),
      onPositivePress: () => {
        history.push(closeFormUrl);
      },
    });
  }

  function initPage() {
    cardsActions.fetchCategories();
    cardsActions.fetchEcoparkRegions();
  }

  useEffect(initPage, []);

  useBeforeUnload(t('dialogs.exitCreateMerchantMessage'), true);

  function renderStepContent() {
    switch (activeStep) {
      case 0:
        return (
          <LegalInformationForm
            onSubmit={onSubmitButton}
            params={merchantParams}
          />
        );
      case 1:
        return (
          <MerchantCardForm
            onSubmit={onSubmitButton}
            submitError={submitError}
            loading={loading}
          />
        );
      default:
        return (
          <LegalInformationForm
            onSubmit={onSubmitButton}
            params={merchantParams}
          />
        );
    }
  }

  return (
    <>
      <NavBar user={user} />
      <CardMerchantFormContainer
        onClosePress={onClosePress}
        onSubmit={onSubmitButton}
        submitError={submitError}
        loading={loading}
        form={
          <>
            <Stepper activeStep={activeStep} alternativeLabel>
              {STEPS.map((step, index) => (
                <Step key={step.key} onClick={() => onClickStep(index)}>
                  <StepLabel>{t(step.name)}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {renderStepContent()}
          </>
        }
        livePreview={activeStep > 0}
        formTitle={t('features.createMerchant.title')}
      />
      <Footer />
    </>
  );
}

const STEPS = [
  {
    key: 'legal',
    name: 'features.createMerchant.legalInformation',
  },
  {
    key: 'display',
    name: 'features.createMerchant.displayInformation',
  },
];
