import moment from 'moment';

export function parseTimeString(
  format: MOMENT_DATE_FORMATS,
  time?: string,
): string {
  if (time) {
    const date = moment(time, format);
    if (date.isValid()) return date.format(format);
    return moment().format(format);
  }
  return moment().format(format);
}

export const DATE_FORMATS = {
  time: 'HH:mm',
  dateTime: 'HH:mm - dd/MM/yyyy',
  date: 'dd/MM/yyyy',
};

export enum MOMENT_DATE_FORMATS {
  time = 'HH:mm',
  dateTime = 'HH:mm - DD/MM/yyyy',
  date = 'DD/MM/yyyy',
}
