import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RequiredLabel from '../../../RequiredLabel';
import Button from '../../../Button';
import resizeImageFile from '../../../../utils/file';
import { getImageURL } from '../../../../utils/card';
import { ImageAttachment } from '../../../../features/Card/types';
import ImagePreviewModal from '../../../ImagePreviewModal';
import style from './style.module.scss';

interface PropsType {
  name: string;
  register: any;
  setValue: (name: string, value: File[] | number[]) => void;
  imageFiles?: File[];
  attachmentRemovedIds?: number[];
  defaultValue?: ImageAttachment[];
}

interface PreviewImage {
  type: 'upload' | 'attached';
  index?: number;
  attachedId?: number;
  photoUrl: string;
}

const MAX_FILES_LENGTH = 16;

export default function GalleryImage(props: PropsType) {
  const { t } = useTranslation();
  const inputRef = React.createRef<HTMLInputElement>();
  const [openPreview, setOpenPreview] = useState(false);
  const [previewSelected, setPreviewSelected] = useState<
    PreviewImage | undefined
  >(undefined);
  const {
    name,
    register,
    setValue,
    imageFiles,
    attachmentRemovedIds,
    defaultValue,
  } = props;

  const reachLimitUploaded = MAX_FILES_LENGTH <= totalImages();

  function onUploadImageClick() {
    inputRef.current!.click();
  }

  const selectImageFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!reachLimitUploaded && e && e.target && e.target.files) {
      const file = e.target.files[0];
      const resizedImg = await resizeImageFile(file);
      if (resizedImg !== undefined) {
        const newImageFiles = imageFiles
          ? [...imageFiles, resizedImg]
          : [resizedImg];
        setValue(name, newImageFiles);
      }
    }
  };

  function selectPreviewImage(index: number, file: File) {
    const photoUrl = getImageURL(file);
    if (photoUrl) {
      const previewImage: PreviewImage = { type: 'upload', index, photoUrl };
      setPreviewSelected(previewImage);
      setOpenPreview(true);
    }
  }

  function selectPreviewAttachment(attachment: ImageAttachment) {
    const previewImage: PreviewImage = {
      type: 'attached',
      attachedId: attachment.id,
      photoUrl: attachment.photoUrl,
    };
    setPreviewSelected(previewImage);
    setOpenPreview(true);
  }

  function removePreviewImage() {
    if (
      imageFiles &&
      previewSelected?.type === 'upload' &&
      previewSelected?.index !== undefined
    ) {
      imageFiles.splice(previewSelected.index, 1);
      setValue(name, imageFiles);
    } else if (
      previewSelected?.type === 'attached' &&
      previewSelected.attachedId !== undefined
    ) {
      const newIds = attachmentRemovedIds
        ? [...attachmentRemovedIds, previewSelected.attachedId]
        : [previewSelected.attachedId];
      setValue('attachmentRemovedIds', newIds);
    }
    setOpenPreview(false);
  }

  function attachedFile() {
    if (defaultValue)
      return defaultValue.filter(
        (file) => !attachmentRemovedIds?.includes(file.id),
      );
    return [];
  }

  function totalImages() {
    const totalUpload = imageFiles ? imageFiles.length : 0;
    const totalAttachedFile = attachedFile().length;
    return totalUpload + totalAttachedFile;
  }

  function registerValues() {
    register(name);
    register('attachmentRemovedIds');
  }

  useEffect(registerValues, []);

  return (
    <div className={style.formGroup}>
      <RequiredLabel
        htmlFor={name}
        title={t('components.galleryImage.labelText', {
          totalImage: totalImages(),
        })}
      />

      <input
        ref={inputRef}
        accept="image/*"
        type="file"
        onChange={selectImageFile}
        hidden
      />

      <div>
        {attachedFile().map((attachment, index) => {
          return (
            <img
              // eslint-disable-next-line react/no-array-index-key
              key={`attachment-${index}`}
              className={style.imageItem}
              src={attachment.photoUrl}
              role="presentation"
              alt=""
              onClick={() => selectPreviewAttachment(attachment)}
            />
          );
        })}
        {imageFiles &&
          imageFiles.map((file, index) => (
            <img
              // eslint-disable-next-line react/no-array-index-key
              key={`image-${index}`}
              className={style.imageItem}
              src={getImageURL(file)}
              role="presentation"
              alt=""
              onClick={() => selectPreviewImage(index, file)}
            />
          ))}
      </div>

      <ImagePreviewModal
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
        imageUrl={previewSelected?.photoUrl}
        handleDelete={removePreviewImage}
      />

      <Button
        title={t('actions.upload')}
        outlined
        onClick={onUploadImageClick}
        type="button"
        klassName={style.uploadButton}
        disabled={reachLimitUploaded}
      />
    </div>
  );
}
