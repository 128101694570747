import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';
import style from './style.module.scss';
import getErrorKey from '../../../../utils/error';
import Link, { LinkType } from '../../../Link';

interface PropsType {
  id: string;
  name: string;
  rules: object;
  control: Control<any>;
  labelText?: string;
  error?: FieldError | undefined;
  defaultValue?: boolean;
  link?: LinkType;
}

export default function CheckboxGroupTag(props: PropsType) {
  const {
    id,
    name,
    labelText,
    error,
    rules,
    control,
    defaultValue,
    link,
  } = props;

  return (
    <div className={style.formGroup}>
      <div className={style.row}>
        <Controller
          id={id}
          as={<Checkbox color="primary" />}
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
        />
        {labelText && <div aria-hidden>{labelText}</div>}
        {link && <Link {...link} />}
      </div>
      {error && <div className={style.error}>{getErrorKey(error)}</div>}
    </div>
  );
}
