import React, { useRef, useEffect } from 'react';
import Chartjs, { ChartConfiguration } from 'chart.js';

type ParamsType = {
  title?: string;
  labels: string[];
  data: number[];
  backgroundColor: string[];
};

export default function PieChart(params: ParamsType) {
  const { title, labels, data, backgroundColor } = params;

  const chartRef = useRef<HTMLCanvasElement>(null);

  const chartConfig: ChartConfiguration = {
    type: 'pie',
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
        },
      ],
    },
    options: {
      cutoutPercentage: 50,
      title: {
        display: true,
        text: title,
        position: 'top',
        padding: 20,
        fontSize: 16,
        fontColor: '#000',
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          padding: 10,
          boxWidth: 60,
          fontSize: 14,
        },
      },
    },
  };

  useEffect(() => {
    if (chartRef && chartRef.current) {
      // eslint-disable-next-line no-new
      new Chartjs(chartRef.current, chartConfig);
    }
  }, [chartRef, chartConfig]);

  return <canvas ref={chartRef} />;
}
