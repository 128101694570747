import React from 'react';
import { useTranslation } from 'react-i18next';
import StatsGroup from './components/StatsGroup';
import style from './style.module.scss';

interface PropsType {
  isAllCouponsSelected: boolean;
  clicked?: number;
  totalRedeemed?: number;
  conversionRate?: number;
  redeemedWithinMonth?: number;
}

export default function CouponStats(props: PropsType) {
  const { t } = useTranslation();
  const {
    isAllCouponsSelected,
    clicked,
    totalRedeemed,
    conversionRate,
    redeemedWithinMonth,
  } = props;

  return (
    <div className={style.statsGroup}>
      <StatsGroup
        type="normal"
        number={clicked || 0}
        details={
          isAllCouponsSelected
            ? t('components.couponStats.averageClicked')
            : t('components.couponStats.totalClicked')
        }
      />

      <StatsGroup
        type="normal"
        number={totalRedeemed || 0}
        details={
          isAllCouponsSelected
            ? t('components.couponStats.averageRedeemed')
            : t('components.couponStats.totalRedeemed')
        }
      />

      <StatsGroup
        type="conversionRate"
        number={conversionRate || 0}
        details={t('components.couponStats.conversionRate')}
      />

      {redeemedWithinMonth && (
        <StatsGroup
          type="topRedemption"
          number={redeemedWithinMonth}
          details={
            isAllCouponsSelected
              ? t('components.couponStats.topRedemptionsWithinMonth')
              : t('components.couponStats.topRedeemOneCouponWithinMonth')
          }
        />
      )}
    </div>
  );
}
