import React from 'react';
import classNames from 'classnames';
import style from './style.module.scss';

export interface MenuPropTypes {
  title: string;
  onClick: () => void;
  color?: string;
  klassName?: string;
}

interface PropTypes {
  options: MenuPropTypes[];
}

export default function Menu(props: PropTypes) {
  const { options } = props;
  return (
    <div className={style.menu}>
      {options.map((item, index) => {
        const { title, onClick, color } = item;
        if (options.length === 1) {
          return (
            <div
              aria-hidden
              key={item.title}
              className={classNames(style.menuItemOnly, item.klassName)}
              style={{ color }}
              onClick={onClick}
            >
              {title}
            </div>
          );
        }
        if (index === 0) {
          return (
            <div
              aria-hidden
              key={item.title}
              className={classNames(style.menuItemFirst, item.klassName)}
              style={{ color }}
              onClick={onClick}
            >
              {title}
            </div>
          );
        }
        if (index === options.length - 1) {
          return (
            <div
              aria-hidden
              key={item.title}
              className={classNames(style.menuItemLast, item.klassName)}
              style={{ color }}
              onClick={onClick}
            >
              {title}
            </div>
          );
        }
        return (
          <div
            aria-hidden
            key={item.title}
            className={classNames(style.menuItem, item.klassName)}
            style={{ color }}
            onClick={onClick}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
}
