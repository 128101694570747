import React from 'react';
import RCPagination from 'rc-pagination';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import { PER_PAGE } from '../../services/api/types/api';

interface PropTypes {
  onPageChange: (param: number) => void;
  total: number;
  current: number;
}

export default function Pagination(params: PropTypes) {
  const { total, onPageChange, current } = params;
  return (
    <RCPagination
      current={current}
      total={total}
      pageSize={PER_PAGE}
      onChange={(currentPage) => onPageChange(currentPage)}
      showTitle={false}
      prevIcon={<FontAwesomeIcon icon={faChevronLeft} color="#006064" />}
      nextIcon={<FontAwesomeIcon icon={faChevronRight} color="#006064" />}
      jumpNextIcon={<FontAwesomeIcon icon={faEllipsisH} color="#006064" />}
      jumpPrevIcon={<FontAwesomeIcon icon={faEllipsisH} color="#006064" />}
    />
  );
}
