import React from 'react';
import style from './style.module.scss';

interface PropTypes {
  html: string;
}

export default function HtmlContent(props: PropTypes) {
  const { html } = props;
  return (
    <div
      className={style.htmlContent}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}
