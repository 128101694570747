import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { MerchantEditorType } from '../../features/Merchant/types';
import { DialogContext } from '../Dialog/provider';
import { useMerchantState } from '../../features/Merchant/reducers';

import Trigger from '../Trigger';
import { MenuPropTypes } from '../Menu';
import style from './style.module.scss';

type PropsType = {
  editor: MerchantEditorType;
};

export default function MerchantEditor(props: PropsType) {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const [, merchantActions] = useMerchantState();
  const { editor } = props;

  const username = `${editor.editor.firstName} ${editor.editor.lastName}`;
  const userInfo = `${username} (${editor.editor.phoneNumber})`;
  const editorOptions: MenuPropTypes[] = [
    {
      title: t('features.merchantEditor.remove'),
      onClick: () => removeEditorHandle(),
      klassName: style.deleteOption,
    },
  ];

  function removeEditorHandle() {
    dialogContext.current?.show({
      message: t('features.merchantEditor.removeConfirm', {
        userInfo,
        businessName: editor.merchantName,
      }),
      type: 'warning',
      positiveTitle: t('actions.yes'),
      negativeTitle: t('actions.no'),
      onPositivePress: async () => {
        merchantActions.removeMerchantEditor({
          id: editor.id,
          merchantId: editor.merchantId,
        });
      },
    });
  }

  return (
    <div className={style.element}>
      <div className={style.header}>
        <span className={style.editorName}>{username}</span>
        <Trigger options={editorOptions}>
          <FontAwesomeIcon icon={faCog} className={style.options} size="lg" />
        </Trigger>
      </div>
      <span className={style.editorPhone}>{editor.editor.phoneNumber}</span>
    </div>
  );
}
