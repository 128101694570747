import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useUserState } from '../../../User/reducers';
import { useMerchantState } from '../../reducers';

import NavBar from '../../../../components/NavBar';
import Footer from '../../../../components/Footer';
import RouterPath from '../../../../constants/RouterPath';
import TotalStats from '../../../Card/components/CardStats/components/TotalStats';
import AgeAndAreaPieCharts from '../../../../components/AgeAndAreaPieChart';
import Loader from '../../../../components/Loader';
import style from './style.module.scss';
import AllCouponStats from './components/AllCouponsStats';
import setPageTitle from '../../../../utils/pageTitle';

export default function MerchantStats() {
  const history = useHistory();
  const { t } = useTranslation();
  const [userState] = useUserState();
  const { merchantId } = useParams();
  const { user } = userState;
  const [merchantState, merchantActions] = useMerchantState();
  const backUrl = RouterPath.MERCHANT_SHOW.replace(':merchantId', merchantId);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function loadMerchantStats() {
    setIsLoading(true);
    await merchantActions.getMerchantStats({ merchantId });
    setIsLoading(false);
  }

  useEffect(() => {
    loadMerchantStats();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageTitle(t('features.cardStats.title'));
  }, [t]);

  if (isLoading) return <Loader />;

  return (
    <>
      <NavBar user={user} />
      <section className={style.section}>
        <div className={style.title}>
          <div className={style.titleContainer}>
            <div
              aria-hidden
              className={style.backButton}
              onClick={() => history.push(backUrl)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <span>{t('features.cardStats.title')}</span>
          </div>
        </div>

        <div className={style.ecooneStatsNote}>
          {t('features.merchantStats.ecooneDataSubTitle')}
        </div>

        <div className={style.chartTitle}>
          {t('features.merchantStats.allCardsStats')}
        </div>

        <TotalStats
          totalUserClicked={merchantState.stats?.totalUniqUserClicked}
          totalPromotionRedemptions={merchantState.stats?.totalFavorites}
          userClickedTitle={t(
            'features.merchantStats.allCardsNumberUniqueUserClicked',
          )}
          totalRedeemTitle={t('features.merchantStats.totalFavorites')}
        />

        <div className={style.statsNote}>
          {t('features.merchantStats.allCardSubTitle')}
        </div>

        <AgeAndAreaPieCharts
          ageStats={merchantState.stats?.clickedByUserAgeSegments}
          areaStats={merchantState.stats?.clickedByUserAreaSegments}
        />

        <AllCouponStats
          merchantId={merchantId}
          merchantStats={merchantState.stats}
        />
      </section>
      <Footer />
    </>
  );
}
