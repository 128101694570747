import { createAsyncThunk } from '@reduxjs/toolkit';
import { isArray } from 'util';
import {
  GetMerchantEditorsParams,
  AddEditorParamsType,
  RemoveEditorParamsType,
  GetMerchantStatsParams,
} from '../../../services/api/types/requestParams';
import { RootState } from '../../../store';
import MerchantApi from '../../../services/api/merchant';
import MerchantCardApi from '../../../services/api/merchant_card';

export const fetchMerchantEditors = createAsyncThunk(
  'merchant/fetchMerchantEditors',
  async (params: GetMerchantEditorsParams, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const merchantApi = new MerchantApi(dispatch, userToken);
    const res = await merchantApi.fetchMerchantEditors(params);

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return Promise.reject(res);
  },
);

export const addMerchantEditor = createAsyncThunk(
  'merchant/addMerchantEditor',
  async (params: AddEditorParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const merchantApi = new MerchantApi(dispatch, userToken);
    const res = await merchantApi.addMerchantEditor(params);

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return Promise.reject(res);
  },
);

export const validateNewMerchantEditor = createAsyncThunk(
  'merchant/validateNewMerchantEditor',
  async (
    params: AddEditorParamsType,
    { dispatch, getState, rejectWithValue },
  ) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const merchantApi = new MerchantApi(dispatch, userToken);
    const res = await merchantApi.validateNewMerchantEditor(params);

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    if (isArray(res.errors)) {
      return rejectWithValue(res.errors[0]);
    }
    return rejectWithValue(res.errors.code);
  },
);

export const removeMerchantEditor = createAsyncThunk(
  'merchant/removeMerchantEditor',
  async (params: RemoveEditorParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const merchantApi = new MerchantApi(dispatch, userToken);
    const res = await merchantApi.removeMerchantEditor(params);

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return Promise.reject(res);
  },
);

export const getMerchantsStats = createAsyncThunk(
  'merchants/getStats',
  async (params: GetMerchantStatsParams, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const merchantApi = new MerchantCardApi(dispatch, userToken);
    const res = await merchantApi.fetchMerchantStats(params);

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return Promise.reject(res);
  },
);
