import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeepMap } from 'react-hook-form';
import { FormPropsType } from '../Form';
import Button from '../Button';
import { CardStatus } from '../../features/Card/types';
import style from './style.module.scss';

type CardFormPropsType = FormPropsType & {
  setValue: (name: string, value: string) => void;
  loading?: boolean;
  submitError: string;
  errors: DeepMap<any, any>;
  register?: any;
};

export default function CardForm(props: CardFormPropsType) {
  const { t } = useTranslation();
  const {
    onSubmit,
    children,
    id,
    setValue,
    loading,
    submitError,
    errors,
    register,
  } = props;
  const submitRef = createRef<HTMLInputElement>();
  const [showError, setShowError] = useState<boolean>(false);

  function handleFormSubmit(status: CardStatus) {
    setValue('status', status);
    setShowError(true);
    setTimeout(() => setShowError(false), 2000);
    submitRef.current?.click();
  }

  return (
    <form id={id} onSubmit={onSubmit}>
      {children}
      <div>
        <Button
          title={t('actions.publishCard')}
          klassName={style.submitButton}
          loading={loading}
          onClick={() => handleFormSubmit('published')}
        />
        <Button
          outlined
          title={t('actions.saveDraft')}
          klassName={style.submitButton}
          loading={loading}
          onClick={() => handleFormSubmit('draft')}
        />
      </div>

      {showError && (submitError || Object.keys(errors).length > 0) && (
        <div className={style.error}>
          {submitError || t('errors.form.checkFormErrors')}
        </div>
      )}

      <input type="text" name="status" ref={register} hidden />

      <input type="submit" ref={submitRef} hidden />
    </form>
  );
}
