import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventCreateDataType } from '../../services/api/types/card';
import CardForm from '../CardForm';
import InputGroupTag from '../Form/components/InputGroupTag';
import style from './style.module.scss';
import UploadImageGroupTag from '../Form/components/UploadImageGroupTag';
import DropdownGroupTag from '../Form/components/DropdownGroupTag';
import { useCardsState } from '../../features/Card/reducers';
import DateTimeInputGroupTag from '../Form/components/DateTimeInputGroupTag';
import {
  DateTimeValidate,
  DescriptionValidate,
  RequiredValidate,
  TITLE_MAX_LENGTH,
  TitleValidate,
  UrlValidate,
} from '../../utils/form/validate';
import CKEditorGroupTag from '../Form/components/CKEditorGroupTag';
import { CardType } from '../../features/Card/types';
import Loader from '../Loader';

interface EventCardFormProps {
  onSubmit: (data: EventCreateDataType) => void;
  submitError: string;
  loading: boolean;
  card?: CardType;
}

export default function EventCardForm(params: EventCardFormProps) {
  const { t } = useTranslation();
  const { submitError, onSubmit, loading, card } = params;
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    control,
    setError,
    watch,
  } = useFormContext<EventCreateDataType>();
  const [cardsState] = useCardsState();
  const startTime = watch('startTime');

  return loading ? (
    <Loader />
  ) : (
    <CardForm
      register={register}
      onSubmit={handleSubmit(onSubmit)}
      setValue={setValue}
      loading={loading}
      submitError={submitError}
      errors={errors}
    >
      <InputGroupTag
        id="title"
        name="title"
        labelText={t('components.cardPromotion.title')}
        maxLength={TITLE_MAX_LENGTH}
        error={errors.title}
        autoComplete={false}
        ref={register(TitleValidate)}
        defaultValue={card?.title}
      />

      <div className={style.banner}>
        <UploadImageGroupTag
          name="photoCover"
          title={t('components.cardEvent.photoCover')}
          ref={register}
          setValue={setValue}
          setError={setError}
          error={errors.photoCover}
          defaultValue={card?.photoCover}
          type="event"
          placeholder={t('components.cardEvent.photoCoverPlaceHolder')}
        />
      </div>

      <div className={style.rowGroup}>
        <DateTimeInputGroupTag
          id="start-time"
          name="startTime"
          control={control}
          labelText={t('components.cardEvent.startDate')}
          error={errors.startTime}
          rules={RequiredValidate}
          type="dateTime"
          placeHolder={t('placeholders.clickToSelect')}
        />

        <DateTimeInputGroupTag
          id="end-time"
          name="endTime"
          control={control}
          labelText={t('components.cardEvent.endDate')}
          error={errors.endTime}
          rules={DateTimeValidate(startTime)}
          type="dateTime"
          placeHolder={t('placeholders.clickToSelect')}
        />
      </div>

      <div className={style.rowGroup}>
        <InputGroupTag
          id="address"
          name="address"
          labelText={t('components.cardEvent.streetAddress')}
          error={errors.address}
          autoComplete={false}
          ref={register(RequiredValidate)}
          defaultValue={card?.event?.address}
        />

        <DropdownGroupTag
          id="ecopark-region-id"
          name="ecoparkRegionId"
          ref={register({ required: true })}
          title={t('components.cardEvent.ecoparkRegion')}
          data={cardsState.ecoparkRegions}
          renderItem={(item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          )}
        />
      </div>

      <InputGroupTag
        id="website-link"
        name="websiteLink"
        labelText={t('components.cardEvent.link')}
        error={errors.websiteLink}
        autoComplete={false}
        ref={register(UrlValidate)}
        type="url"
        defaultValue={card?.websiteLink}
        placeholder={t('placeholders.url')}
      />

      <CKEditorGroupTag
        name="description"
        labelText={t('components.cardEvent.content')}
        rules={DescriptionValidate}
        control={control}
        error={errors.description}
        imageUploadable
      />
    </CardForm>
  );
}
