import React, { ReactNode } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from '@material-ui/icons';
import style from './style.module.scss';
import Logo from '../../../../components/Logo';
import RouterPath from '../../../../constants/RouterPath';
import LanguageSwitch from '../../../../components/LanguageSwitch';

interface PropTypes {
  children: ReactNode;
  title: string;
  noSignInLink?: boolean;
  backButton?: boolean;
  languageSwitch?: boolean;
}

export default function AuthContainer(props: PropTypes) {
  const {
    children,
    title,
    noSignInLink,
    backButton,
    languageSwitch = true,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={style.screen}>
      <div className={style.logoContainer}>
        <Link to={RouterPath.HOME_PAGE}>
          <Logo type="light" />
        </Link>
      </div>
      <div className={style.contentContainer}>
        <div className={style.contentScroll}>
          {languageSwitch && (
            <div className={style.languageSwitchContainer}>
              <LanguageSwitch />
            </div>
          )}
          <div className={style.mainContent}>
            <Link to={RouterPath.HOME_PAGE}>
              <Logo type="light" className={style.logoMobile} />
            </Link>
            <div className={style.titleContainer}>
              {backButton && (
                <div
                  className={style.titleLeft}
                  aria-hidden
                  onClick={() => history.goBack()}
                >
                  <ChevronLeft />
                </div>
              )}
              <h1 className={style.title}>{title}</h1>
              {backButton && <div className={style.titleRight} />}
            </div>
            {children}
          </div>
          {!noSignInLink && (
            <div className={style.signIn}>
              {`${t('features.signUpForm.alreadyHaveAnAccount')} `}
              <Link to={RouterPath.LOGIN}>
                {t('features.signUpForm.signIn')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
