import React from 'react';
import { SVGCardPropTypes } from '../../../features/Card/components/CreateCard/components/CardTypeSelection';

export default function SvgEventCard(props: SVGCardPropTypes) {
  const { backgroundColor, primaryColor, secondaryColor } = props;
  return (
    <svg width={169} height={214} fill="none">
      <rect
        x={0.5}
        y={0.5}
        width={168}
        height={213}
        rx={11.5}
        fill={backgroundColor}
        stroke={secondaryColor}
      />
      <path stroke={secondaryColor} strokeWidth={8} d="M34 109h45" />
      <path
        stroke={primaryColor}
        strokeWidth={4}
        d="M13 127h146M13 160h146M13 138h146M13 171h146M13 149h146M13 182h146"
      />
      <circle cx={20} cy={109} r={7} fill={secondaryColor} />
      <path
        d="M0 12C0 5.373 5.373 0 12 0h145c6.627 0 12 5.373 12 12v39H0V12z"
        fill={secondaryColor}
      />
      <path
        d="M89.5 23.5v-7a1 1 0 00-1-1h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1zm-6.25-2.75l1.25 1.503L86.25 20l2.25 3h-7l1.75-2.25z"
        fill={backgroundColor}
      />
      <rect
        x={13.5}
        y={36.5}
        width={145}
        height={55}
        rx={7.5}
        fill={backgroundColor}
        stroke={secondaryColor}
      />
      <path stroke={primaryColor} strokeWidth={8} d="M22 67h107" />
      <path
        stroke={secondaryColor}
        strokeWidth={8}
        strokeLinecap="round"
        d="M26 51h19"
      />
      <path
        stroke={secondaryColor}
        strokeWidth={4}
        strokeLinecap="round"
        d="M23 80h33M94 80h33"
      />
      <circle cx={144} cy={52} r={6} fill="#C4C4C4" />
    </svg>
  );
}
