import i18next from 'i18next';
import { ValidationRules } from 'react-hook-form';

export const MAX_FILE_SIZE_KB = 500;
export const TITLE_MAX_LENGTH = 60;
export const BUSINESS_NAME_MAX_LENGTH = 60;

export const RequiredValidate = {
  required: true,
};

export const SubcategoryValidation: ValidationRules = {
  required: true,
  validate: (value: number[]) => {
    if (!value || value.length === 0) {
      return 'errors.required';
    }
    return undefined;
  },
};

export const MerchantPhoneNumberValidation = {
  required: true,
  pattern: {
    value: /(^84|0)[0-9]{9,12}$/i,
    message: i18next.t('errors.merchantPhoneNumber'),
  },
};

export const RequiredCheckedValidate = {
  validate: (value: any) => {
    if (!value) {
      return 'errors.accept';
    }
    return undefined;
  },
};

export const TitleValidate = {
  required: true,
  minLength: 4,
  maxLength: TITLE_MAX_LENGTH,
};

export const EmailValidate = {
  pattern: /[\w+\-.]+@[a-z\d]+(\.[a-z\d]+)*\.[a-z]+/i,
};

export const DescriptionValidate = {
  required: true,
  validate: (value: string) => {
    if (value && value.length < 5) {
      return 'errors.description.minLength';
    }
    return undefined;
  },
};

export function SomeSelectedValidate(values: boolean[]): ValidationRules {
  return {
    validate: () => {
      if (!values.some((value) => value)) {
        return 'errors.required';
      }
      return undefined;
    },
  };
}

export function ImageRequiredValidate(): ValidationRules {
  return {
    validate: (value) => {
      if (!value) return 'errors.required';
      return validateFileSize(value);
    },
  };
}

export function ImageValidateOnUpdate(): ValidationRules {
  return {
    validate: (value) => {
      return validateFileSize(value);
    },
  };
}

export function DateTimeValidate(startTime?: Date): ValidationRules {
  return {
    required: true,
    validate: (value: Date) => {
      if (startTime) {
        if (value <= startTime) {
          return 'errors.dateTime.endTimeInvalid';
        }
      }
      return undefined;
    },
  };
}

function validateFileSize(value: File): string | undefined {
  if (value) {
    const sizeKB = value.size / 1024;
    if (sizeKB > MAX_FILE_SIZE_KB) {
      return i18next.t('errors.image.maxSize', {
        max: MAX_FILE_SIZE_KB,
      });
    }
  }
  return undefined;
}

export const UrlValidate = {
  validate: (value: any) => {
    if (value && !/^https:\/\//.test(value)) {
      return 'errors.url.startWithHttps';
    }
    return undefined;
  },
};
