import React, { useState } from 'react';
import SvgNewsCard from '../../../../../../components/SVG/NewsCard';
import SvgEventCard from '../../../../../../components/SVG/EventCard';
import SvgCoupon from '../../../../../../components/SVG/Coupon';
import { SelectableCardType } from '../../types';
import style from './style.module.scss';
import { getCardTitle } from '../../../../../../utils/card';

const colors = {
  primary: {
    teal: '#006064',
    white: '#FFFFFF',
  },
  secondary: {
    lightTeal: '#82E9DE',
    middleGrey: '#BCBCBC',
    darkGrey: '#767676',
  },
};

interface CardTypeSelectionPropTypes {
  type: SelectableCardType;
  active: boolean;
  onClick: () => void;
}

export interface SVGCardPropTypes {
  backgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
}

export default function CardTypeSelection(props: CardTypeSelectionPropTypes) {
  const { type, active, onClick } = props;
  const [backgroundColor, setBackgroundColor] = useState<string>(
    colors.primary.white,
  );
  const [primaryColor, setPrimaryColor] = useState<string>(
    colors.secondary.darkGrey,
  );
  const [secondaryColor, setSecondaryColor] = useState<string>(
    colors.secondary.middleGrey,
  );
  const [textColor, setTextColor] = useState<string>('black');
  const cardTitle = getCardTitle(type);

  function onClickHandler() {
    onMouseLeave();
    if (onClick) {
      onClick();
    }
  }

  function onMouseEnter() {
    setPrimaryColor(colors.secondary.lightTeal);
    setSecondaryColor(colors.secondary.lightTeal);
    setBackgroundColor(colors.primary.white);
    setTextColor(colors.secondary.lightTeal);
  }

  function onMouseLeave() {
    setPrimaryColor(colors.secondary.darkGrey);
    setSecondaryColor(colors.secondary.middleGrey);
    setBackgroundColor(colors.primary.white);
    setTextColor('black');
  }

  return (
    <>
      {active ? (
        <div className={style.activeCard}>
          {renderCardSVG(type, {
            primaryColor: colors.secondary.middleGrey,
            backgroundColor: colors.primary.teal,
            secondaryColor: '#EFEFEF',
          })}
          <div className={style.title} style={{ color: colors.primary.white }}>
            {cardTitle}
          </div>
        </div>
      ) : (
        <div
          onClick={onClickHandler}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={style.card}
          style={{ backgroundColor }}
          aria-hidden
        >
          {renderCardSVG(type, {
            primaryColor,
            backgroundColor,
            secondaryColor,
          })}
          <div className={style.title} style={{ color: textColor }}>
            {cardTitle}
          </div>
        </div>
      )}
    </>
  );
}

function renderCardSVG(type: SelectableCardType, cardColors: SVGCardPropTypes) {
  const { backgroundColor, primaryColor, secondaryColor } = cardColors;
  switch (type) {
    case 'CardPromotion':
      return (
        <SvgCoupon
          backgroundColor={backgroundColor}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      );
    case 'CardEvent':
      return (
        <SvgEventCard
          backgroundColor={backgroundColor}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      );
    case 'CardNews':
      return (
        <SvgNewsCard
          backgroundColor={backgroundColor}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      );
    default:
      return <></>;
  }
}
