import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import RouterPath from '../../constants/RouterPath';
import { signIn } from '../User/actions';
import { useUserState } from '../User/reducers';
import SignInForm from './components/SignInForm';
import { SignInParamsType } from '../../services/api/types/user';
import setPageTitle from '../../utils/pageTitle';

export default function SignInScreen() {
  const history = useHistory();
  const { t } = useTranslation();
  const [userState, userActions] = useUserState();
  const [isSubmitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userState) userActions.signOut();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageTitle(t('features.signInForm.formTitle'));
  }, [t]);

  const onSubmit = async (data: SignInParamsType) => {
    setLoading(true);
    const result = await userActions.signIn(data);
    setLoading(false);

    if (signIn.fulfilled.match(result)) {
      if (result.payload.user?.status === 'inactive') {
        history.push(RouterPath.OTP);
      } else {
        history.push(RouterPath.HOME_PAGE);
      }
    } else {
      setSubmitError(true);
    }
  };

  function onSignUp() {
    history.push(RouterPath.SIGNUP);
  }

  return (
    <SignInForm
      apiError={t(userState!.apiError!)}
      onSubmit={onSubmit}
      isSubmitError={isSubmitError}
      onSignUp={onSignUp}
      loading={loading}
    />
  );
}
