import React from 'react';
import Dialog, { DialogRef } from './index';

export const DialogContext = React.createContext<
  React.MutableRefObject<DialogRef | undefined>
>({ current: undefined });

interface DialogProviderProps {
  children: React.ReactNode;
}

export function DialogProvider(props: DialogProviderProps) {
  const dialogRef = React.useRef<DialogRef>();
  const { children } = props;
  return (
    <>
      <DialogContext.Provider value={dialogRef}>
        {children}
      </DialogContext.Provider>
      <Dialog dialogRef={dialogRef} />
    </>
  );
}
