import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectableCardType } from '../../types';
import style from './style.module.scss';
import CardTypeSelection from '../CardTypeSelection';
import Button from '../../../../../../components/Button';

type PropsType = {
  cardType: SelectableCardType;
  setCardType: Dispatch<SetStateAction<SelectableCardType>>;
  goSecondPage: () => void;
};

export default function SelectCardTypeForm(props: PropsType) {
  const { cardType, setCardType, goSecondPage } = props;
  const { t } = useTranslation();
  const nextBtnDisabled: boolean = cardType === 'unselect';

  return (
    <div className={style.container}>
      <div className={style.selection}>
        <CardTypeSelection
          type="CardPromotion"
          onClick={() => setCardType('CardPromotion')}
          active={cardType === 'CardPromotion'}
        />
        <CardTypeSelection
          type="CardNews"
          onClick={() => setCardType('CardNews')}
          active={cardType === 'CardNews'}
        />
        <CardTypeSelection
          type="CardEvent"
          onClick={() => setCardType('CardEvent')}
          active={cardType === 'CardEvent'}
        />
      </div>
      <div className={style.buttonContainer}>
        <Button
          title={t('features.createCard.selectCardType.next')}
          onClick={goSecondPage}
          disabled={nextBtnDisabled}
        />
      </div>
    </div>
  );
}
