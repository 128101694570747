import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { MerchantStateType, MerchantEditorType, MerchantStats } from '../types';
import { RootState, useAppDispatch } from '../../../store';
import {
  fetchMerchantEditors,
  addMerchantEditor,
  validateNewMerchantEditor,
  removeMerchantEditor,
  getMerchantsStats,
} from '../actions';
import {
  ApiSuccessListType,
  ApiSuccessType,
} from '../../../services/api/types/api';
import {
  GetMerchantEditorsParams,
  AddEditorParamsType,
  RemoveEditorParamsType,
  GetMerchantStatsParams,
} from '../../../services/api/types/requestParams';

const initialState: MerchantStateType = {
  editors: [],
  totalMerchantEditors: 0,
  stats: {
    totalUniqUserClicked: 0,
    totalFavorites: 0,
    totalCouponCards: 0,
    totalUserClickedCoupons: 0,
    totalPromotionRedemptions: 0,
    clickedByUserAgeSegments: [],
    clickedByUserAreaSegments: [],
    redeemedByUserAgeSegments: [],
    redeemedByUserAreaSegments: [],
    listCoupons: [],
  },
};

const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchMerchantEditors.fulfilled,
      (
        state,
        action: PayloadAction<ApiSuccessListType<MerchantEditorType[]>>,
      ) => {
        if (action.payload.result.data) {
          state.editors = action.payload.result.data;
          state.totalMerchantEditors = action.payload.result.totalEntries || 0;
        } else {
          state.editors = [];
          state.totalMerchantEditors = 0;
        }
      },
    );
    builder.addCase(fetchMerchantEditors.rejected, (state) => {
      state.editors = [];
      state.totalMerchantEditors = 0;
    });
    builder.addCase(removeMerchantEditor.fulfilled, (state, action) => {
      state.editors = state.editors.filter(
        (el) => el.id !== action.meta.arg.id,
      );
      state.totalMerchantEditors -= 1;
    });
    builder.addCase(
      getMerchantsStats.fulfilled,
      (state, action: PayloadAction<ApiSuccessType<MerchantStats>>) => {
        state.stats = action.payload.result.data;
      },
    );
    builder.addCase(getMerchantsStats.pending, (state) => {
      state.stats = undefined;
    });
  },
});

export function useMerchantState() {
  const merchantState = useSelector(
    (state: RootState) => state.merchant,
  ) as MerchantStateType;
  const dispatch = useAppDispatch();

  const merchantActions = {
    fetchMerchantEditors: (params: GetMerchantEditorsParams) =>
      dispatch(fetchMerchantEditors(params)),
    addMerchantEditor: (params: AddEditorParamsType) =>
      dispatch(addMerchantEditor(params)),
    validateNewMerchantEditor: (params: AddEditorParamsType) =>
      dispatch(validateNewMerchantEditor(params)),
    removeMerchantEditor: (params: RemoveEditorParamsType) =>
      dispatch(removeMerchantEditor(params)),
    getMerchantStats: (params: GetMerchantStatsParams) =>
      dispatch(getMerchantsStats(params)),
  };

  return [merchantState, merchantActions] as [
    typeof merchantState,
    typeof merchantActions,
  ];
}

export default merchantSlice;
