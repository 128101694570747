import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DialogContext } from '../components/Dialog/provider';
import { useUserState } from '../features/User/reducers';

import RouterPath from '../constants/RouterPath';
import PrivateRoute from './PrivateRoute';
import SignInScreen from '../features/SignIn';
import MerchantEdit from '../features/MerchantEdit';
import MerchantCardList from '../features/MerchantCardList';
import MerchantShow from '../features/MerchantShow';
import CreateCard from '../features/Card/components/CreateCard';
import CardStats from '../features/Card/components/CardStats';
import CardEdit from '../features/CardEdit';
import SignUpScreen from '../features/SignUp';
import SignUpSocial from '../features/SignUp/components/SignUpSocial';
import OTP from '../features/SignUp/components/OTP';
import SignUpPhone from '../features/SignUp/components/SignUpPhone';
import SignUpPrivateRoute from './SignUpPrivateRoute';
import ForgotPasswordScreen from '../features/ForgotPassword';
import ForgotPasswordOtpScreen from '../features/ForgotPassword/ForgotPasswordOtp';
import NewPasswordScreen from '../features/ForgotPassword/NewPassword';
import ForgotPasswordPrivateRoute from './ForgotPasswordPrivateRoute';
import MerchantCreate from '../features/MerchantCreate';
import MerchantEditor from '../features/MerchantEditor';
import MerchantStats from '../features/Merchant/components/MerchantStats';

export default function AppRouter() {
  const { i18n, t } = useTranslation();
  const [userState] = useUserState();
  const { userLanguage } = userState;
  const dialogContext = useContext(DialogContext);

  function getUserConfirmation(
    message: string,
    callback: (ok: boolean) => void,
  ) {
    dialogContext.current?.show({
      message,
      positiveTitle: t('actions.yes'),
      negativeTitle: t('actions.no'),
      type: 'warning',
      onPositivePress: () => {
        callback(true);
      },
    });
  }

  useEffect(() => {
    i18n.changeLanguage(userLanguage);
  }, [userLanguage, i18n]);

  return (
    <Router getUserConfirmation={getUserConfirmation}>
      <Switch>
        <Route exact path={RouterPath.SIGNUP}>
          <SignUpScreen />
        </Route>

        <Route exact path={RouterPath.LOGIN}>
          <SignInScreen />
        </Route>

        <Route exact path={RouterPath.FORGOT_PASSWORD}>
          <ForgotPasswordScreen />
        </Route>

        <ForgotPasswordPrivateRoute path={RouterPath.FORGOT_PASSWORD}>
          <Switch>
            <Route exact path={RouterPath.FORGOT_PASSWORD_OTP}>
              <ForgotPasswordOtpScreen />
            </Route>
            <Route exact path={RouterPath.NEW_PASSWORD}>
              <NewPasswordScreen />
            </Route>
          </Switch>
        </ForgotPasswordPrivateRoute>

        <Route exact path={RouterPath.SIGNUP_PHONE}>
          <SignUpPhone />
        </Route>
        <SignUpPrivateRoute path={RouterPath.SIGNUP}>
          <Switch>
            <Route exact path={RouterPath.SIGNUP_SOCIAL}>
              <SignUpSocial />
            </Route>
            <Route exact path={RouterPath.OTP}>
              <OTP />
            </Route>
          </Switch>
        </SignUpPrivateRoute>

        <PrivateRoute path={RouterPath.HOME_PAGE}>
          <Switch>
            <Route exact path={RouterPath.CREATE_MERCHANT}>
              <MerchantCreate />
            </Route>
            <Route exact path={RouterPath.MERCHANT_SHOW}>
              <MerchantShow />
            </Route>
            <Route exact path={RouterPath.MERCHANT_EDIT}>
              <MerchantEdit />
            </Route>
            <Route exact path={RouterPath.CREATE_CARDS}>
              <CreateCard />
            </Route>
            <Route exact path={RouterPath.CARD_EDIT}>
              <CardEdit />
            </Route>
            <Route exact path={RouterPath.MERCHANT_STATS}>
              <MerchantStats />
            </Route>
            <Route exact path={RouterPath.CARD_STATS}>
              <CardStats />
            </Route>
            <Route exact path={RouterPath.MERCHANT_EDITORS}>
              <MerchantEditor />
            </Route>
            <Route exact path="*">
              <MerchantCardList />
            </Route>
          </Switch>
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
