import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserState } from '../../User/reducers';
import { SignUpParamsType } from '../../../services/api/types/user';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { resetPassword } from '../../User/actions';
import RouterPath from '../../../constants/RouterPath';
import AuthContainer from '../../SignUp/components/AuthContainer';
import style from '../../SignUp/components/SignUpPhone/style.module.scss';
import Form from '../../../components/Form';
import InputGroupTag from '../../../components/Form/components/InputGroupTag';

import { PasswordValidate } from '../../SignIn/components/SignInForm/validate';
import Button from '../../../components/Button';
import LoginButton from '../../../components/LoginButton';
import setPageTitle from '../../../utils/pageTitle';

export default function NewPasswordScreen() {
  const { register, errors, handleSubmit, watch } = useForm<SignUpParamsType>();
  const { t } = useTranslation();
  const [submitError, setSubmitError] = useState(false);
  const [userState, userActions] = useUserState();
  const history = useHistory();
  const windowSize = useWindowSize();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageTitle(t('features.forgotPassword.title'));
  }, [t]);

  async function onSubmit(data: any) {
    setLoading(true);
    const result = await userActions.resetPassword(data.password);
    setLoading(false);
    if (resetPassword.fulfilled.match(result)) {
      history.push(RouterPath.HOME_PAGE);
    } else {
      setSubmitError(true);
    }
  }

  const PasswordConfirmationValidator = {
    validate: (value: string) =>
      value === watch('password') || t('errors.passwordConfirmation'),
    required: t('errors.required'),
  };

  return (
    <AuthContainer
      title={t('features.forgotPassword.newPassword')}
      noSignInLink
    >
      <div className={style.form}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <InputGroupTag
              id="password"
              name="password"
              labelText={`${t('features.forgotPassword.newPassword')} *`}
              error={errors.password}
              ref={register(PasswordValidate)}
              type="password"
            />
            <InputGroupTag
              id="passwordConfirmation"
              name="passwordConfirmation"
              labelText={`${t('features.signUpForm.passwordConfirmation')} *`}
              error={errors.passwordConfirmation}
              type="password"
              // @ts-ignore
              ref={register(PasswordConfirmationValidator)}
            />
            {submitError && (
              <span className={style.error}>{t(userState.apiError!)}</span>
            )}
          </div>

          <div className={style.buttonContainer}>
            {windowSize.width > 800 ? (
              <Button
                loading={loading}
                type="submit"
                title={t('actions.next')}
              />
            ) : (
              <LoginButton
                loading={loading}
                backgroundColor="white"
                color="#006064"
                title={t('actions.finish')}
                onClick={() => handleSubmit(onSubmit)()}
              />
            )}
          </div>
        </Form>
      </div>
    </AuthContainer>
  );
}
