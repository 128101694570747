import React, { forwardRef } from 'react';
import style from './style.module.scss';

interface PropsType {
  id: string;
  name: string;
  title: string;
  data?: any[];
  klassName?: string;
  renderItem: (item: any, index: number) => React.ReactNode;
  onChange?: (value: any) => void;
}

// Dropdown selection component
const DropdownGroupTag = forwardRef<HTMLSelectElement, PropsType>(
  (params, ref) => {
    const { id, name, title, data, renderItem, klassName, onChange } = params;
    return (
      <div className={klassName || style.formGroup}>
        <label htmlFor={name}>{title}</label>
        <select
          ref={ref}
          name={name}
          id={id}
          onChange={(event) => {
            if (onChange) {
              onChange(event.target.value);
            }
          }}
        >
          {data && data.map((item, index) => renderItem(item, index))}
        </select>
      </div>
    );
  },
);

export default DropdownGroupTag;
