import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import getErrorKey from '../../../../utils/error';
import RequiredLabel from '../../../RequiredLabel';
import { useUserState } from '../../../../features/User/reducers';
import style from './style.module.scss';

const CKEditor = require('ckeditor4-react');

type PropsType = {
  name: string;
  labelText: string;
  inputClass?: string;
  error?: FieldError;
  rules: object;
  control: Control<any>;
  imageUploadable?: boolean;
};

const DEFAULT_CKEDITOR_PLUGINS = 'autogrow';
const CKEDITOR_IMAGES_PLUGINS = 'autogrow,image2,uploadimage';

export default function CKEditorGroupTag(params: PropsType) {
  const { name, labelText, error, rules, control, imageUploadable } = params;
  const [userState] = useUserState();

  const plugin = imageUploadable
    ? CKEDITOR_IMAGES_PLUGINS
    : DEFAULT_CKEDITOR_PLUGINS;

  return (
    <div className={style.formGroup}>
      <RequiredLabel
        htmlFor={name}
        title={labelText}
        error={getErrorKey(error)}
      />

      <div className={style.editorContainer}>
        <Controller
          control={control}
          rules={rules}
          name={name}
          render={({ value, onChange }) => (
            <CKEditor
              data={value}
              onAfterPaste={(e: any) => {
                setTimeout(() => {
                  const data = e.editor.getData();
                  onChange(data);
                }, 3000);
              }}
              onChange={(e: any) => {
                const currentData = e.editor.getData();
                onChange(currentData);
              }}
              config={{
                versionCheck:false,
                toolbar: [
                  ['Bold', 'Italic', 'Strike'],
                  ['NumberedList', 'BulletedList'],
                  ['Undo', 'Redo'],
                  ['Image'],
                  ['Link'],
                  ['Maximize'],
                ],
                removePlugins: 'image',
                extraPlugins: `${plugin}, autolink`,
                removeDialogTabs: 'image2:Upload;',
                autoGrow_minHeight: 200,
                autoGrow_maxHeight: 600,
                autoGrow_bottomSpace: 50,
                enterMode: 2,
                fileTools_requestHeaders: {
                  authorization: `${userState.userToken?.tokenType} ${userState.userToken?.accessToken}`,
                },
                filebrowserImageUploadUrl: `${process.env.REACT_APP_API_BASE_URL}/ckeditor/pictures?`,
                pasteUploadFileApi: `${process.env.REACT_APP_API_BASE_URL}/ckeditor/pictures?`,
                image2_disableResizer: false,
                disallowedContent: 'img[width,height]',
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
