import React, { useState, ReactNode } from 'react';
import { Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';
import style from './style.module.scss';

interface TabType {
  label: string;
  component: ReactNode | ReactNode[];
}

interface PropTypes {
  tabs: TabType[];
}

export default function Tabs(props: PropTypes) {
  const { tabs } = props;
  const [value, setValue] = useState<number>(0);

  return (
    <>
      <MuiTabs value={value} onChange={(event, newValue) => setValue(newValue)}>
        {tabs.map((tab) => (
          <MuiTab
            classes={{ root: style.root, selected: style.selected }}
            label={tab.label}
          />
        ))}
      </MuiTabs>
      <div className={style.content}>
        {tabs.find((tab, index) => index === value)!.component}
      </div>
    </>
  );
}
