import React from 'react';
import Pagination from '../Pagination';
import { CardType, MerchantType } from '../../features/Card/types';
import style from './style.module.scss';
import MerchantCard from '../MerchantCard';
import Card from '../Card';
import { PER_PAGE } from '../../services/api/types/api';

type PropsType = {
  merchants?: MerchantType[];
  cards?: CardType[];
  total: number;
  onPageChange: (param: number) => void;
  noCardMessage: string;
  currentPage: number;
};

export default function CardList(props: PropsType) {
  const {
    cards,
    merchants,
    onPageChange,
    total,
    noCardMessage,
    currentPage,
  } = props;

  return (
    <>
      {total === 0 && <span className={style.noCard}>{noCardMessage}</span>}

      <div className={style.itemsList}>
        {cards && cards.map((card) => <Card key={card.id} card={card} />)}
        {merchants &&
          merchants.map((merchant) => (
            <MerchantCard key={merchant.id} merchant={merchant} />
          ))}
      </div>
      {total > PER_PAGE && (
        <Pagination
          current={currentPage}
          total={total}
          onPageChange={(current) => onPageChange(current)}
        />
      )}
    </>
  );
}
