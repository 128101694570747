import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import RouterPath from '../../constants/RouterPath';
import { UserStateType } from '../../features/User/types';

interface ProtectedRouteProps {
  children: ReactNode;
  path: string;
}

export default function SignUpPrivateRoute({
  children,
  ...rest
}: ProtectedRouteProps) {
  const userState = useSelector((state: any) => state.user) as UserStateType;
  const { user } = userState;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          if (user.status === 'active') {
            return (
              <Redirect
                to={{
                  pathname: RouterPath.HOME_PAGE,
                  state: { from: location },
                }}
              />
            );
          }
          if (user.phoneNumber && location.pathname !== RouterPath.OTP) {
            return (
              <Redirect
                to={{
                  pathname: RouterPath.OTP,
                  state: { from: location },
                }}
              />
            );
          }
          if (
            !user.phoneNumber &&
            location.pathname !== RouterPath.SIGNUP_SOCIAL
          ) {
            return (
              <Redirect
                to={{
                  pathname: RouterPath.SIGNUP_SOCIAL,
                  state: { from: location },
                }}
              />
            );
          }
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: RouterPath.SIGNUP,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
