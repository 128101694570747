import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import RouterPath from '../../constants/RouterPath';
import { UserStateType } from '../../features/User/types';

interface ProtectedRouteProps {
  children: ReactNode;
  path: string;
}

export default function ForgotPasswordPrivateRoute({
  children,
  ...rest
}: ProtectedRouteProps) {
  const userState = useSelector((state: any) => state.user) as UserStateType;
  const { user, userToken } = userState;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          if (
            (location.pathname === RouterPath.FORGOT_PASSWORD_OTP &&
              !user.phoneNumber &&
              !user.id) ||
            (location.pathname === RouterPath.NEW_PASSWORD &&
              !user.resetPasswordToken &&
              !userToken)
          ) {
            return (
              <Redirect
                to={{
                  pathname: RouterPath.LOGIN,
                  state: { from: location },
                }}
              />
            );
          }
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: RouterPath.LOGIN,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
