import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { useUserState } from '../User/reducers';
import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';
import Pagination from '../../components/Pagination';
import Editor from '../../components/MerchantEditor';
import AddEditorForm from './components/AddEditorForm';

import RouterPath from '../../constants/RouterPath';
import { useMerchantState } from '../Merchant/reducers';
import { GetMerchantEditorsParams } from '../../services/api/types/requestParams';
import { PER_PAGE } from '../../services/api/types/api';
import style from './style.module.scss';
import setPageTitle from '../../utils/pageTitle';

export default function MerchantEditor() {
  const [userState] = useUserState();
  const { merchantId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [merchantState, merchantActions] = useMerchantState();

  const { user } = userState;
  const { editors, totalMerchantEditors } = merchantState;
  const backUrl = RouterPath.MERCHANT_SHOW.replace(':merchantId', merchantId);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function onPageChange(currentEditorPage: number) {
    const params: GetMerchantEditorsParams = {
      page: currentEditorPage,
      perPage: PER_PAGE,
      merchantId,
    };

    setIsLoading(true);
    await merchantActions.fetchMerchantEditors(params);
    setIsLoading(false);
    setCurrentPage(currentEditorPage);
  }

  useEffect(() => {
    onPageChange(currentPage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageTitle(t('features.merchantEditor.title'));
  }, [t]);

  function reloadEditors() {
    onPageChange(1);
  }

  if (isLoading) return <Loader />;

  return (
    <>
      <NavBar user={user} />
      <section className={style.section}>
        <div className={style.title}>
          <div className={style.titleContainer}>
            <div
              aria-hidden
              className={style.backButton}
              onClick={() => history.push(backUrl)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <span>{t('features.merchantEditor.title')}</span>
          </div>
        </div>

        <div className={style.subtitle}>
          {t('features.merchantEditor.subTitle')}
        </div>

        <AddEditorForm reloadEditors={reloadEditors} />

        <div className={style.editorsTitle}>
          {t('features.merchantEditor.editors')}
        </div>

        {totalMerchantEditors === 0 && (
          <span className={style.noEditorMessage}>
            {t('features.merchantEditor.hasNoEditors')}
          </span>
        )}
        <div className={style.itemsList}>
          {editors &&
            editors.map((editor) => <Editor key={editor.id} editor={editor} />)}
        </div>
        {totalMerchantEditors > PER_PAGE && (
          <Pagination
            current={currentPage}
            total={totalMerchantEditors}
            onPageChange={(current: number) => onPageChange(current)}
          />
        )}
      </section>
      <Footer />
    </>
  );
}
