import Api from './api';
import {
  CardCreateParamsType,
  CardUpdateParamsType,
  CreateCardResponseType,
  DeleteCardParamsType,
  FetchCardParamsType,
  FetchCardResponseType,
  FetchCardsByMerchantParamsType,
  FetchCardsResponseType,
} from './types/card';
import { OauthTokenType } from '../../features/User/types';
import { AppDispatch } from '../../store';
import { CardStats, CardType, UserStats } from '../../features/Card/types';
import { GetCardStatsParams } from './types/requestParams';
import {
  GetCardStatsResponse,
  GetUserStatsResponse,
} from './types/requestResponse';

export default class CardApi extends Api {
  path: string;

  constructor(dispatch: AppDispatch, userToken?: OauthTokenType) {
    super(dispatch, userToken);
    this.path = '/cms/merchants';
  }

  async fetchCardsByMerchant(
    params: FetchCardsByMerchantParamsType,
  ): Promise<FetchCardsResponseType> {
    const response = await this.request<CardType[]>({
      path: `${this.path}/${params.merchantId}/cards`,
      method: 'GET',
      params,
    });
    return response;
  }

  // Fetch a specific card
  async fetchCard(params: FetchCardParamsType): Promise<FetchCardResponseType> {
    const response = await this.request<CardType>({
      path: `${this.path}/${params.merchantId}/cards/${params.cardId}`,
      method: 'GET',
    });
    return response;
  }

  async deleteCard(params: DeleteCardParamsType): Promise<any> {
    const res = await this.request<any>({
      path: `${this.path}/${params.merchantId}/cards/${params.cardId}`,
      method: 'DELETE',
    });
    return res;
  }

  async fetchCardStats(
    params: GetCardStatsParams,
  ): Promise<GetCardStatsResponse> {
    const response = await this.request<CardStats>({
      path: `/cms/merchants/${params.merchantId}/cards/${params.id}/stats`,
      method: 'GET',
    });
    return response;
  }

  async fetchUserStats(): Promise<GetUserStatsResponse> {
    const response = await this.request<UserStats>({
      path: '/cms/users/stats',
      method: 'GET',
    });
    return response;
  }

  async createCard(
    params: CardCreateParamsType,
  ): Promise<CreateCardResponseType> {
    const response = await this.request<CardType>({
      path: `${this.path}/${params.merchantId}/cards`,
      method: 'POST',
      body: this.toFormData(params),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }

  async updateCard(
    params: CardUpdateParamsType,
  ): Promise<CreateCardResponseType> {
    const response = await this.request<CardType>({
      path: `${this.path}/${params.merchantId}/cards/${params.id}`,
      method: 'PATCH',
      body: this.toFormData(params),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }
}
