import { useHistory } from 'react-router-dom';
import { useEffect, useRef } from 'react';

// use for every page want to show a warning dialog when back, reload... actions
export default function useBeforeUnload(message: string, when: boolean) {
  const history = useHistory();
  const unblock = useRef<any>(null);

  useEffect(() => {
    // handle for reload or close window action, it will show the default dialog of browser (almost can not change the message)
    function onBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      event.returnValue = message;
      return when && message ? message : undefined;
    }

    window.addEventListener('beforeunload', onBeforeUnload);

    if (when) {
      // handle for back for push page action, it will show the custom dialog
      unblock.current = history.block((location, action) => {
        if (action === 'POP') {
          return message;
        }
        return undefined;
      });
    } else {
      unblock.current = null;
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [when, message, history]);
}
