import React from 'react';
import style from './style.module.scss';

interface RequiredLabelProps {
  title: string;
  htmlFor: string;
  error?: string;
}

// Show the label with the `is required` text on the right when have error
export default function RequiredLabel(props: RequiredLabelProps) {
  const { title, htmlFor, error } = props;
  return (
    <label htmlFor={htmlFor}>
      {/* eslint-disable-next-line */}
      <label>{title}</label>
      {error && (
        // eslint-disable-next-line
        <label className={style.error}>{error}</label>
      )}
    </label>
  );
}

RequiredLabel.defaultProps = {
  error: undefined,
};
