import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeepMap, FieldError, ValidationRules } from 'react-hook-form';
import InputRadioGroupTag from '../../../Form/components/InputRadioGroupTag';
import { PromotionTypes } from '../../../../features/Card/types';
import styles from './style.module.scss';
import { RequiredValidate } from '../../../../utils/form/validate';
import InputGroupTag from '../../../Form/components/InputGroupTag';
import { PromotionCreateDataType } from '../../../../services/api/types/card';

interface PromotionTypeInputProps {
  error: DeepMap<PromotionCreateDataType, FieldError>;
  control: any;
  register: any;
}

export default function PromotionTypeInput(props: PromotionTypeInputProps) {
  const { error, control, register } = props;
  const { t } = useTranslation();

  // replace key in `[ ]` of text by Input component that allow import input field into a text
  function renderChildren(
    content: string,
    disable: boolean,
    rules: ValidationRules,
  ): any {
    const arr: any = [];
    let text: string | undefined;
    for (let i = 0; i < content.length; i += 1) {
      // `[` is sign for start of variable
      if (content[i] === '[') {
        text = '';
      } else if (content[i] === ']') {
        // `]` is sign for end of variable, then push Input component to content
        arr.push(
          <InputGroupTag
            id={text!}
            name={text!}
            // @ts-ignore
            error={!disable && error[text!]}
            type="number"
            value={disable ? '' : undefined}
            ref={register(disable ? { required: false } : rules)}
            klassName={styles.input}
          />,
        );
        text = undefined;
      } else if (text !== undefined) {
        text = text.concat(content[i]);
      } else {
        arr.push(content[i]);
      }
    }
    return arr;
  }

  return (
    <InputRadioGroupTag
      name="promotionType"
      title={t('components.cardPromotion.promotionType')}
      error={error.promotionType}
      data={PROMOTION_RADIO_INPUTS}
      renderItems={(item, disable) => (
        <div
          className={
            disable ? styles.inputContainerDisable : styles.inputContainer
          }
        >
          {renderChildren(
            t(`components.cardPromotion.types.${item.value}`),
            disable,
            item.rules,
          )}
        </div>
      )}
      rules={RequiredValidate}
      control={control}
    />
  );
}

interface PromotionRadioInput {
  value: PromotionTypes;
  rules: ValidationRules;
}

const PROMOTION_RADIO_INPUTS: PromotionRadioInput[] = [
  {
    value: 'buy_get',
    rules: {
      required: true,
      max: 100,
      min: 1,
    },
  },
  {
    value: 'percent_off',
    rules: {
      required: true,
      max: 100,
      min: 1,
    },
  },
  {
    value: 'percent_off_upto',
    rules: {
      required: true,
      max: 100,
      min: 1,
    },
  },
  {
    value: 'only_from',
    rules: {
      required: true,
      min: 1,
    },
  },
  {
    value: 'same_price',
    rules: {
      required: true,
      min: 1,
    },
  },
];
