import { UsersByAreaStats, UserByAgeStats } from '../../../features/Card/types';

const CHART_COLORS = [
  '#296C9F',
  '#EE5B46',
  '#00B07B',
  '#5CAACC',
  '#006064',
  '#767676',
];

export function ageLabels(stats: UserByAgeStats[]): string[] {
  return stats.map((stat) => stat.ageRanger);
}

export function ageValues(stats: UserByAgeStats[]): number[] {
  return stats.map((stat) => stat.total);
}

export function ageColors(stats: UserByAgeStats[]) {
  return stats.map((_stat, index) => {
    return CHART_COLORS[index];
  });
}

export function areaLabels(stats: UsersByAreaStats[]): string[] {
  return stats.map((stat) => stat.regionName);
}

export function areaValues(stats: UsersByAreaStats[]): number[] {
  return stats.map((stat) => stat.total);
}

export function areaColors(stats: UsersByAreaStats[]) {
  return stats.map((_stat, index) => {
    return CHART_COLORS[index];
  });
}
