import React from 'react';
import {
  Control,
  Controller,
  FieldError,
  ValidationRules,
} from 'react-hook-form';
import { Chip } from '@material-ui/core';
import style from './style.module.scss';
import RequiredLabel from '../../../RequiredLabel';
import getErrorKey from '../../../../utils/error';

interface ChipData {
  value: number;
  title: string;
}

interface PropsType {
  name: string;
  title: string;
  control: Control;
  data: ChipData[];
  klassName?: string;
  rules?: ValidationRules;
  error?: (FieldError | undefined)[];
}

// Chip selection component
export default function ChipSelectGroupTag(props: PropsType) {
  const { name, title, data, klassName, control, rules, error } = props;

  function renderChip(
    item: ChipData,
    onChange: (value?: number[]) => void,
    selectedIds: number[],
  ) {
    const selected = selectedIds?.includes(item.value);
    return (
      <div className={style.chip}>
        <Chip
          clickable
          color={selectedIds?.includes(item.value) ? 'primary' : 'default'}
          onClick={() => {
            if (selected) {
              onChange(selectedIds?.filter((id) => id !== item.value));
            } else {
              onChange(selectedIds?.concat(item.value));
            }
          }}
          size="small"
          label={item.title}
        />
      </div>
    );
  }

  return (
    <div className={klassName || style.formGroup}>
      <RequiredLabel htmlFor={name} title={title} error={getErrorKey(error)} />
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ value, onChange }) => {
          const ids: number[] = value || [];
          return (
            <div className={style.chipGroup}>
              {data &&
                data.map((item) => {
                  return renderChip(item, onChange, ids);
                })}
            </div>
          );
        }}
      />
    </div>
  );
}
