import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavBar from '../../components/NavBar';
import { useUserState } from '../User/reducers';
import { updateMerchant } from '../Card/actions';
import { MerchantUpdateParamsType } from '../../services/api/types/merchant';
import { useCardsState } from '../Card/reducers';
import RouterPath from '../../constants/RouterPath';
import useBeforeUnload from '../../utils/hooks/useBeforeUnload';
import { DialogContext } from '../../components/Dialog/provider';
import CardMerchantFormContainer from '../../components/CardFormContainer/Merchant';
import Loader from '../../components/Loader';
import setPageTitle from '../../utils/pageTitle';
import removeSpace from '../../utils/string';

// Edit merchant info screen
export default function MerchantEdit() {
  const { t } = useTranslation();
  const { merchantId } = useParams();
  const [userState] = useUserState();
  const { user } = userState;
  const [cardsState, cardsActions] = useCardsState();
  const closeFormUrl = RouterPath.HOME_PAGE;
  const history = useHistory();
  const [submitError, setSubmitError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const dialogContext = useContext(DialogContext);

  function onClosePress() {
    dialogContext.current?.show({
      message: t('dialogs.exitCreateOrUpdateCardMessage'),
      type: 'warning',
      negativeTitle: t('actions.back'),
      positiveTitle: t('actions.exit'),
      onPositivePress: () => {
        history.push(closeFormUrl);
      },
    });
  }

  const onMerchantUpdateSubmit = async (data: MerchantUpdateParamsType) => {
    setLoading(true);
    const merchantParams: MerchantUpdateParamsType = {
      ...data,
      id: merchantId,
      email: removeSpace(data.email),
    };

    const result = await cardsActions.updateMerchant(merchantParams);

    if (updateMerchant.fulfilled.match(result)) {
      history.push(closeFormUrl);
    } else if (result.error.message) setSubmitError(result.error.message);
    setLoading(false);
  };

  function initPage() {
    cardsActions.fetchCategories();
    cardsActions.fetchEcoparkRegions();
    cardsActions.fetchMerchant({ id: merchantId });
  }

  useEffect(initPage, []);

  useEffect(() => {
    setPageTitle(cardsState.merchant?.name);
  }, [cardsState.merchant]);

  useBeforeUnload(t('dialogs.exitCreateOrUpdateCardMessage'), true);

  return (
    <>
      <NavBar user={user} />
      {cardsState.isFetchingCard && <Loader />}
      {cardsState.merchant && (
        <CardMerchantFormContainer
          onSubmit={onMerchantUpdateSubmit}
          submitError={submitError}
          merchant={cardsState.merchant}
          loading={loading}
          onClosePress={onClosePress}
        />
      )}
    </>
  );
}
