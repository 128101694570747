import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCog } from '@fortawesome/free-solid-svg-icons';
import { useCardsState } from '../Card/reducers';
import { useUserState } from '../User/reducers';
import RouterPath from '../../constants/RouterPath';
import { FetchCardsByMerchantParamsType } from '../../services/api/types/card';
import NavBar from '../../components/NavBar';
import CardList from '../../components/CardList';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import { PER_PAGE } from '../../services/api/types/api';
import Trigger from '../../components/Trigger';
import { MenuPropTypes } from '../../components/Menu';
import Loader from '../../components/Loader';
import style from './style.module.scss';
import setPageTitle from '../../utils/pageTitle';

export default function MerchantShow() {
  const { t } = useTranslation();
  const { merchantId } = useParams();
  const [userState] = useUserState();
  const [cardsState, cardsActions] = useCardsState();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { cards, totalCards, merchant } = cardsState;
  const { user } = userState;
  const merchantStatsUrl = RouterPath.MERCHANT_STATS.replace(
    ':merchantId',
    merchantId,
  );
  const merchantEditorsUrl = RouterPath.MERCHANT_EDITORS.replace(
    ':merchantId',
    merchantId,
  );
  const createCardUrl = RouterPath.CREATE_CARDS.replace(
    ':merchantId',
    merchantId,
  );

  function generateCardOptions(): MenuPropTypes[] {
    const defaultOptions = [
      {
        title: t('actions.analytics'),
        onClick: onGoMerchantAnalytics,
      },
    ];

    if (canInviteEmployee()) {
      defaultOptions.push({
        title: t('features.merchantEditor.menuTitle'),
        onClick: () => history.push(merchantEditorsUrl),
      });
    }

    return defaultOptions;
  }

  function onGoMerchantAnalytics() {
    history.push(merchantStatsUrl);
  }

  function canInviteEmployee() {
    return user?.permissionRole === 'root' || merchant?.userId === user?.id;
  }

  const onPageChange = async (currentCardPage: number) => {
    setIsLoading(true);
    const params: FetchCardsByMerchantParamsType = {
      page: currentCardPage,
      perPage: PER_PAGE,
      merchantId,
    };
    await cardsActions.fetchMerchant({ id: merchantId });
    await cardsActions.fetchCardsByMerchant(params);
    setIsLoading(false);
    setCurrentPage(currentCardPage);
  };

  useEffect(() => {
    onPageChange(currentPage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageTitle(merchant?.name || t('features.merchantShow.defaultCardTitle'));
  }, [merchant, t]);

  if (isLoading) return <Loader />;

  return (
    <>
      <NavBar user={user}>
        <Button
          outlined
          title={t('features.merchantShow.createNewCard')}
          onClick={() => history.push(createCardUrl)}
        />
      </NavBar>

      <section className={style.section}>
        <div className={style.title}>
          <div className={style.titleContainer}>
            <div
              aria-hidden
              className={style.backButton}
              onClick={() => history.push(RouterPath.HOME_PAGE)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <span>
              {merchant?.name || t('features.merchantShow.defaultCardTitle')}
            </span>
          </div>

          <Trigger options={generateCardOptions()}>
            <FontAwesomeIcon icon={faCog} className={style.options} size="lg" />
          </Trigger>

          <div className={style.buttonContainer}>
            {canInviteEmployee() && (
              <Button
                title={t('features.merchantEditor.menuTitle')}
                outlined
                onClick={() => history.push(merchantEditorsUrl)}
              />
            )}

            <Button
              title={t('actions.analytics')}
              outlined
              onClick={() => history.push(merchantStatsUrl)}
            />

            <Button
              title={t('features.merchantShow.createNewCard')}
              onClick={() => history.push(createCardUrl)}
            />
          </div>
        </div>

        <CardList
          cards={cards}
          onPageChange={onPageChange}
          total={totalCards}
          noCardMessage={t('components.cardList.noCard')}
          currentPage={currentPage}
        />
      </section>
      <Footer />
    </>
  );
}
