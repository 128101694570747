import React, { useEffect, useState } from 'react';
import { Alarm, Link, LocationOn } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import {
  DEFAULT_FORM_DATA,
  getImageURL,
} from '../../../../../../../utils/card';
import { MOMENT_DATE_FORMATS } from '../../../../../../../utils/time';
import HtmlContent from '../HtmlContent';
import CardContainer from '../CardContainer';

export default function CardEventPreview() {
  const formContext = useFormContext();
  let formData: any;
  if (formContext) {
    formData = formContext.watch();
  }
  const [coverImage, setCoverImage] = useState<string | undefined>(
    formData?.photoCover,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (formData) {
      setCoverImage(getImageURL(formData.photoCover));
    }
    // eslint-disable-next-line
  }, [formData?.photoCover]);

  return (
    <CardContainer hasCoverImage coverImage={coverImage} type="event">
      <div className={style.contentContainer}>
        <div className={style.headerContainer}>
          <div className={style.titleContainer}>
            <div className={style.titleContainer}>
              <h2>
                {formData && formData.title && formData.title.length > 0
                  ? formData.title
                  : t(DEFAULT_FORM_DATA.eventTitle)}
              </h2>
            </div>
            <div
              aria-hidden
              className={style.linkButtonContainer}
              onClick={() => window.open(formData?.websiteLink, '_blank')}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link className={style.linkIcon} />
            </div>
          </div>
          <div className={style.dateContainer}>
            <Alarm className={style.smallIcon} />
            <div>
              <div>
                {formData && formData.startTime
                  ? moment(formData.startTime).format(
                      MOMENT_DATE_FORMATS.dateTime,
                    )
                  : DEFAULT_FORM_DATA.fullDate}
              </div>
              <div>
                {formData && formData.endTime
                  ? moment(formData.endTime).format(
                      MOMENT_DATE_FORMATS.dateTime,
                    )
                  : DEFAULT_FORM_DATA.fullDate}
              </div>
            </div>
          </div>
          <div className={style.locationContainer}>
            <LocationOn className={style.smallIcon} />
            <div>
              {formData && formData.address && formData.address.length > 0
                ? formData.address
                : t(DEFAULT_FORM_DATA.eventAddress)}
            </div>
          </div>
        </div>
        <HtmlContent
          html={
            formData && formData.description && formData.description.length > 0
              ? formData.description
              : `<i>${t(DEFAULT_FORM_DATA.description.event)}</i>`
          }
        />
      </div>
    </CardContainer>
  );
}
