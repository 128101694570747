import React, { useImperativeHandle, useState } from 'react';
import { Dialog as RDialog, DialogContent } from '@material-ui/core';
import styles from './style.module.scss';
import Button from '../Button';

export interface DialogRef {
  show: (props: DialogType) => void;
  dismiss: () => void;
}

export interface DialogType {
  message: string;
  positiveTitle?: string;
  negativeTitle?: string;
  onNegativePress?: () => void;
  onPositivePress?: () => void;
  type?: 'warning' | 'default';
}

interface DialogProps {
  dialogRef: React.MutableRefObject<DialogRef | undefined>;
}

// Confirm Dialog contains a title, a negative button and a confirm button.
// To show this dialog, please use
// `const dialogContext = useContext(DialogContext);`
// then `dialogContext.current?.show`
export default function Dialog(props: DialogProps) {
  const { dialogRef } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<DialogType>({ message: '' });

  function onNegativeButtonClick() {
    setOpen(false);
    if (data.onNegativePress) {
      data.onNegativePress();
    }
  }

  function onPositiveButtonClick() {
    setOpen(false);
    if (data.onPositivePress) {
      data.onPositivePress();
    }
  }

  useImperativeHandle(dialogRef, () => ({
    show: (dialogData) => {
      setOpen(true);
      setData(dialogData);
    },
    dismiss: () => {
      setOpen(false);
    },
  }));

  return (
    <RDialog
      open={open}
      PaperProps={{
        id: styles.dialogContainer,
      }}
      onBackdropClick={() => setOpen(false)}
    >
      <DialogContent className={styles.message} id="alert-dialog-slide-title">
        {data.message}
      </DialogContent>
      <div className={styles.buttonContainer}>
        {data.negativeTitle && (
          <div className={styles.button}>
            <Button
              klassName={styles.flatButton}
              outlined
              title={data.negativeTitle}
              onClick={onNegativeButtonClick}
            />
          </div>
        )}
        {data.positiveTitle && (
          <div
            className={
              data.type === 'warning' ? styles.warningButton : styles.button
            }
          >
            <Button
              title={data.positiveTitle}
              klassName={styles.flatButton}
              onClick={onPositiveButtonClick}
            />
          </div>
        )}
      </div>
    </RDialog>
  );
}
