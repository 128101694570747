import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import { PromotionCreateDataType } from '../../../services/api/types/card';
import { CardType } from '../../../features/Card/types';
import CardPromotionForm from '../../CardPromotionForm';
import CardPreview from '../../../features/Card/components/CreateCard/components/CardPreview';
import CreateCardContainer from '../../../features/Card/components/CreateCard/components/CreateCardContainer';
import FormTitle from '../../../features/Card/components/CreateCard/components/FormTitle';
import { FormPageType } from '../../../features/Card/components/CreateCard/types';

type PropsType = {
  onSubmit: (data: PromotionCreateDataType) => void;
  submitError: string;
  card?: CardType;
  currentPage?: FormPageType;
  goFirstPage?: () => void;
  onClosePress?: () => void;
  loading: boolean;
};

export default function CardPromotionFormContainer(props: PropsType) {
  const {
    onSubmit,
    submitError,
    card,
    currentPage,
    goFirstPage,
    onClosePress,
    loading,
  } = props;
  const methods = useForm<PromotionCreateDataType>({
    defaultValues: {
      ...card,
      ...card?.promotion,
      expiry: card?.expiry ? new Date(card?.expiry) : new Date(),
    },
  });

  return (
    <FormProvider {...methods}>
      <CreateCardContainer
        title={
          <FormTitle
            onClosePress={onClosePress}
            cardType="CardPromotion"
            currentPage={currentPage}
            goFirstPage={goFirstPage}
          />
        }
        form={
          <CardPromotionForm
            loading={loading}
            onSubmit={onSubmit}
            submitError={submitError}
          />
        }
        example={<CardPreview cardType="CardPromotion" type="preview" />}
      />
    </FormProvider>
  );
}
