import React, { useRef } from 'react';
import {
  Button as MButton,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import style from './style.module.scss';
import { LanguageType } from '../../features/User/types';
import { useUserState } from '../../features/User/reducers';

export default function LanguageSwitch() {
  const [userState, userActions] = useUserState();
  const { userLanguage } = userState;
  const anchorRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleLanguageToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleLanguageClose = () => setOpen(false);
  const setUserLanguage = (value: LanguageType) => {
    if (value !== userLanguage) userActions.changeUserLanguage(value);
    handleLanguageClose();
  };
  return (
    <div className={style.menuLanguages}>
      <MButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleLanguageToggle}
      >
        {userLanguage}
      </MButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleLanguageClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <MenuItem onClick={() => setUserLanguage('vn')}>
                    Tiếng Việt
                  </MenuItem>
                  <MenuItem onClick={() => setUserLanguage('en')}>
                    English
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
