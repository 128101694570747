import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { useCardsState } from '../../../../../reducers';
import {
  DEFAULT_FORM_DATA,
  getImageURL,
} from '../../../../../../../utils/card';
import { MOMENT_DATE_FORMATS } from '../../../../../../../utils/time';
import HtmlContent from '../HtmlContent';
import CardContainer from '../CardContainer';

export default function CardNewsPreview() {
  const [cardsState] = useCardsState();
  const { merchant, card } = cardsState;
  const formContext = useFormContext();
  let formData: any;
  if (formContext) {
    formData = formContext.watch();
  }
  const [coverImage, setCoverImage] = useState<string | undefined>(
    formData?.photoCover,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (formData) {
      setCoverImage(getImageURL(formData.photoCover));
    }
    // eslint-disable-next-line
  }, [formData?.photoCover]);

  return (
    <CardContainer hasCoverImage coverImage={coverImage} type="news">
      <div className={style.contentContainer}>
        <div className={style.titleContainer}>
          <h2>
            {formData && formData.title && formData.title.length > 0
              ? formData.title
              : t(DEFAULT_FORM_DATA.newsTitle)}
          </h2>
        </div>
        <div className={style.dateContainer}>
          {card
            ? moment(card.createdAt).format(MOMENT_DATE_FORMATS.dateTime)
            : DEFAULT_FORM_DATA.fullDate}
        </div>
        <div className={style.authorContainer}>
          <div
            className={style.profileImage}
            style={{
              backgroundImage: `url(${merchant?.logo})`,
            }}
          />
          <div>{merchant?.name}</div>
        </div>
        <HtmlContent
          html={
            formData && formData.description && formData.description.length > 0
              ? formData.description
              : `<i>${t(DEFAULT_FORM_DATA.description.news)}</i>`
          }
        />
      </div>
    </CardContainer>
  );
}
