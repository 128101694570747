import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormPageType, SelectableCardType } from '../../types';
import {
  CardCreateParamsType,
  CardUpdateParamsType,
  EventCreateDataType,
  NewsCreateDataType,
  PromotionCreateDataType,
} from '../../../../../../services/api/types/card';
import { useCardsState } from '../../../../reducers';
import { createCard, updateCard } from '../../../../actions';
import { CardType, CardTypes } from '../../../../types';
import Loader from '../../../../../../components/Loader';
import CardPromotionFormContainer from '../../../../../../components/CardFormContainer/Promotion';
import CardNewsFormContainer from '../../../../../../components/CardFormContainer/News';
import CardEventFormContainer from '../../../../../../components/CardFormContainer/Event';

type PropsType = {
  cardType: SelectableCardType;
  merchantId: number;
  closeFormUrl: string;
  card?: CardType;
  isFetching?: boolean;
  currentPage?: FormPageType;
  goFirstPage?: () => void;
  onClosePress?: () => void;
};

export default function CardForm(props: PropsType) {
  const {
    cardType,
    merchantId,
    closeFormUrl,
    card,
    isFetching,
    currentPage,
    goFirstPage,
    onClosePress,
  } = props;
  const [submitError, setSubmitError] = useState<string>('');
  const [, cardsActions] = useCardsState();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const renderForm = (type: SelectableCardType) => {
    if (isFetching) {
      return <Loader />;
    }
    return {
      CardNews: (
        <CardNewsFormContainer
          card={card}
          onSubmit={card ? onUpdateCardSubmit : onCreateCardSubmit}
          submitError={submitError}
          currentPage={currentPage}
          goFirstPage={goFirstPage}
          loading={loading}
          onClosePress={onClosePress}
        />
      ),
      CardEvent: (
        <CardEventFormContainer
          card={card}
          onSubmit={card ? onUpdateCardSubmit : onCreateCardSubmit}
          submitError={submitError}
          onClosePress={onClosePress}
          currentPage={currentPage}
          goFirstPage={goFirstPage}
          loading={loading}
        />
      ),
      CardPromotion: (
        <CardPromotionFormContainer
          currentPage={currentPage}
          goFirstPage={goFirstPage}
          card={card}
          onSubmit={card ? onUpdateCardSubmit : onCreateCardSubmit}
          submitError={submitError}
          onClosePress={onClosePress}
          loading={loading}
        />
      ),
      unselect: 'No type selected!',
    }[type];
  };

  const onCreateCardSubmit = async (
    data: PromotionCreateDataType | EventCreateDataType | NewsCreateDataType,
  ) => {
    setLoading(true);
    const cardParams: CardCreateParamsType = {
      ...data,
      merchantId,
      type: cardType as CardTypes,
    };
    const result = await cardsActions.createCard(cardParams);

    if (createCard.fulfilled.match(result)) {
      history.push(closeFormUrl);
    } else if (result.error.message) {
      setLoading(false);
      setSubmitError(result.error.message);
    }
  };

  const onUpdateCardSubmit = async (
    data: PromotionCreateDataType | EventCreateDataType | NewsCreateDataType,
  ) => {
    setLoading(true);
    const cardParams: CardUpdateParamsType = {
      ...data,
      merchantId,
      id: card?.id!,
    };
    const result = await cardsActions.updateCard(cardParams);

    console.log('result', result);
    if (updateCard.fulfilled.match(result)) {
      history.push(closeFormUrl);
    } else if (result.error.message) {
      setLoading(false);
      setSubmitError(result.error.message);
    }
  };

  return <>{renderForm(cardType)}</>;
}
