import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import RouterPath from '../../constants/RouterPath';
import { MerchantType } from '../../features/Card/types';
import { useUserState } from '../../features/User/reducers';
import Trigger from '../Trigger';
import { MenuPropTypes } from '../Menu';
import { DialogContext } from '../Dialog/provider';

type PropsType = {
  merchant: MerchantType;
};

export default function MerchantCard(props: PropsType) {
  const { t } = useTranslation();
  const history = useHistory();
  const [userState] = useUserState();
  const { userLanguage } = userState;
  const { merchant } = props;
  const { cardMerchant } = merchant;
  const dialogContext = useContext(DialogContext);
  const merchantShowUrl = RouterPath.MERCHANT_SHOW.replace(
    ':merchantId',
    merchant.id.toString(),
  );
  const merchantEditUrl = RouterPath.MERCHANT_EDIT.replace(
    ':merchantId',
    merchant.id.toString(),
  );
  const merchantStatsUrl = RouterPath.MERCHANT_STATS.replace(
    ':merchantId',
    merchant.id.toString(),
  );
  const createCardUrl = RouterPath.CREATE_CARDS.replace(
    ':merchantId',
    merchant.id.toString(),
  );

  function generateActionOptions(): MenuPropTypes[] {
    if (merchant.approvalStatus === 'approved') {
      return [
        {
          title: t('features.merchantCardList.createNewCard'),
          onClick: onGoCreateMerchant,
          color: '#006064',
        },
        {
          title: t('features.merchantCardList.editBusiness'),
          onClick: onGoEditMerchant,
          color: '#006064',
        },
        {
          title: t('actions.analytics'),
          onClick: onGoMerchantAnalytics,
        },
      ];
    }
    return [
      {
        title: t('features.merchantCardList.editBusiness'),
        onClick: onGoEditMerchant,
        color: '#006064',
      },
    ];
  }

  const postString =
    userLanguage === 'en'
      ? pluralize(t('features.merchantCardList.post'), merchant.totalCards)
      : t('features.merchantCardList.post');

  function onGoEditMerchant() {
    history.push(merchantEditUrl);
  }

  function onGoCreateMerchant() {
    history.push(createCardUrl);
  }

  function onGoMerchantAnalytics() {
    history.push(merchantStatsUrl);
  }

  function onClickMerchantCard() {
    if (merchant.approvalStatus === 'approved') {
      history.push(merchantShowUrl);
    } else {
      dialogContext.current?.show({
        message: t('features.merchantCardList.waitingApproveMessage'),
        positiveTitle: t('actions.ok'),
      });
    }
  }

  return (
    <div className={style.element} onClick={onClickMerchantCard} aria-hidden>
      <img src={cardMerchant?.photoCover} alt={cardMerchant?.title} />
      <div className={style.infoContainer}>
        <div className={style.row}>
          <div className={style.title} title={cardMerchant?.title}>
            {cardMerchant?.title}
          </div>
          <div
            className={merchant ? style[merchant.approvalStatus] : undefined}
          >
            {t(`features.merchantCardList.${merchant?.approvalStatus}`)}
          </div>
        </div>
        <div className={style.bottomRow}>
          <div>{`${merchant.totalCards} ${postString}`}</div>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            aria-hidden
          >
            <Trigger options={generateActionOptions()}>
              <FontAwesomeIcon
                icon={faCog}
                className={style.options}
                size="2x"
              />
            </Trigger>
          </div>
        </div>
      </div>
    </div>
  );
}
