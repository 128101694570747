import { combineReducers } from '@reduxjs/toolkit';
import userSlice from '../../features/User/reducers';
import cardsSlice from '../../features/Card/reducers';
import merchantSlice from '../../features/Merchant/reducers';

export default combineReducers({
  user: userSlice.reducer,
  cards: cardsSlice.reducer,
  merchant: merchantSlice.reducer,
});
