import Api from './api';
import { OauthTokenType } from '../../features/User/types';
import { AppDispatch } from '../../store';
import { CardType } from '../../features/Card/types';
import { FetchMerchantResponseType } from './types/merchant';
import {
  GetMerchantEditorsParams,
  AddEditorParamsType,
  RemoveEditorParamsType,
} from './types/requestParams';
import {
  FetchMerchantEditorsResponseType,
  ValidateNewEditorResponseType,
} from './types/requestResponse';
import { MerchantEditorType } from '../../features/Merchant/types';

export default class MerchantApi extends Api {
  path: string;

  constructor(dispatch: AppDispatch, userToken?: OauthTokenType) {
    super(dispatch, userToken);
    this.path = '/cms/merchants';
  }

  async fetchMerchant(id: number): Promise<FetchMerchantResponseType> {
    try {
      const uri = `${this.path}/${id}`;
      const response = await this.http.get<CardType>(uri);
      const parsedResult = this.snakeToCamel(response.data);
      return {
        status: 'success',
        result: parsedResult,
      };
    } catch (err) {
      const errors = this.handleError(err.response.data);
      return {
        status: 'failed',
        errors,
      };
    }
  }

  async fetchMerchantEditors(
    params: GetMerchantEditorsParams,
  ): Promise<FetchMerchantEditorsResponseType> {
    const response = await this.request<MerchantEditorType[]>({
      path: `${this.path}/${params.merchantId}/editors`,
      method: 'GET',
      params,
    });
    return response;
  }

  async addMerchantEditor(
    params: AddEditorParamsType,
  ): Promise<FetchMerchantEditorsResponseType> {
    const response = await this.request<any>({
      path: `${this.path}/${params.merchantId}/editors`,
      method: 'POST',
      params,
    });
    return response;
  }

  async validateNewMerchantEditor(
    params: AddEditorParamsType,
  ): Promise<ValidateNewEditorResponseType> {
    const response = await this.request<any>({
      path: `${this.path}/${params.merchantId}/editors/validate`,
      method: 'POST',
      params,
    });
    return response;
  }

  async removeMerchantEditor(params: RemoveEditorParamsType): Promise<any> {
    const response = await this.request<any>({
      path: `${this.path}/${params.merchantId}/editors/${params.id}`,
      method: 'DELETE',
    });
    return response;
  }
}
