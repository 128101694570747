import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import useInterval from '../../utils/hooks/useInterval';
import InputGroupTag from '../Form/components/InputGroupTag';
import style from './style.module.scss';

interface OTPInput {
  error: FieldError;
  onResendPress: () => void;
  initialResendTimeout: number;
}

const DEFAULT_OTP_TIMEOUT = 60;

function getTimeString(timeInSeconds: number) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;

  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
}

const OTPInput = forwardRef<HTMLInputElement, OTPInput>((params, ref) => {
  const { i18n } = useTranslation();
  const { onResendPress, initialResendTimeout, error } = params;
  const [OTPResendTimeout, setOTPResendTimeout] = useState<number>(
    initialResendTimeout,
  );

  function onResendPressHandler() {
    if (OTPResendTimeout === 0) {
      setOTPResendTimeout(DEFAULT_OTP_TIMEOUT);
      onResendPress();
    }
  }

  useInterval({
    callback: () => {
      if (OTPResendTimeout !== 0) setOTPResendTimeout(OTPResendTimeout - 1);
    },
    delay: 1000,
  });

  return (
    <div className={style.container}>
      <InputGroupTag
        id="OTP"
        name="OTP"
        labelText={i18n.t('components.otp.inputLabel')}
        ref={ref}
        error={error}
        useErrorMessage
      />
      {OTPResendTimeout !== 0 ? (
        <div className={style.resendDisabled}>
          {`${i18n.t('features.signUpForm.resendOTP')} (${getTimeString(
            OTPResendTimeout,
          )})`}
        </div>
      ) : (
        <div
          className={style.resend}
          onClick={onResendPressHandler}
          aria-hidden
        >
          {`${i18n.t('features.signUpForm.resendOTP')}`}
        </div>
      )}
    </div>
  );
});

export default OTPInput;
