import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserState } from '../../User/reducers';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { signInOtp } from '../../User/actions';
import RouterPath from '../../../constants/RouterPath';
import AuthContainer from '../../SignUp/components/AuthContainer';
import style from '../../SignUp/components/OTP/style.module.scss';
import Form from '../../../components/Form';
import OtpInput from '../../../components/OTPInput';
import { OTPValidator } from '../../../components/OTPInput/validate';
import Button from '../../../components/Button';
import LoginButton from '../../../components/LoginButton';
import setPageTitle from '../../../utils/pageTitle';

export default function ForgotPasswordOtpScreen() {
  const [userState, userActions] = useUserState();
  const { user } = userState;
  const { handleSubmit, getValues, register, errors } = useForm();
  const [otpError, setOtpError] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageTitle(t('features.forgotPassword.title'));
  }, [t]);

  async function onSubmit() {
    const OTPString = getValues().OTP;
    setLoading(true);
    const res = await userActions.signInOtp(OTPString);
    setLoading(false);
    if (signInOtp.fulfilled.match(res)) {
      history.push(RouterPath.NEW_PASSWORD);
    } else {
      setOtpError(true);
    }
  }

  async function onResend() {
    await userActions.requestForgotPassword(user?.phoneNumber!);
  }

  return (
    <AuthContainer
      title={t('features.signUpForm.otpVerification')}
      noSignInLink
    >
      <div className={style.form}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <OtpInput
              onResendPress={onResend}
              initialResendTimeout={60}
              ref={register(OTPValidator)}
              error={errors.OTP}
            />
            {otpError && (
              <div className={style.error}>{t(userState.apiError!)}</div>
            )}
          </div>
          <div className={style.buttonContainer}>
            {windowSize.width > 800 ? (
              <Button
                type="submit"
                loading={loading}
                title={t('actions.finish')}
              />
            ) : (
              <LoginButton
                loading={loading}
                backgroundColor="white"
                color="#006064"
                title={t('actions.finish')}
                onClick={() => handleSubmit(onSubmit)()}
              />
            )}
          </div>
        </Form>
      </div>
    </AuthContainer>
  );
}
