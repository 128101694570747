import i18next from 'i18next';
import { DeepMap, FieldError } from 'react-hook-form';
import { MAX_FILE_SIZE_KB } from './form/validate';

export default function getErrorKey(
  errorType?: DeepMap<any, FieldError>,
): string | undefined {
  if (errorType) {
    if (errorType.type === 'required') {
      return i18next.t('errors.required');
    }
    if (errorType.type === 'pattern') {
      return i18next.t('errors.pattern');
    }
    if (errorType.type === 'validate') {
      return i18next.t(errorType.message!);
    }
    if (errorType.type === 'maxSize') {
      return i18next.t('errors.image.maxSize', { max: MAX_FILE_SIZE_KB });
    }
  }
  return undefined;
}
