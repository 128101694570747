import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AuthContainer from '../SignUp/components/AuthContainer';
import { ActivateUserParamsType } from '../../services/api/types/user';
import { useUserState } from '../User/reducers';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { requestForgotPassword } from '../User/actions';
import RouterPath from '../../constants/RouterPath';
import Form from '../../components/Form';
import InputGroupTag from '../../components/Form/components/InputGroupTag';
import { PhoneNumberValidate } from '../SignIn/components/SignInForm/validate';
import Button from '../../components/Button';
import style from './style.module.scss';
import LoginButton from '../../components/LoginButton';
import setPageTitle from '../../utils/pageTitle';

export default function ForgotPasswordScreen() {
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm<ActivateUserParamsType>();
  const [isSubmitError, setSubmitError] = useState(false);
  const [userState, userActions] = useUserState();
  const history = useHistory();
  const windowSize = useWindowSize();
  const [loading, setLoading] = useState<boolean>(false);

  async function onSubmit(params: ActivateUserParamsType) {
    const { phoneNumber } = params;
    setLoading(true);
    const result = await userActions.requestForgotPassword(phoneNumber);
    setLoading(false);
    if (requestForgotPassword.fulfilled.match(result)) {
      history.push(RouterPath.FORGOT_PASSWORD_OTP);
    } else {
      setSubmitError(true);
    }
  }

  useEffect(() => {
    setPageTitle(t('features.forgotPassword.title'));
  }, [t]);

  return (
    <AuthContainer
      title={t('features.forgotPassword.title')}
      noSignInLink
      backButton
    >
      <div className="sign-up-form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <InputGroupTag
              id="phoneNumber"
              type="text"
              name="phoneNumber"
              labelText={t('features.forgotPassword.phoneNumberInputLabel')}
              error={errors.phoneNumber}
              autoComplete={false}
              ref={register(PhoneNumberValidate)}
              useErrorMessage
            />

            {isSubmitError && (
              <span className={style.error}>{t(userState!.apiError!)}</span>
            )}
          </div>

          {windowSize.width > 800 && (
            <div className={style.buttonContainer}>
              <Button
                type="submit"
                loading={loading}
                title={t('features.forgotPassword.searchPhoneNumber')}
              />
            </div>
          )}
          {windowSize.width <= 800 && (
            <div className={style.buttonContainer}>
              <LoginButton
                loading={loading}
                title={t('features.forgotPassword.searchPhoneNumber')}
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
                backgroundColor="white"
                color="#006064"
              />
            </div>
          )}
        </Form>
      </div>
    </AuthContainer>
  );
}
