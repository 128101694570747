/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
interface CropImageProps {
  imageSrc: string;
  pixelCrop: any;
}

export default function getCroppedImg(props: CropImageProps): Promise<File> {
  const { imageSrc, pixelCrop } = props;

  const image = new Image();
  image.src = imageSrc;
  image.setAttribute('crossOrigin', 'anonymous');

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx == null) return Promise.reject();

  return new Promise<File>((resolve) => {
    waitForLoadImageDone(imageSrc).then(() => {
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
      );
      canvas.toBlob((file) => {
        resolve(file as File);
      }, 'image/jpeg');
    });
  });

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');
}

function waitForLoadImageDone(imageSrc: string): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve) => {
    const image = new Image();
    image.src = imageSrc;
    image.setAttribute('crossOrigin', 'anonymous');
    image.onload = () => {
      return resolve(image);
    };
    image.onerror = () => {
      return resolve(image);
    };
  });
}
