import { useEffect, useState } from 'react';

export default function useCurrentLocation(): number[] | undefined {
  const [location, setLocation] = useState<number[]>();
  useEffect(() => {
    const geolocation = window.navigator && window.navigator.geolocation;
    if (geolocation) {
      geolocation.getCurrentPosition((position) => {
        setLocation([position.coords.latitude, position.coords.longitude]);
      });
    }
  }, []);
  return location;
}
