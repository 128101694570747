import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import {
  MerchantOpeningTimeType,
  MerchantType,
} from '../../../features/Card/types';
import CardPreview from '../../../features/Card/components/CreateCard/components/CardPreview';
import CreateCardContainer from '../../../features/Card/components/CreateCard/components/CreateCardContainer';
import FormTitle from '../../../features/Card/components/CreateCard/components/FormTitle';
import { MerchantUpdateParamsType } from '../../../services/api/types/merchant';
import MerchantCardForm, { DAYS_OF_WEEK } from '../../MerchantCardForm';
import styles from './style.module.scss';

type PropsType = {
  onSubmit: (data: any) => void;
  submitError: string;
  merchant?: MerchantType;
  closeFormUrl?: string;
  form?: React.ReactNode;
  livePreview?: boolean;
  formTitle?: string;
  onClosePress?: () => void;
  loading: boolean;
};

export default function CardMerchantFormContainer(props: PropsType) {
  const {
    onSubmit,
    submitError,
    merchant,
    closeFormUrl,
    form,
    livePreview = true,
    formTitle,
    onClosePress,
    loading,
  } = props;
  const methods = useForm<MerchantUpdateParamsType>({
    defaultValues: {
      description: merchant?.cardMerchant.description,
      categoryId: merchant?.cardMerchant.categoryId,
      subcategoryIds: merchant?.cardMerchant.subcategoryIds,
      photoCover: merchant?.cardMerchant.photoCover,
      name: merchant?.name,
      email: merchant?.email,
      phoneNumber: merchant?.phoneNumber,
      openingTime: merchant?.openingTime
        ? new Date(merchant?.openingTime)
        : new Date(),
      closingTime: merchant?.closingTime
        ? new Date(merchant?.closingTime)
        : new Date(),
      street: merchant?.street,
      logo: merchant?.logo,
      latitude: merchant?.cardMerchant.latitude,
      longitude: merchant?.cardMerchant.longitude,
      facebookLink: merchant?.cardMerchant.social?.facebook,
      instagramLink: merchant?.cardMerchant?.social?.instagram,
      ecoparkRegionId: merchant?.ecoparkRegionId,
      images: merchant?.images,
      allSelected: !DAYS_OF_WEEK.some(
        (day) =>
          day !== 'all' &&
          merchant?.merchantOpeningTime[
            `${day}Selected` as keyof MerchantOpeningTimeType
          ],
      ),
      allOpeningTime: merchant?.merchantOpeningTime.allOpeningTime
        ? new Date(merchant?.merchantOpeningTime.allOpeningTime)
        : undefined,
      allClosingTime: merchant?.merchantOpeningTime.allClosingTime
        ? new Date(merchant?.merchantOpeningTime.allClosingTime)
        : undefined,
      monSelected: merchant?.merchantOpeningTime.monSelected || false,
      monOpeningTime: merchant?.merchantOpeningTime.monOpeningTime
        ? new Date(merchant?.merchantOpeningTime.monOpeningTime)
        : undefined,
      monClosingTime: merchant?.merchantOpeningTime.monClosingTime
        ? new Date(merchant?.merchantOpeningTime.monClosingTime)
        : undefined,
      tueSelected: merchant?.merchantOpeningTime.tueSelected || false,
      tueOpeningTime: merchant?.merchantOpeningTime.tueOpeningTime
        ? new Date(merchant?.merchantOpeningTime.tueOpeningTime)
        : undefined,
      tueClosingTime: merchant?.merchantOpeningTime.tueClosingTime
        ? new Date(merchant?.merchantOpeningTime.tueClosingTime)
        : undefined,
      wedSelected: merchant?.merchantOpeningTime.wedSelected || false,
      wedOpeningTime: merchant?.merchantOpeningTime.wedOpeningTime
        ? new Date(merchant?.merchantOpeningTime.wedOpeningTime)
        : undefined,
      wedClosingTime: merchant?.merchantOpeningTime.wedClosingTime
        ? new Date(merchant?.merchantOpeningTime.wedClosingTime)
        : undefined,
      thuSelected: merchant?.merchantOpeningTime.thuSelected || false,
      thuOpeningTime: merchant?.merchantOpeningTime.thuOpeningTime
        ? new Date(merchant?.merchantOpeningTime.thuOpeningTime)
        : undefined,
      thuClosingTime: merchant?.merchantOpeningTime.thuClosingTime
        ? new Date(merchant?.merchantOpeningTime.thuClosingTime)
        : undefined,
      friSelected: merchant?.merchantOpeningTime.friSelected || false,
      friOpeningTime: merchant?.merchantOpeningTime.friOpeningTime
        ? new Date(merchant?.merchantOpeningTime.friOpeningTime)
        : undefined,
      friClosingTime: merchant?.merchantOpeningTime.friClosingTime
        ? new Date(merchant?.merchantOpeningTime.friClosingTime)
        : undefined,
      satSelected: merchant?.merchantOpeningTime.satSelected || false,
      satOpeningTime: merchant?.merchantOpeningTime.satOpeningTime
        ? new Date(merchant?.merchantOpeningTime.satOpeningTime)
        : undefined,
      satClosingTime: merchant?.merchantOpeningTime.satClosingTime
        ? new Date(merchant?.merchantOpeningTime.satClosingTime)
        : undefined,
      sunSelected: merchant?.merchantOpeningTime.sunSelected || false,
      sunOpeningTime: merchant?.merchantOpeningTime.sunOpeningTime
        ? new Date(merchant?.merchantOpeningTime.sunOpeningTime)
        : undefined,
      sunClosingTime: merchant?.merchantOpeningTime.sunClosingTime
        ? new Date(merchant?.merchantOpeningTime.sunClosingTime)
        : undefined,
    },
  });

  return (
    <FormProvider {...methods}>
      <CreateCardContainer
        title={
          <FormTitle
            closeFormUrl={closeFormUrl}
            onClosePress={onClosePress}
            title={merchant?.name || formTitle}
          />
        }
        form={
          form || (
            <MerchantCardForm
              loading={loading}
              onSubmit={onSubmit}
              submitError={submitError}
              merchant={merchant}
            />
          )
        }
        example={
          livePreview ? (
            <CardPreview cardType="CardMerchant" type="preview" />
          ) : (
            <div className={styles.emptyExample} />
          )
        }
      />
    </FormProvider>
  );
}
