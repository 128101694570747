import React from 'react';
import { SelectableCardType } from '../../types';
import style from './style.module.scss';
import CardNewsPreview from './CardNewsPreview';
import CardEventPreview from './CardEventPreview';
import CardPromotionPreview from './CardPromotionPreview';
import CardMerchantPreview from './CardMerchantPreview';
import { ReactComponent as EventCardIllustration } from '../../../../../../assets/svg/create_event_card.svg';
import { ReactComponent as NewsCardIllustration } from '../../../../../../assets/svg/create_news_card.svg';
import { ReactComponent as PromotionCardIllustration } from '../../../../../../assets/svg/create_promotion_card.svg';

interface CardPreviewPropTypes {
  cardType: SelectableCardType | 'CardMerchant';
  type: 'preview' | 'illustration';
}

function renderPreview(type: SelectableCardType | 'CardMerchant') {
  switch (type) {
    case 'CardEvent':
      return <CardEventPreview />;
    case 'CardNews':
      return <CardNewsPreview />;
    case 'CardPromotion':
      return <CardPromotionPreview />;
    case 'CardMerchant':
      return <CardMerchantPreview />;
    default:
      return <></>;
  }
}

function renderIllustration(type: SelectableCardType | 'CardMerchant') {
  switch (type) {
    case 'CardEvent':
      return <EventCardIllustration />;
    case 'CardNews':
      return <NewsCardIllustration />;
    case 'CardPromotion':
      return <PromotionCardIllustration />;
    default:
      return <></>;
  }
}

export default function CardPreview(props: CardPreviewPropTypes) {
  const { cardType, type } = props;
  return (
    <div className={style.container}>
      <div className={style.exampleContainer}>
        {type === 'preview' && renderPreview(cardType)}
        {type === 'illustration' && renderIllustration(cardType)}
      </div>
    </div>
  );
}
