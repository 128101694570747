import React from 'react';
import style from '../../style.module.scss';

export default function CreateCardContainer(props: any) {
  const { title, form, example } = props;
  return (
    <>
      <div className={style.createCardContainer}>
        <div className={style.createCardScroll}>
          <div className={style.createCardSection}>
            {title}
            {form}
          </div>
        </div>
        {example}
      </div>
    </>
  );
}
