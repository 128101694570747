import Api from './api';
import {
  ActivateUserParamsType,
  ActivateUserResponseType,
  RequestForgotPasswordResponse,
  RequestForgotPasswordResponseType,
  ResetPasswordResponseType,
  ResetPasswordType,
  SignInOtpType,
  SignInParamsType,
  SignInResponseType,
  SignInSuccessType,
  SignInWithThirdPartiesParamsType,
  SignUpParamsType,
  SignUpResponseType,
  VerifyOTPParamsType,
} from './types/user';
import { OauthTokenType, UserType } from '../../features/User/types';
import { AppDispatch } from '../../store';
import { UpdateUserInfoParams } from './types/requestParams';
import { UpdateUserInfoResponse } from './types/requestResponse';

export default class UserApi extends Api {
  path: string;

  constructor(dispatch: AppDispatch, userToken?: OauthTokenType) {
    super(dispatch, userToken);
    this.path = 'users';
  }

  async signIn(params: SignInParamsType): Promise<SignInResponseType> {
    const res = await this.request<SignInSuccessType>({
      path: 'oauth/token',
      method: 'POST',
      params: {
        grant_type: 'password',
        client_id: process.env.REACT_APP_ECOONE_CLIENT_ID,
        ...params,
      },
    });
    return res;
  }

  async signUp(params: SignUpParamsType): Promise<SignUpResponseType> {
    const res = await this.request<UserType>({
      path: this.path,
      method: 'POST',
      params: {
        client_id: process.env.REACT_APP_ECOONE_CLIENT_ID,
        ...params,
      },
    });
    return res;
  }

  async signInWithThirdParties(
    params: SignInWithThirdPartiesParamsType,
  ): Promise<SignUpResponseType> {
    const { token, provider } = params;
    const res = await this.request<UserType>({
      path: `${this.path}/social_oauths`,
      method: 'POST',
      params: {
        token,
        provider,
        client_id: process.env.REACT_APP_ECOONE_CLIENT_ID,
        platform: 'web',
      },
    });
    return res;
  }

  async verifyOTP(
    params: VerifyOTPParamsType,
  ): Promise<ActivateUserResponseType> {
    const { userId, OTP } = params;
    const res = await this.request<UserType>({
      path: `${this.path}/${userId}/activate`,
      method: 'PATCH',
      params: {
        otp_code: OTP,
      },
    });
    return res;
  }

  async activateUser(
    params: ActivateUserParamsType,
  ): Promise<ActivateUserResponseType> {
    const { phoneNumber, userId } = params;
    const res = await this.request<UserType>({
      path: `${this.path}/${userId}/activate`,
      method: 'POST',
      params: {
        phone_number: phoneNumber,
      },
    });
    return res;
  }

  async requestForgotPassword(
    phoneNumber: string,
  ): Promise<RequestForgotPasswordResponseType> {
    const res = await this.request<RequestForgotPasswordResponse>({
      path: `${this.path}/password_reset`,
      method: 'POST',
      params: {
        phoneNumber,
      },
    });
    return res;
  }

  async signInOtp(params: SignInOtpType): Promise<SignUpResponseType> {
    const { userId, otp } = params;
    const res = await this.request<UserType>({
      path: `users/${userId}/otp/${otp}/token`,
      method: 'POST',
      params: {
        client_id: process.env.REACT_APP_ECOONE_CLIENT_ID,
      },
    });
    return res;
  }

  // Update users password from reset password flow
  async resetPassword(
    params: ResetPasswordType,
  ): Promise<ResetPasswordResponseType> {
    const res = await this.request<UserType>({
      path: `${this.path}/password_reset`,
      method: 'PATCH',
      params,
    });
    return res;
  }

  async updateUserInfo(
    params: UpdateUserInfoParams,
  ): Promise<UpdateUserInfoResponse> {
    const { reachedOnboardingScreen, userId } = params;
    const res = await this.request<UserType>({
      path: `${this.path}/${userId}`,
      method: 'PATCH',
      params: {
        reachedOnboardingScreen,
      },
    });
    return res;
  }
}
