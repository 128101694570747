import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthContainer from '../AuthContainer';
import OtpInput from '../../../../components/OTPInput';
import style from './style.module.scss';
import Button from '../../../../components/Button';
import { useUserState } from '../../../User/reducers';
import Form from '../../../../components/Form';
import { OTPValidator } from '../../../../components/OTPInput/validate';
import { verifyOTP } from '../../../User/actions';
import RouterPath from '../../../../constants/RouterPath';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import LoginButton from '../../../../components/LoginButton';
import setPageTitle from '../../../../utils/pageTitle';

export default function OTP() {
  const [userState, userActions] = useUserState();
  const { user } = userState;
  const { handleSubmit, getValues, register, errors } = useForm();
  const [otpError, setOtpError] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageTitle(t('features.signUpForm.otpVerification'));
  }, [t]);

  async function onSubmit() {
    const OTPString = getValues().OTP;
    setLoading(true);
    const res = await userActions.verifyOTP(OTPString);
    setLoading(false);
    if (verifyOTP.fulfilled.match(res)) {
      history.push(RouterPath.HOME_PAGE);
    } else {
      setOtpError(true);
    }
  }

  async function onResend() {
    await userActions.activateUser(user?.phoneNumber!);
  }

  return (
    <AuthContainer title={t('features.signUpForm.otpVerification')}>
      <div className={style.form}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <OtpInput
              onResendPress={onResend}
              initialResendTimeout={60}
              ref={register(OTPValidator)}
              error={errors.OTP}
            />
            {otpError && (
              <div className={style.error}>{t(userState!.apiError!)}</div>
            )}
          </div>
          <div className={style.buttonContainer}>
            {windowSize.width > 800 ? (
              <Button
                loading={loading}
                title={t('actions.finish')}
                type="submit"
              />
            ) : (
              <LoginButton
                loading={loading}
                backgroundColor="white"
                color="#006064"
                title={t('actions.finish')}
                onClick={() => handleSubmit(onSubmit)()}
              />
            )}
          </div>
        </Form>
      </div>
    </AuthContainer>
  );
}
