export default {
  HOME_PAGE: '/',
  LOGIN: '/login',
  CREATE_MERCHANT: '/merchants/create',
  MERCHANT_SHOW: '/merchants/:merchantId',
  MERCHANT_EDIT: '/merchants/:merchantId/edit',
  CREATE_CARDS: '/merchants/:merchantId/cards/create',
  CARD_EDIT: '/merchants/:merchantId/cards/:id/edit',
  CARD_STATS: '/merchants/:merchantId/cards/:id/stats',
  MERCHANT_STATS: '/merchants/:merchantId/stats',
  MERCHANT_EDITORS: '/merchants/:merchantId/editors',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_OTP: '/forgot-password/otp',
  NEW_PASSWORD: '/forgot-password/new-password',
  SIGNUP: '/signup',
  SIGNUP_PHONE: '/signup/phone',
  SIGNUP_SOCIAL: '/signup/social',
  OTP: '/signup/otp',
};
