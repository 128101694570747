import React, { CSSProperties } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import style from './style.module.scss';
import getErrorKey from '../../../../utils/error';
import RequiredLabel from '../../../RequiredLabel';
import { DATE_FORMATS } from '../../../../utils/time';

type DateTimeInputType = 'time' | 'date' | 'dateTime';

type PropsType = {
  id: string;
  name: string;
  labelText?: string;
  control: Control<any>;
  error: FieldError | undefined;
  rules: object;
  type?: DateTimeInputType;
  placeHolder?: string;
  minDate?: Date;
  disabled?: boolean;
  containerStyle?: CSSProperties;
};

export default function DateTimeInputGroupTag(props: PropsType) {
  const {
    id,
    name,
    labelText,
    control,
    error,
    rules,
    type,
    placeHolder,
    minDate,
    disabled,
    containerStyle,
  } = props;

  return (
    <div className={style.formGroup} style={containerStyle}>
      {labelText && (
        <RequiredLabel
          htmlFor={name}
          title={labelText}
          error={getErrorKey(error)}
        />
      )}

      <Controller
        render={({ value, onChange }) => {
          return (
            <ReactDatePicker
              className={error ? style.error : undefined}
              showTimeSelectOnly={type === 'time'}
              showTimeSelect={type !== 'date'}
              timeFormat={DATE_FORMATS.time}
              dateFormat={DATE_FORMATS[type!]}
              onChange={(date) => {
                onChange(getDateOfTime(type!, date));
              }}
              selected={value ? getDateOfTime(type!, value) : undefined}
              placeholderText={placeHolder}
              minDate={minDate}
              disabled={disabled}
            />
          );
        }}
        id={id}
        control={control}
        name={name}
        rules={rules}
        minDate={minDate}
      />
      {!labelText && (
        <div className={style.errorMessage}>{getErrorKey(error)}</div>
      )}
    </div>
  );
}

DateTimeInputGroupTag.defaultProps = {
  type: 'dateTime',
  minDate: new Date(),
};

// parse date or string to Date
// if date is display as only `time`, need to reset day, month, year
export function getDateOfTime(type: DateTimeInputType, date?: any) {
  if (date) {
    let parseDate: Date;
    if (typeof date === 'string') {
      parseDate = new Date(date);
    } else {
      parseDate = date;
    }
    if (type === 'time') {
      parseDate.setFullYear(2000, 1, 1);
    }
    if (type === 'date') {
      parseDate.setHours(23, 59, 59);
    }
    return parseDate;
  }
  return date;
}
