import React from 'react';
import { SVGCardPropTypes } from '../../../features/Card/components/CreateCard/components/CardTypeSelection';

export default function SvgCoupon(props: SVGCardPropTypes) {
  const { backgroundColor, primaryColor, secondaryColor } = props;
  return (
    <svg width={169} height={207} fill="none">
      <path
        d="M89.5 22.5v-7a1 1 0 00-1-1h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1zm-6.25-2.75l1.25 1.503L86.25 19l2.25 3h-7l1.75-2.25z"
        fill={backgroundColor}
      />
      <path
        d="M162.62 149.675l-.289.337.302.327 2.817 3.053a11.499 11.499 0 013.05 7.8V195c0 6.351-5.149 11.5-11.5 11.5H12C5.649 206.5.5 201.351.5 195v-33.808c0-2.891 1.089-5.676 3.05-7.8l2.817-3.053.302-.327-.29-.337-3.11-3.63A11.5 11.5 0 01.5 138.561V12C.5 5.649 5.649.5 12 .5h145c6.351 0 11.5 5.149 11.5 11.5v126.561c0 2.745-.982 5.4-2.769 7.484l-3.111 3.63z"
        fill={backgroundColor}
        stroke={secondaryColor}
      />
      <path stroke={primaryColor} strokeWidth={8} d="M31 25h107" />
      <path
        stroke={secondaryColor}
        strokeWidth={8}
        strokeLinecap="round"
        d="M75 12h19"
      />
      <path stroke={secondaryColor} strokeWidth={4} d="M53 40h63M53 52h63" />
      <path
        stroke={primaryColor}
        strokeWidth={4}
        d="M12 64h146M12 97h146M12 75h146M12 108h146M12 86h146M12 119h146"
      />
      <path
        d="M6 177c0-6.627 5.373-12 12-12h133c6.627 0 12 5.373 12 12v1c0 6.627-5.373 12-12 12H18c-6.627 0-12-5.373-12-12v-1z"
        fill={secondaryColor}
      />
      <circle cx={21} cy={178} r={10} fill={primaryColor} />
      <path stroke={secondaryColor} strokeDasharray="2 3" d="M6 149.5h157" />
    </svg>
  );
}
