import React from 'react';
import style from '../../style.module.scss';

interface TotalStatsProps {
  userClickedTitle: string;
  totalRedeemTitle: string;
  totalUserClicked?: number;
  totalPromotionRedemptions?: number;
}

export default function TotalStats(props: TotalStatsProps) {
  const {
    userClickedTitle,
    totalRedeemTitle,
    totalPromotionRedemptions,
    totalUserClicked,
  } = props;
  return (
    <>
      <div className={style.couponStats}>
        <div>
          {userClickedTitle}
          <span className={style.statsHighlight}>{totalUserClicked || 0}</span>
        </div>
        <div>
          {totalRedeemTitle}
          <span className={style.statsHighlight}>
            {totalPromotionRedemptions || 0}
          </span>
        </div>
      </div>
    </>
  );
}
