import React, { ReactNode } from 'react';

export type FormPropsType = {
  children: ReactNode;
  onSubmit?: (data: any) => any;
  id?: string;
  onChange?: () => void;
};

export default function Form(props: FormPropsType) {
  const { onSubmit, children, id, onChange } = props;
  return (
    <form id={id} onSubmit={onSubmit} onChange={onChange}>
      {children}
    </form>
  );
}
