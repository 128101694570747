import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardStatus } from '../../../features/Card/types';
import Badge from '../index';

interface PropTypes {
  status: CardStatus;
}

export default function CardStatusBadge(props: PropTypes) {
  const { status } = props;
  const { t } = useTranslation();
  return (
    <>
      {status === 'published' && (
        <Badge title={t('cardStatus.published')} background="#00B07B" />
      )}
      {status === 'draft' && (
        <Badge title={t('cardStatus.draft')} background="#5CAACC" />
      )}
      {status === 'unpublished' && (
        <Badge title={t('cardStatus.unpublished')} background="#767676" />
      )}
    </>
  );
}
