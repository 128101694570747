import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import style from './style.module.scss';
import Form from '../../../../components/Form';
import { MerchantPhoneNumberValidate, PasswordValidate } from './validate';
import { SignInParamsType } from '../../../../services/api/types/user';
import AuthContainer from '../../../SignUp/components/AuthContainer';
import InputGroupTag from '../../../../components/Form/components/InputGroupTag';
import Button from '../../../../components/Button';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import RouterPath from '../../../../constants/RouterPath';
import LoginButton from '../../../../components/LoginButton';

type SignInPropsType = {
  onSubmit: (data: SignInParamsType) => void;
  onSignUp: () => void;
  isSubmitError: boolean;
  apiError?: string;
  loading?: boolean;
};

export default function SignInForm(props: SignInPropsType) {
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm<SignInParamsType>();
  const { onSubmit, isSubmitError, onSignUp, apiError, loading } = props;
  const windowSize = useWindowSize();
  const history = useHistory();

  return (
    <AuthContainer title={t('features.signInForm.formTitle')} noSignInLink>
      <div className={style.signInForm}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputGroupTag
            id="phone-number"
            type="text"
            name="phoneNumber"
            labelText={t('features.signInForm.phoneNumber')}
            autoComplete={false}
            error={errors.phoneNumber}
            ref={register(MerchantPhoneNumberValidate)}
          />

          <InputGroupTag
            id="password"
            type="password"
            name="password"
            labelText={t('features.signInForm.password')}
            autoComplete={false}
            error={errors.password}
            ref={register(PasswordValidate)}
          />

          <div
            className={style.forgotPassword}
            aria-hidden
            onClick={() => history.push(RouterPath.FORGOT_PASSWORD)}
          >
            {t('features.signInForm.forgotPassword')}
          </div>

          {isSubmitError && <div className={style.error}>{apiError}</div>}

          <div className={style.buttonRow}>
            {windowSize.width > 800 ? (
              <>
                <div className={style.signUpButton}>
                  <Button
                    outlined
                    title={t('features.signInForm.signUp')}
                    onClick={onSignUp}
                  />
                </div>
                <div className={style.buttonContainer}>
                  <Button
                    loading={loading}
                    title={t('features.signInForm.submit')}
                    type="submit"
                  />
                </div>
              </>
            ) : (
              <>
                <div className={style.signUpButton}>
                  <LoginButton
                    outlined
                    title={t('features.signInForm.signUp')}
                    onClick={onSignUp}
                    color="white"
                    backgroundColor="white"
                  />
                </div>
                <div className={style.buttonContainer}>
                  <LoginButton
                    loading={loading}
                    title={t('features.signInForm.submit')}
                    onClick={() => handleSubmit(onSubmit)()}
                    color="#006064"
                    backgroundColor="white"
                  />
                </div>
              </>
            )}
          </div>
        </Form>
      </div>
    </AuthContainer>
  );
}
