import React, { useEffect, useState } from 'react';
import '../SignIn/components/SignInForm/style.module.scss';
import { useHistory } from 'react-router-dom';
import { Apple, Facebook } from '@material-ui/icons';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useTranslation } from 'react-i18next';
import RouterPath from '../../constants/RouterPath';
import Loader from '../../components/Loader';
import { useUserState } from '../User/reducers';
import Button from '../../components/Button';
import style from './style.module.scss';
import AuthContainer from './components/AuthContainer';
import { signInWithThirdParties } from '../User/actions';
import { ReactComponent as GoogleLogo } from '../../assets/svg/google.svg';
import { SocialLoginProvider } from '../../services/api/types/user';
import useWindowSize from '../../utils/hooks/useWindowSize';
import LoginButton from '../../components/LoginButton';
import setPageTitle from '../../utils/pageTitle';

export default function SignUpScreen() {
  const [loading, setLoading] = useState<boolean>(false);
  const [userState, userActions] = useUserState();
  const history = useHistory();
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const [thirdPartiesError, setThirdPartiesError] = useState<boolean>(false);

  useEffect(() => {
    loadFBSDK();
    loadAppleSDK();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageTitle(t('features.signUpForm.title'));
  }, [t]);

  useEffect(() => {
    if (userState.user) userActions.signOut();
    // eslint-disable-next-line
  }, []);

  function loadFBSDK() {
    // @ts-ignore
    window.fbAsyncInit = () => {
      // @ts-ignore
      FB.init({
        appId: process.env.REACT_APP_ECOONE_FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v13.0',
      });
    };
    loadSDK('facebook-jssdk', 'https://connect.facebook.net/en_US/sdk.js');
  }

  function loadAppleSDK() {
    // @ts-ignore
    AppleID.auth.init({
      clientId: process.env.REACT_APP_ECOONE_APPLE_CLIENT_ID,
      scope: 'name email',
      redirectURI: window.location.origin,
      usePopup: true,
    });

    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      // @ts-ignore
      const { code } = data.detail.authorization;
      signUpWithThirdParties(code, 'apple');
    });

    document.addEventListener('AppleIDSignInOnFailure', () => {
      setThirdPartiesError(true);
    });
  }

  function loadSDK(id: string, src: string) {
    const fjs = document.getElementsByTagName('script')[0];
    if (document.getElementById(id)) return;
    const js = document.createElement('script');
    js.id = id;
    js.src = src;
    js.crossOrigin = 'anonymous';
    js.defer = true;
    fjs.parentNode?.insertBefore(js, fjs);
  }

  function FBLogin() {
    setThirdPartiesError(false);
    setLoading(true);
    // @ts-ignore
    FB.login((response) => {
      if (
        response.authResponse &&
        Object.prototype.hasOwnProperty.call(
          response.authResponse,
          'accessToken',
        )
      ) {
        const { accessToken } = response.authResponse;
        signUpWithThirdParties(accessToken, 'facebook');
      } else {
        setLoading(false);
        setThirdPartiesError(true);
      }
    });
  }

  function GoogleLoginOnSuccess(
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) {
    setLoading(true);
    setThirdPartiesError(false);
    if (Object.prototype.hasOwnProperty.call(response, 'accessToken')) {
      // @ts-ignore
      signUpWithThirdParties(response.accessToken, 'google_oauth2');
    }
  }

  async function signUpWithThirdParties(
    token: string,
    provider: SocialLoginProvider,
  ) {
    const res = await userActions.signInWithThirdParties({
      provider,
      token,
    });
    if (signInWithThirdParties.fulfilled.match(res)) {
      if (res.payload.user.status === 'inactive') {
        if (res.payload.user.phoneNumber) {
          await userActions.activateUser(res.payload.user.phoneNumber);
          history.push(RouterPath.OTP);
        } else {
          history.push(RouterPath.SIGNUP_SOCIAL);
        }
      } else {
        history.push(RouterPath.HOME_PAGE);
      }
    }
    setLoading(false);
  }

  function AppleLogin() {
    // @ts-ignore
    AppleID.auth.signIn();
    setThirdPartiesError(false);
  }

  function signUpWithPhone() {
    history.push(RouterPath.SIGNUP_PHONE);
  }

  return (
    <AuthContainer title={t('features.signUpForm.title')}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={style.formContainer}>
            <div className={style.socialLoginContainer}>
              <div className={style.buttonContainer}>
                <GoogleLogin
                  // @ts-ignore
                  clientId={process.env.REACT_APP_ECOONE_GOOGLE_CLIENT_ID}
                  onSuccess={(response) => GoogleLoginOnSuccess(response)}
                  onFailure={() => {
                    setThirdPartiesError(true);
                    setLoading(false);
                  }}
                  render={({ onClick }) => (
                    <LoginButton
                      onClick={() => {
                        setLoading(true);
                        setThirdPartiesError(false);
                        onClick();
                      }}
                      title={t('features.signUpForm.google')}
                      icon={<GoogleLogo />}
                      backgroundColor="white"
                      color="rgba(0, 0, 0, 0.54)"
                    />
                  )}
                />
              </div>
              <div className={style.buttonContainer}>
                <LoginButton
                  onClick={FBLogin}
                  title={t('features.signUpForm.facebook')}
                  icon={<Facebook />}
                  backgroundColor="#3B5998"
                />
              </div>
              <LoginButton
                onClick={AppleLogin}
                title={t('features.signUpForm.apple')}
                icon={<Apple />}
                backgroundColor="black"
              />
              {thirdPartiesError && (
                <div className={style.error}>
                  {t('features.signUpForm.thirdPartiesError')}
                </div>
              )}
            </div>
            <div className={style.signUp}>
              {windowSize.width > 800 ? (
                <Button
                  onClick={signUpWithPhone}
                  title={t('features.signUpForm.signUpWithPhone')}
                />
              ) : (
                <LoginButton
                  color="black"
                  backgroundColor="white"
                  onClick={signUpWithPhone}
                  title={t('features.signUpForm.signUpWithPhone')}
                />
              )}
            </div>
          </div>
        </>
      )}
    </AuthContainer>
  );
}
