import React, { useEffect, useState } from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './style.module.scss';
import RequiredLabel from '../../../RequiredLabel';

interface PickLocationMapProps {
  label: string;
  register: any;
  setValue: (name: string, value: number) => void;
  defaultLocation?: number[];
  placeholder?: string;
}

// Pick a coordination on a map
export default function PickLocationMap(props: PickLocationMapProps) {
  const { register, label, defaultLocation, setValue, placeholder } = props;
  const [selectedLocation, setSelectedLocation] = useState<number[]>(
    defaultLocation!,
  );
  const mapRef = React.createRef<GoogleMapReact>();

  function registerValues() {
    register('latitude');
    register('longitude');
  }

  useEffect(registerValues, []);

  return (
    <div className={style.formGroup}>
      <RequiredLabel htmlFor="latitude" title={label} />
      {placeholder && <div className={style.placeholder}>{placeholder}</div>}
      <div className={style.maps}>
        <GoogleMapReact
          ref={mapRef}
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY! }}
          yesIWantToUseGoogleMapApiInternals={false}
          defaultZoom={18}
          defaultCenter={parseToCoords(defaultLocation!)}
          center={parseToCoords(selectedLocation!)}
          onClick={(event) => {
            setValue('latitude', event.lat);
            setValue('longitude', event.lng);
            setSelectedLocation([event.lat, event.lng]);
          }}
          onChange={(value) => {
            setValue('latitude', value.center.lat);
            setValue('longitude', value.center.lng);
            setSelectedLocation([value.center.lat, value.center.lng]);
          }}
        />
        <FontAwesomeIcon
          className={style.centerLocation}
          icon={faMapMarkerAlt}
          color="red"
          size="1x"
        />
      </div>
    </div>
  );
}

PickLocationMap.defaultProps = {
  defaultLocation: [20.961924, 105.931914], // default is ecopark
};

function parseToCoords(latLng: number[]): Coords {
  return {
    lat: parseFloat(latLng[0].toString()),
    lng: parseFloat(latLng[1].toString()),
  };
}
