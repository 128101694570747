import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useUserState } from '../../../User/reducers';
import NavBar from '../../../../components/NavBar';
import Footer from '../../../../components/Footer';
import { useCardsState } from '../../reducers';
import RouterPath from '../../../../constants/RouterPath';
import CouponStats from '../../../../components/CouponStats';
import AgeAndAreaPieCharts from '../../../../components/AgeAndAreaPieChart';
import style from './style.module.scss';
import setPageTitle from '../../../../utils/pageTitle';

export default function CardStats() {
  const [userState] = useUserState();
  const { user } = userState;
  const { t } = useTranslation();
  const [cardState, cardsActions] = useCardsState();
  const { cardStats } = cardState;
  const totalUniqUserClicked = cardStats?.totalUniqUserClicked;
  const totalPromotionRedemptions = cardStats?.totalPromotionRedemptions;

  const { merchantId, id } = useParams();
  const history = useHistory();
  const backUrl = RouterPath.MERCHANT_SHOW.replace(':merchantId', merchantId);

  useEffect(() => {
    cardsActions.getCardStats({ id, merchantId });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageTitle(t('features.cardStats.title'));
  }, [t]);

  function getConversionRate() {
    if (
      totalUniqUserClicked &&
      totalPromotionRedemptions &&
      totalUniqUserClicked !== 0 &&
      totalPromotionRedemptions !== 0
    )
      return (totalPromotionRedemptions / totalUniqUserClicked) * 100;
    return 0;
  }

  return (
    <>
      <NavBar user={user} />
      <section className={style.section}>
        <div className={style.title}>
          <div className={style.titleContainer}>
            <div
              aria-hidden
              className={style.backButton}
              onClick={() => history.push(backUrl)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <span>{t('features.cardStats.title')}</span>
          </div>
        </div>

        <div className={style.ecooneStatsNote}>
          {t('features.merchantStats.ecooneDataSubTitle')}
        </div>

        <div className={style.chartTitle}>
          {t('features.cardStats.couponTitle')}
        </div>

        <CouponStats
          isAllCouponsSelected={false}
          clicked={cardStats?.totalUniqUserClicked}
          totalRedeemed={cardStats?.totalPromotionRedemptions}
          conversionRate={getConversionRate()}
          redeemedWithinMonth={cardStats?.totalWithinMonth}
        />

        <div className={style.statsNote}>
          {t('features.cardStats.couponStatsNote')}
        </div>

        <AgeAndAreaPieCharts
          ageStats={cardStats?.redeemedByUserAgeSegments}
          areaStats={cardStats?.redeemedByUserAreaSegments}
        />
      </section>
      <Footer />
    </>
  );
}
