import { createAsyncThunk } from '@reduxjs/toolkit';
import { isArray } from 'util';
import CardApi from '../../../services/api/card';
import MerchantCardApi from '../../../services/api/merchant_card';
import { RootState } from '../../../store';
import { FetchCardsPayloadType } from '../types';
import {
  CardCreateParamsType,
  CardUpdateParamsType,
  DeleteCardParamsType,
  FetchCardParamsType,
  FetchCardsByMerchantParamsType,
} from '../../../services/api/types/card';
import CategoryApi from '../../../services/api/category';
import EcoparkRegionApi from '../../../services/api/ecoparkRegion';
import {
  FetchMerchantParamsType,
  MerchantUpdateParamsType,
} from '../../../services/api/types/merchant';
import {
  GetCardStatsParams,
  GetMerchantCardsParams,
} from '../../../services/api/types/requestParams';
import MerchantApi from '../../../services/api/merchant';

export const getMerchants = createAsyncThunk(
  'merchantCards/getMerchantCards',
  async (params: GetMerchantCardsParams, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const merchantCardApi = new MerchantCardApi(dispatch, userToken);
    const res = await merchantCardApi.getMerchantCards(params);

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return Promise.reject(res);
  },
);

export const fetchCardsByMerchant = createAsyncThunk(
  'merchantCards/fetchCardsByMerchant',
  async (params: FetchCardsByMerchantParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const cardApi = new CardApi(dispatch, userToken);
    const res = await cardApi.fetchCardsByMerchant(params);

    if (res.status === 'success') {
      return Promise.resolve({
        cards: res.result.data,
        total: res.result.totalEntries,
      } as FetchCardsPayloadType);
    }
    return Promise.reject();
  },
);

export const fetchCategories = createAsyncThunk(
  'merchantCards/fetchCategories',
  async (params: undefined, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const categoryApi = new CategoryApi(dispatch, userToken);
    const res = await categoryApi.categories();

    if (res.status === 'success') {
      return Promise.resolve(res.result.data);
    }
    return Promise.reject();
  },
);

export const fetchEcoparkRegions = createAsyncThunk(
  'merchantCards/fetchEcoparkRegions',
  async (params: undefined, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const ecoparkRegionApi = new EcoparkRegionApi(dispatch, userToken);
    const res = await ecoparkRegionApi.fetchRegions();

    if (res.status === 'success') {
      return Promise.resolve(res.result.data);
    }
    return Promise.reject();
  },
);

export const createCard = createAsyncThunk(
  'cards/create',
  async (params: CardCreateParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const cardApi = new CardApi(dispatch, userToken);
    const res = await cardApi.createCard(params);

    if (res.status === 'success') {
      return Promise.resolve();
    }
    if (isArray(res.errors)) {
      return Promise.reject(res.errors[0]);
    }
    return Promise.reject(res.errors.code);
  },
);

export const updateCard = createAsyncThunk(
  'cards/update',
  async (params: CardUpdateParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const cardApi = new CardApi(dispatch, userToken);
    const res = await cardApi.updateCard(params);

    if (res.status === 'success') {
      return Promise.resolve(res.result.data);
    }
    if (isArray(res.errors)) {
      return Promise.reject(res.errors[0]);
    }
    return Promise.reject(res.errors.code);
  },
);

export const deleteCard = createAsyncThunk(
  'merchantCards/deleteCard',
  async (params: DeleteCardParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const cardApi = new CardApi(dispatch, userToken);
    const res = await cardApi.deleteCard(params);

    if (res.status === 'success') {
      return Promise.resolve(params);
    }
    if (isArray(res.errors)) {
      return Promise.reject(res.errors[0]);
    }
    return Promise.reject(res.errors.code);
  },
);

export const fetchCard = createAsyncThunk(
  'cards/fetchCard',
  async (params: FetchCardParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const cardApi = new CardApi(dispatch, userToken);
    const res = await cardApi.fetchCard(params);

    if (res.status === 'success') {
      return Promise.resolve(res.result.data);
    }
    return Promise.reject();
  },
);

export const fetchMerchant = createAsyncThunk(
  'cards/fetchMerchant',
  async (params: FetchMerchantParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const merchantApi = new MerchantApi(dispatch, userToken);
    const res = await merchantApi.fetchMerchant(params.id);

    if (res.status === 'success') {
      return Promise.resolve(res.result.data);
    }
    return Promise.reject();
  },
);

export const updateMerchant = createAsyncThunk(
  'merchant/update',
  async (params: MerchantUpdateParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const merchantApi = new MerchantCardApi(dispatch, userToken);
    const res = await merchantApi.updateMerchant(params);

    if (res.status === 'success') {
      return Promise.resolve();
    }
    if (isArray(res.errors)) {
      return Promise.reject(res.errors[0]);
    }
    return Promise.reject(res.errors.code);
  },
);

export const getCardStats = createAsyncThunk(
  'cards/getStats',
  async (params: GetCardStatsParams, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const cardApi = new CardApi(dispatch, userToken);
    const res = await cardApi.fetchCardStats(params);

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return Promise.reject(res);
  },
);

export const getUserStats = createAsyncThunk(
  'cards/getUserStats',
  async (_params, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const cardApi = new CardApi(dispatch, userToken);
    const res = await cardApi.fetchUserStats();

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return Promise.reject(res);
  },
);

export const createMerchant = createAsyncThunk(
  'merchants/create',
  async (params: MerchantUpdateParamsType, { dispatch, getState }) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;

    const merchantApi = new MerchantCardApi(dispatch, userToken);
    const res = await merchantApi.createMerchant(params);

    if (res.status === 'success') {
      return Promise.resolve();
    }
    if (isArray(res.errors)) {
      return Promise.reject(res.errors[0]);
    }
    return Promise.reject(res.errors.code);
  },
);
