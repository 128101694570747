import { useSelector } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LanguageType,
  OauthTokenType,
  SignInPayloadType,
  UserStateType,
  UserType,
} from '../types';
import {
  activateUser,
  requestForgotPassword,
  resetPassword,
  signIn,
  signInOtp,
  signInWithThirdParties,
  signUp,
  updateUserInfo,
  verifyOTP,
} from '../actions';
import { RootState, useAppDispatch } from '../../../store';
import {
  SignInParamsType,
  SignInWithThirdPartiesParamsType,
  SignUpParamsType,
} from '../../../services/api/types/user';
import { ApiSuccessType } from '../../../services/api/types/api';
import { UpdateUserInfoParams } from '../../../services/api/types/requestParams';

const initialState: UserStateType = {
  user: undefined,
  userToken: undefined,
  isLoggedIn: false,
  userLanguage: 'vn',
  apiError: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<OauthTokenType>) {
      state.userToken = action.payload;
      state.isLoggedIn = true;
    },
    signOut(state) {
      state.user = initialState.user;
      state.userToken = initialState.userToken;
      state.isLoggedIn = false;
    },
    changeUserLanguage(state, action: PayloadAction<LanguageType>) {
      state.userLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      signIn.fulfilled,
      (state, action: PayloadAction<SignInPayloadType>) => {
        state.user = action.payload.user;
        state.userToken = action.payload.token;
        state.isLoggedIn = true;
      },
    );
    builder.addCase(signIn.rejected, (state, action: PayloadAction<any>) => {
      state.apiError = action.payload;
    });

    builder.addCase(
      signInWithThirdParties.fulfilled,
      (state, action: PayloadAction<SignInPayloadType>) => {
        state.user = action.payload.user;
        state.userToken = {
          ...action.payload.token,
          tokenType: 'Bearer',
        };
        if (action.payload.user.status === 'active') {
          state.isLoggedIn = true;
        }
      },
    );
    builder.addCase(
      signInWithThirdParties.rejected,
      (state, action: PayloadAction<any>) => {
        state.apiError = action.payload;
      },
    );

    builder.addCase(
      activateUser.fulfilled,
      (state, action: PayloadAction<ApiSuccessType<UserType>>) => {
        state.user = action.payload.result.data;
      },
    );
    builder.addCase(
      activateUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.apiError = action.payload;
      },
    );

    builder.addCase(
      verifyOTP.fulfilled,
      (state, action: PayloadAction<ApiSuccessType<UserType>>) => {
        state.user = action.payload.result.data;
        state.isLoggedIn = true;
      },
    );
    builder.addCase(verifyOTP.rejected, (state, action: PayloadAction<any>) => {
      state.apiError = action.payload;
    });

    builder.addCase(
      signUp.fulfilled,
      (state, action: PayloadAction<SignInPayloadType>) => {
        state.user = action.payload.user;
        state.userToken = {
          ...action.payload.token,
          tokenType: 'Bearer',
        };
      },
    );
    builder.addCase(signUp.rejected, (state, action: PayloadAction<any>) => {
      state.apiError = action.payload;
    });

    builder.addCase(
      requestForgotPassword.fulfilled,
      (state, action: PayloadAction<UserType>) => {
        state.user = action.payload;
      },
    );
    builder.addCase(
      requestForgotPassword.rejected,
      (state, action: PayloadAction<any>) => {
        state.apiError = action.payload;
      },
    );

    builder.addCase(
      signInOtp.fulfilled,
      (state, action: PayloadAction<SignInPayloadType>) => {
        state.user = action.payload.user;
        state.userToken = {
          ...action.payload.token,
          tokenType: 'Bearer',
        };
      },
    );
    builder.addCase(signInOtp.rejected, (state, action: PayloadAction<any>) => {
      state.apiError = action.payload;
    });

    builder.addCase(
      resetPassword.fulfilled,
      (state, action: PayloadAction<ApiSuccessType<UserType>>) => {
        state.user = action.payload.result.data;
        state.isLoggedIn = true;
      },
    );
    builder.addCase(
      resetPassword.rejected,
      (state, action: PayloadAction<any>) => {
        state.apiError = action.payload;
      },
    );

    builder.addCase(
      updateUserInfo.fulfilled,
      (state, action: PayloadAction<ApiSuccessType<UserType>>) => {
        state.user = action.payload.result.data;
      },
    );
    builder.addCase(
      updateUserInfo.rejected,
      (state, action: PayloadAction<any>) => {
        state.apiError = action.payload;
      },
    );
  },
});

export function useUserState() {
  const userState = useSelector(
    (state: RootState) => state.user,
  ) as UserStateType;
  const dispatch = useAppDispatch();

  const userActions = {
    signIn: (params: SignInParamsType) => dispatch(signIn(params)),
    signOut: () => dispatch(userSlice.actions.signOut()),
    changeUserLanguage: (params: LanguageType) =>
      dispatch(userSlice.actions.changeUserLanguage(params)),
    signInWithThirdParties: (params: SignInWithThirdPartiesParamsType) =>
      dispatch(signInWithThirdParties(params)),
    activateUser: (phoneNumber: string) => dispatch(activateUser(phoneNumber)),
    verifyOTP: (otp: string) => dispatch(verifyOTP(otp)),
    signUp: (params: SignUpParamsType) => dispatch(signUp(params)),
    requestForgotPassword: (phoneNumber: string) =>
      dispatch(requestForgotPassword(phoneNumber)),
    signInOtp: (otp: string) => dispatch(signInOtp(otp)),
    resetPassword: (password: string) => dispatch(resetPassword(password)),
    updateUserInfo: (params: UpdateUserInfoParams) =>
      dispatch(updateUserInfo(params)),
  };
  return [userState, userActions] as [typeof userState, typeof userActions];
}

export default userSlice;
