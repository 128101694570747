import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SVG } from '../../../assets/svg/cta.svg';
import styles from './style.module.scss';
import LoginButton from '../../../components/LoginButton';

interface Props {
  buttonTitle: string;
  onButtonClick: () => void;
}

export default function CTA(props: Props) {
  const { buttonTitle, onButtonClick } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.svgMobile}>
        <SVG width="100%" />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>{t('components.cta.title')}</h1>
        <div className={styles.description}>
          {t('components.cta.description')}
        </div>
        <div className={styles.buttonContainer}>
          <LoginButton
            title={buttonTitle}
            onClick={onButtonClick}
            backgroundColor="#00B07B"
          />
        </div>
      </div>
      <div className={styles.svg}>
        <SVG width="100%" />
      </div>
    </div>
  );
}
