import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { persistor, store } from './store';
import AppRouter from './router';
import { DialogProvider } from './components/Dialog/provider';

function App() {
  return (
    <ThemeProvider
      theme={createMuiTheme({
        palette: {
          primary: {
            main: '#006064',
          },
          secondary: {
            main: '#ee5b46',
          },
        },
      })}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <DialogProvider>
            <AppRouter />
          </DialogProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
