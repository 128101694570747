import React, { ReactNode } from 'react';
import { CircularProgress } from '@material-ui/core';
import style from './style.module.scss';

interface ButtonPropTypes {
  title: string;
  onClick: () => void;
  outlined?: boolean;
  icon?: ReactNode;
  backgroundColor?: string;
  color?: string;
  loading?: boolean;
}

export default function LoginButton(params: ButtonPropTypes) {
  const {
    title,
    onClick,
    outlined,
    icon,
    backgroundColor,
    color,
    loading,
  } = params;

  return outlined ? (
    <div
      className={style.outlinedButton}
      onClick={onClick}
      aria-hidden
      style={{ borderColor: backgroundColor, color }}
    >
      {icon && <div style={{ marginRight: 10 }}>{icon}</div>}
      {loading ? (
        <CircularProgress
          className={style.loading}
          size={18.5}
          thickness={5}
          color="inherit"
        />
      ) : (
        title
      )}
    </div>
  ) : (
    <div
      className={style.button}
      onClick={onClick}
      aria-hidden
      style={{ backgroundColor, color }}
    >
      {icon && <div style={{ marginRight: 10 }}>{icon}</div>}
      {loading ? (
        <CircularProgress
          className={style.loading}
          size={18.5}
          thickness={5}
          color="inherit"
        />
      ) : (
        title
      )}
    </div>
  );
}
