import React, { ReactNode } from 'react';
import style from './style.module.scss';

interface Props {
  children: ReactNode;
  onClick: () => void;
  color: string;
  outlined?: boolean;
}

export default function MobileButton(params: Props) {
  const { children, onClick, color, outlined } = params;
  return outlined ? (
    <div
      aria-hidden
      onClick={onClick}
      className={style.button}
      style={{ color, border: `1px solid ${color}` }}
    >
      {children}
    </div>
  ) : (
    <div
      aria-hidden
      onClick={onClick}
      className={style.button}
      style={{ color: 'white', backgroundColor: color }}
    >
      {children}
    </div>
  );
}
