import React, { ReactNode } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import style from './style.module.scss';

interface PropsType {
  children: ReactNode;
  open: boolean;
  handleClose: () => void;
}

export default function ModalGroup(props: PropsType) {
  const { children, open, handleClose } = props;

  return (
    <Modal
      className={style.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={style.paper}>{children}</div>
      </Fade>
    </Modal>
  );
}
