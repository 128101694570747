import React from 'react';
import { Controller, FieldError } from 'react-hook-form';
import className from 'classnames';
import { Radio, RadioGroup } from '@material-ui/core';
import style from './style.module.scss';
import RequiredLabel from '../../../RequiredLabel';
import getErrorKey from '../../../../utils/error';

interface PropsType {
  name: string;
  title: string;
  error: FieldError | undefined;
  defaultValue?: string;
  klassName?: string;
  data: any[];
  renderItems: (item: any, disable: boolean) => React.ReactNode;
  rules: any;
  control: any;
}

export default function InputRadioGroupTag(props: PropsType) {
  const {
    name,
    title,
    error,
    defaultValue,
    klassName,
    data,
    renderItems,
    rules,
    control,
  } = props;

  return (
    <div className={className(style.formGroup, klassName)}>
      <RequiredLabel htmlFor={name} title={title} error={getErrorKey(error)} />
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ value, onChange }) => {
          return (
            <RadioGroup
              aria-label={name}
              name={name}
              value={value}
              onChange={(event, selected) => onChange(selected)}
            >
              {data &&
                data.map((item) => (
                  <div
                    aria-hidden
                    key={item.value}
                    onClick={() => onChange(item.value)}
                  >
                    <Radio value={item.value} checked={item.value === value} />
                    {renderItems && renderItems(item, value !== item.value)}
                  </div>
                ))}
            </RadioGroup>
          );
        }}
      />
    </div>
  );
}
