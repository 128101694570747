import { CircularProgress } from '@material-ui/core';
import React from 'react';
import style from './style.module.css';

export default function Loader() {
  return (
    <div className={style.loading}>
      <CircularProgress />
    </div>
  );
}
