import { useFormContext, Controller } from 'react-hook-form';
import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControlLabel,
  Switch,
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import style from './style.module.scss';
import InputGroupTag from '../Form/components/InputGroupTag';
import UploadImageGroupTag from '../Form/components/UploadImageGroupTag';
import Button from '../Button';
import DropdownGroupTag from '../Form/components/DropdownGroupTag';
import { useCardsState } from '../../features/Card/reducers';
import { useUserState } from '../../features/User/reducers';
import DateTimeInputGroupTag from '../Form/components/DateTimeInputGroupTag';
import PickLocationMap from '../Form/components/PickLocationMap';
import useCurrentLocation from '../../utils/hooks/useCurrentLocation';
import {
  BUSINESS_NAME_MAX_LENGTH,
  MerchantPhoneNumberValidation,
  DescriptionValidate,
  RequiredValidate,
  TitleValidate,
  SubcategoryValidation,
  SomeSelectedValidate,
  DateTimeValidate,
} from '../../utils/form/validate';
import CKEditorGroupTag from '../Form/components/CKEditorGroupTag';
import { MerchantUpdateParamsType } from '../../services/api/types/merchant';
import { MerchantType } from '../../features/Card/types';
import Form from '../Form';
import { EmailValidator } from '../../features/SignUp/components/SignUpPhone/validate';
import GalleryImage from '../Form/components/GalleryImage';
import ChipSelectGroupTag from '../Form/components/ChipSelectGroupTag';
import RequiredLabel from '../RequiredLabel';
import getErrorKey from '../../utils/error';

interface MerchantCardFormProps {
  merchant?: MerchantType;
  onSubmit: (data: MerchantUpdateParamsType) => void;
  loading: boolean;
  submitError: string;
}

export const DAYS_OF_WEEK = [
  'all',
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
];

// Form to update Merchant Card
export default function MerchantCardForm(props: MerchantCardFormProps) {
  const { onSubmit, submitError, merchant, loading } = props;
  const {
    register,
    errors,
    setValue,
    setError,
    handleSubmit,
    control,
    watch,
  } = useFormContext<MerchantUpdateParamsType>();
  const { t } = useTranslation();
  const [userState] = useUserState();
  const currentLocation = useCurrentLocation();
  const [cardsState] = useCardsState();
  const isCreateNewMerchant = !merchant;
  const allSelected = watch('allSelected');
  const galleryImages = watch('images');
  const attachmentRemovedIds = watch('attachmentRemovedIds');
  const [showError, setShowError] = useState<boolean>(false);
  const submitRef = createRef<HTMLInputElement>();
  const selectedCategoryId = watch('categoryId');
  const selectedCategory = cardsState.categories?.find(
    (category) => selectedCategoryId?.toString() === category.id.toString(),
  );

  function getMerchantLocation(): number[] | undefined {
    if (merchant?.cardMerchant?.latitude && merchant?.cardMerchant?.longitude) {
      return [merchant.cardMerchant.latitude, merchant.cardMerchant.longitude];
    }
    return currentLocation;
  }

  function handleFormSubmit() {
    setShowError(true);
    setTimeout(() => setShowError(false), 2000);
    submitRef.current?.click();
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputGroupTag
        id="name"
        name="name"
        labelText={t('features.merchantUpdate.businessName')}
        maxLength={BUSINESS_NAME_MAX_LENGTH}
        autoComplete={false}
        defaultValue={merchant?.name}
        ref={register(TitleValidate)}
        error={errors.name}
      />

      <div className={style.rowGroup}>
        <UploadImageGroupTag
          name="photoCover"
          title={t('features.merchantUpdate.photoCover')}
          ref={register}
          setValue={setValue}
          setError={setError}
          defaultValue={merchant?.cardMerchant?.photoCover}
          selectedFile={merchant?.photoCoverFile}
          error={errors.photoCover}
          type="merchant"
          placeholder={t('features.merchantUpdate.photoCoverPlaceholder')}
        />

        <UploadImageGroupTag
          name="logo"
          title={t('features.merchantUpdate.logo')}
          ref={register}
          setValue={setValue}
          setError={setError}
          defaultValue={merchant?.logo}
          selectedFile={merchant?.logoFile}
          error={errors.logo}
          type="merchant"
          placeholder={t('features.merchantUpdate.photoCoverPlaceholder')}
        />
      </div>

      <div className={style.halfRow}>
        <DropdownGroupTag
          id="category-id"
          name="categoryId"
          ref={register(RequiredValidate)}
          title={t('features.merchantUpdate.category')}
          data={cardsState.categories}
          onChange={() => {
            setValue('subcategoryIds', []);
          }}
          renderItem={(item) => (
            <option key={item.id} value={item.id}>
              {item.name[userState.userLanguage]}
            </option>
          )}
        />
      </div>

      {selectedCategory?.subcategories &&
        selectedCategory?.subcategories.length > 0 && (
          <div className={style.rowGroup}>
            <ChipSelectGroupTag
              title={t('features.merchantUpdate.subcategory')}
              name="subcategoryIds"
              rules={SubcategoryValidation}
              error={errors.subcategoryIds}
              control={control}
              data={
                selectedCategory?.subcategories?.map((category) => ({
                  value: category.id,
                  title: category.name[userState.userLanguage],
                })) || []
              }
            />
          </div>
        )}
      <div className={style.operationHourContainer}>
        <div className={style.operationHourTitle}>
          <RequiredLabel
            title={`${t('components.cardMerchant.openingHours')}*`}
            htmlFor="operationHours"
            error={getErrorKey(errors.allSelected)}
          />
        </div>
        <div className={style.timeGrid}>
          {DAYS_OF_WEEK.map((day) => (
            <>
              <FormControlLabel
                classes={{
                  root: style.switchLabel,
                  label: style.label,
                }}
                style={{
                  display: allSelected && day !== 'all' ? 'none' : undefined,
                }}
                control={
                  <Controller
                    id={`${day}-selected`}
                    name={`${day}Selected`}
                    rules={SomeSelectedValidate(
                      DAYS_OF_WEEK.map((weekday) =>
                        watch(`${weekday}Selected`),
                      ),
                    )}
                    render={({ value, onChange }) => (
                      <AntSwitch
                        checked={value}
                        onChange={(e) => {
                          if (day === 'all' && e.target.checked) {
                            DAYS_OF_WEEK.forEach((item) => {
                              if (item !== 'all') {
                                setValue(`${item}Selected`, false);
                              }
                            });
                          }
                          onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                }
                label={t(`date.daysOfWeek.${day}`)}
              />
              <DateTimeInputGroupTag
                id={`${day}-opening-time`}
                name={`${day}OpeningTime`}
                control={control}
                // @ts-ignore
                error={errors[`${day}OpeningTime`]}
                rules={watch(`${day}Selected`) && RequiredValidate}
                type="time"
                placeHolder={t('placeholders.clickToSelect')}
                disabled={!watch(`${day}Selected`)}
                containerStyle={{
                  display: allSelected && day !== 'all' ? 'none' : undefined,
                  margin: 0,
                }}
              />
              <DateTimeInputGroupTag
                id={`${day}-closing-time`}
                name={`${day}ClosingTime`}
                control={control}
                // @ts-ignore
                error={errors[`${day}ClosingTime`]}
                rules={
                  watch(`${day}Selected`) &&
                  DateTimeValidate(watch(`${day}OpeningTime`))
                }
                type="time"
                placeHolder={t('placeholders.clickToSelect')}
                disabled={!watch(`${day}Selected`)}
                containerStyle={{
                  display: allSelected && day !== 'all' ? 'none' : undefined,
                  margin: 0,
                }}
              />
            </>
          ))}
        </div>
      </div>

      <div className={style.rowGroup}>
        <DropdownGroupTag
          id="ecopark-region-id"
          name="ecoparkRegionId"
          ref={register(RequiredValidate)}
          title={t('features.merchantUpdate.ecoparkArea')}
          data={cardsState.ecoparkRegions}
          renderItem={(item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          )}
        />
      </div>

      <div className={style.rowGroup}>
        <InputGroupTag
          id="email"
          name="email"
          labelText={t('features.merchantUpdate.email')}
          autoComplete={false}
          ref={register(EmailValidator)}
          error={errors.email}
          defaultValue={merchant?.email}
        />
        <InputGroupTag
          id="phone-number"
          name="phoneNumber"
          labelText={t('features.merchantUpdate.phoneNumber')}
          autoComplete={false}
          ref={register(MerchantPhoneNumberValidation)}
          error={errors.phoneNumber}
          defaultValue={merchant?.phoneNumber}
          useErrorMessage
        />
      </div>

      <div className={style.rowGroup}>
        <InputGroupTag
          id="facebook-link"
          name="facebookLink"
          labelText={t('features.merchantUpdate.facebookLink')}
          autoComplete={false}
          error={errors.facebookLink}
          ref={register}
          type="url"
          defaultValue={merchant?.cardMerchant?.social?.facebook}
          placeholder={t('placeholders.url')}
        />
        <InputGroupTag
          id="instagram-link"
          name="instagramLink"
          labelText={t('features.merchantUpdate.instagramLink')}
          autoComplete={false}
          error={errors.instagramLink}
          ref={register}
          type="url"
          defaultValue={merchant?.cardMerchant?.social?.instagram}
          placeholder={t('placeholders.url')}
        />
      </div>

      <div className={style.rowGroup}>
        <InputGroupTag
          id="street-number"
          name="streetNumber"
          labelText={t('features.merchantUpdate.streetNumber')}
          autoComplete={false}
          ref={register(RequiredValidate)}
          error={errors.streetNumber}
          defaultValue={merchant?.streetNumber}
        />
        <InputGroupTag
          id="street"
          name="street"
          labelText={t('features.merchantUpdate.streetAddress')}
          autoComplete={false}
          ref={register(RequiredValidate)}
          error={errors.street}
          defaultValue={merchant?.street}
        />
      </div>

      <PickLocationMap
        defaultLocation={getMerchantLocation()}
        label={t('features.merchantUpdate.addressCoordinate')}
        register={register}
        setValue={setValue}
        placeholder={t('features.createMerchant.placeholders.pickLocation')}
      />

      <CKEditorGroupTag
        name="description"
        labelText={t('features.merchantUpdate.description')}
        rules={DescriptionValidate}
        control={control}
        error={errors.description}
        imageUploadable
      />

      <GalleryImage
        name="images"
        register={register}
        setValue={setValue}
        imageFiles={galleryImages}
        attachmentRemovedIds={attachmentRemovedIds}
        defaultValue={merchant?.imageAttachments}
      />

      {showError && (submitError || Object.keys(errors).length > 0) && (
        <div className={style.error}>
          {submitError || t('errors.form.checkFormErrors')}
        </div>
      )}

      <div>
        <Button
          title={t(!isCreateNewMerchant ? 'actions.save' : 'actions.next')}
          klassName={style.submitButton}
          loading={loading}
          onClick={() => {
            handleFormSubmit();
          }}
        />
      </div>

      <input type="submit" ref={submitRef} hidden />
    </Form>
  );
}

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      marginRight: 10,
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);
