import React, { ReactNode } from 'react';
import { CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import style from './style.module.scss';

interface ButtonPropTypes {
  title: string;
  /* Parent class CSS will override button component CSS - please don't abuse this! */
  klassName?: string;
  onClick?: () => void;
  disabled?: boolean;
  outlined?: boolean;
  icon?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  form?: string;
  loading?: boolean;
}

export default function Button(params: ButtonPropTypes) {
  const {
    title,
    onClick,
    disabled,
    outlined,
    icon,
    type = 'button',
    form,
    klassName,
    loading,
  } = params;

  const isDisabled = disabled || loading;
  const buttonClassNames = calculateButtonClassNames(
    isDisabled,
    outlined,
    klassName,
  );

  /* eslint-disable react/button-has-type */
  return (
    <button
      className={classnames(buttonClassNames)}
      onClick={onClick}
      type={type}
      form={form}
      disabled={disabled || loading}
    >
      {loading ? (
        <CircularProgress size={18.5} thickness={5} color="inherit" />
      ) : (
        <>
          {icon && <div className={style.icon}>{icon}</div>}
          {title}
        </>
      )}
    </button>
  );
}

function calculateButtonClassNames(
  isDisabled?: boolean,
  outlined?: boolean,
  klassName?: string,
): string[] {
  const classNames: string[] = [style.button];
  if (outlined) {
    classNames.push(style.outlinedButton);
  } else {
    // Default button
    classNames.push(style.primaryButton);
  }
  if (isDisabled) {
    classNames.push(style.buttonDisabled);
  }
  if (klassName) {
    classNames.push(klassName);
  }
  return classNames;
}
