import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import ModalGroup from '../ModalGroup';
import style from './style.module.scss';

interface PropsType {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  imageUrl?: string;
}

export default function ImagePreviewModal(props: PropsType) {
  const { open, handleClose, handleDelete, imageUrl } = props;
  return (
    <ModalGroup open={open} handleClose={handleClose}>
      <div className={style.modal}>
        <div className={style.header}>
          <div className={style.modalAction}>
            <span
              className={style.removeImage}
              onClick={handleDelete}
              aria-hidden
            >
              <FontAwesomeIcon icon={faTrash} /> Delete
            </span>
          </div>
          <div className={style.closeIcon} onClick={handleClose} aria-hidden>
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </div>
        </div>
        <div className={style.image}>
          <img src={imageUrl} alt="" />
        </div>
      </div>
    </ModalGroup>
  );
}
