import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import style from './style.module.scss';
import { useCardsState } from '../../../../../reducers';
import { ReactComponent as CouponSlideIcon } from '../../../../../../../assets/svg/couponSlide.svg';
import { DEFAULT_FORM_DATA } from '../../../../../../../utils/card';
import { MOMENT_DATE_FORMATS } from '../../../../../../../utils/time';
import HtmlContent from '../HtmlContent';
import CardContainer from '../CardContainer';

export default function CardPromotionPreview() {
  const [cardsState] = useCardsState();
  const { merchant } = cardsState;
  const { t } = useTranslation();
  const formContext = useFormContext();
  let formData: any;

  if (formContext) {
    formData = formContext.watch();
  }

  function getPromotionTypeText(): string {
    return formData && formData.promotionType
      ? t(
          `components.cardPromotion.typesInPreview.${formData.promotionType}`,
          formData,
        )
      : '';
  }

  return (
    <CardContainer hasCoverImage={false}>
      <div className={style.couponTop}>
        <div className={style.row}>
          {formData && (
            <div className={style.couponType}>{getPromotionTypeText()}</div>
          )}

          <div className={style.expireContainer}>
            <div className={style.expireAt}>
              {t('components.cardPromotion.preview.expireAt')}
            </div>
            <div className={style.expireDate}>
              {formData && formData.expiry
                ? moment(formData.expiry).format(MOMENT_DATE_FORMATS.date)
                : DEFAULT_FORM_DATA.fullDate}
            </div>
          </div>
        </div>
        <div className={style.titleContainer}>
          <h2>
            {formData && formData.title && formData.title.length > 0
              ? formData.title
              : t(DEFAULT_FORM_DATA.promotionTitle)}
          </h2>
        </div>
        <div className={style.authorContainer}>
          <div
            className={style.profileImage}
            style={{ backgroundImage: `url(${merchant?.logo})` }}
          />
          <div>{`${merchant?.name}`}</div>
        </div>

        <HtmlContent
          html={
            formData && formData.description && formData.description.length > 0
              ? formData.description
              : `<i>${t(DEFAULT_FORM_DATA.description.promotion)}</i>`
          }
        />
      </div>
      <div className={style.couponBottom}>
        <div className={style.couponText}>
          {t('components.cardPromotion.redeemDescription')}
        </div>
        <div className={style.slider}>
          <div className={style.sliderButton}>
            <CouponSlideIcon />
          </div>
          <div className={style.sliderText}>
            {t('components.cardPromotion.slideToRedeem')}
          </div>
        </div>
      </div>
    </CardContainer>
  );
}
