import React from 'react';
import { useTranslation } from 'react-i18next';
import { Facebook } from '@material-ui/icons';
import Ecotek from '../../assets/svg/ecotek.svg';
import style from './style.module.scss';

export const FACEBOOK_GROUP =
  'https://www.facebook.com/groups/ecoone.ecotekcorp';

export default function Footer() {
  const { t } = useTranslation();

  function onFacebookGroupClick() {
    window.open(FACEBOOK_GROUP);
  }

  return (
    <nav className={style.nav}>
      <div className={style.container}>
        <img src={Ecotek} alt="" />
        <div className={style.copyright}>
          {t('components.footer.copyright')}
        </div>
        <div className={style.spacer} />

        {/* {TODO need to add link here} */}
        {/* <Link className={style.link} to="/">
          {t('components.footer.help')}
        </Link> */}
        <div
          className={style.socialIcon}
          onClick={onFacebookGroupClick}
          aria-hidden
        >
          <Facebook />
        </div>
        {/* {TODO need to add link here} */}
        {/* <Link className={style.link} to="/">
          {t('components.footer.privatePolicy')}
        </Link> */}
      </div>
    </nav>
  );
}
