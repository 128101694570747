import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CardForm from '../CardForm';
import { NewsCreateDataType } from '../../services/api/types/card';
import InputGroupTag from '../Form/components/InputGroupTag';
import {
  DescriptionValidate,
  TITLE_MAX_LENGTH,
  TitleValidate,
} from '../../utils/form/validate';
import style from './style.module.scss';
import UploadImageGroupTag from '../Form/components/UploadImageGroupTag';
import CKEditorGroupTag from '../Form/components/CKEditorGroupTag';
import { CardType } from '../../features/Card/types';

interface NewsCardFormProps {
  onSubmit: (data: NewsCreateDataType) => void;
  submitError: string;
  loading: boolean;
  card?: CardType;
}

export default function NewsCardForm(props: NewsCardFormProps) {
  const { t } = useTranslation();
  const { submitError, onSubmit, loading, card } = props;
  const methods = useFormContext<NewsCreateDataType>();
  const {
    register,
    setValue,
    errors,
    handleSubmit,
    setError,
    control,
  } = methods;

  return (
    <CardForm
      onSubmit={handleSubmit(onSubmit)}
      setValue={setValue}
      loading={loading}
      submitError={submitError}
      errors={errors}
      register={register}
    >
      <InputGroupTag
        id="title"
        name="title"
        labelText={t('components.cardPromotion.title')}
        maxLength={TITLE_MAX_LENGTH}
        error={errors.title}
        autoComplete={false}
        ref={register(TitleValidate)}
        defaultValue={card?.title}
      />

      <div className={style.uploadButtons}>
        <UploadImageGroupTag
          name="photoCover"
          title={t('components.cardNews.photoCover')}
          error={errors.photoCover}
          ref={register}
          setValue={setValue}
          setError={setError}
          type="news"
          defaultValue={card?.photoCover}
          placeholder={t('components.cardNews.photoCoverPlaceHolder')}
        />
      </div>

      <CKEditorGroupTag
        name="description"
        labelText={t('components.cardNews.content')}
        rules={DescriptionValidate}
        error={errors.description}
        control={control}
        imageUploadable
      />
    </CardForm>
  );
}
