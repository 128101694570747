import React from 'react';

export interface LinkType {
  href: string;
  linkLabel: string;
  blank: boolean;
  label?: string;
}

export default function Link(props: LinkType) {
  const { href, linkLabel, label, blank } = props;

  return (
    <div>
      {label && <div>{{ label }}</div>}
      <a
        href={href}
        rel="noopener noreferrer"
        target={blank ? '_blank' : undefined}
      >
        {linkLabel}
      </a>
    </div>
  );
}

Link.defaultProps = {
  blank: true,
};
