import React from 'react';
import classNames from 'classnames';
import style from './style.module.scss';
import { DEFAULT_FORM_DATA } from '../../../../../../../utils/card';
import { ASPECT_RATIO_TYPES } from '../../../../../../../components/Form/components/UploadImageGroupTag';

interface PropTypes {
  children: React.ReactNode;
  hasCoverImage?: boolean;
  coverImage?: string;
  klassName?: string;
  type?: 'merchant' | 'news' | 'event';
}

export default function CardContainer(props: PropTypes) {
  const { children, coverImage, hasCoverImage, klassName, type } = props;
  return (
    <div className={classNames(style.cardContainer, klassName)}>
      {hasCoverImage && (
        <div
          className={style.headerImage}
          style={{
            backgroundImage: coverImage
              ? `url(${coverImage})`
              : `url(${DEFAULT_FORM_DATA.placeholderImage})`,
            height: type ? 360 / ASPECT_RATIO_TYPES[type] : undefined,
          }}
        />
      )}
      {children}
    </div>
  );
}
