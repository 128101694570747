import React, { useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavBar from '../../components/NavBar';
import { useUserState } from '../User/reducers';
import { useCardsState } from '../Card/reducers';
import RouterPath from '../../constants/RouterPath';
import CardForm from '../Card/components/CreateCard/components/CardForm';
import { SelectableCardType } from '../Card/components/CreateCard/types';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';
import useBeforeUnload from '../../utils/hooks/useBeforeUnload';
import style from './style.module.css';
import { DialogContext } from '../../components/Dialog/provider';
import setPageTitle from '../../utils/pageTitle';

// Edit Card info screen
export default function CardEdit() {
  const { t } = useTranslation();
  const { id, merchantId } = useParams();
  const history = useHistory();
  const dialogContext = useContext(DialogContext);
  const [userState] = useUserState();
  const { user } = userState;
  const [cardsState, cardsActions] = useCardsState();

  function init() {
    cardsActions.fetchCard({
      cardId: id,
      merchantId,
    });
    cardsActions.fetchCategories();
    cardsActions.fetchEcoparkRegions();
    cardsActions.fetchMerchant({ id: merchantId });
  }

  function getCloseUrl(): string {
    if (cardsState.card) {
      return RouterPath.MERCHANT_SHOW.replace(':merchantId', merchantId);
    }
    return '';
  }

  function onClosePress() {
    dialogContext.current?.show({
      message: t('dialogs.exitCreateOrUpdateCardMessage'),
      type: 'warning',
      negativeTitle: t('actions.back'),
      positiveTitle: t('actions.exit'),
      onPositivePress: () => {
        history.push(getCloseUrl());
      },
    });
  }

  useEffect(init, []);

  useEffect(() => {
    setPageTitle(cardsState.card?.title);
  }, [cardsState.card]);

  useBeforeUnload(t('dialogs.exitCreateOrUpdateCardMessage'), true);

  return (
    <>
      <NavBar user={user} />
      {cardsState.isFetchingCard && (
        <div className={style.container}>
          <Loader />
        </div>
      )}
      {cardsState.card && (
        <CardForm
          onClosePress={onClosePress}
          card={cardsState.card}
          cardType={cardsState.card.type as SelectableCardType}
          merchantId={id}
          closeFormUrl={getCloseUrl()}
          isFetching={cardsState.isFetchingCard}
        />
      )}
      <Footer />
    </>
  );
}
