import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import { NewsCreateDataType } from '../../../services/api/types/card';
import { CardType } from '../../../features/Card/types';
import CardPreview from '../../../features/Card/components/CreateCard/components/CardPreview';
import CreateCardContainer from '../../../features/Card/components/CreateCard/components/CreateCardContainer';
import FormTitle from '../../../features/Card/components/CreateCard/components/FormTitle';
import { FormPageType } from '../../../features/Card/components/CreateCard/types';
import NewsCardForm from '../../NewsCardForm';

type PropsType = {
  onSubmit: (data: NewsCreateDataType) => void;
  submitError: string;
  card?: CardType;
  closeFormUrl?: string;
  currentPage?: FormPageType;
  goFirstPage?: () => void;
  onClosePress?: () => void;
  loading: boolean;
};

export default function CardNewsFormContainer(props: PropsType) {
  const {
    onSubmit,
    submitError,
    card,
    closeFormUrl,
    currentPage,
    goFirstPage,
    onClosePress,
    loading,
  } = props;
  const methods = useForm<NewsCreateDataType>({
    defaultValues: {
      title: card?.title,
      description: card?.description,
      categoryId: card?.categoryId,
      photoCover: card?.photoCover,
    },
  });

  return (
    <FormProvider {...methods}>
      <CreateCardContainer
        title={
          <FormTitle
            onClosePress={onClosePress}
            closeFormUrl={closeFormUrl}
            cardType="CardNews"
            currentPage={currentPage}
            goFirstPage={goFirstPage}
          />
        }
        form={
          <NewsCardForm
            loading={loading}
            onSubmit={onSubmit}
            submitError={submitError}
            card={card}
          />
        }
        example={<CardPreview cardType="CardNews" type="preview" />}
      />
    </FormProvider>
  );
}
