import moment from 'moment';
import i18n from '../i18n';
import { SelectableCardType } from '../features/Card/components/CreateCard/types';
import placeholderImage from '../assets/placeholder/image.png';
import { CategoryType } from '../services/api/types/category';
import { LanguageType } from '../features/User/types';

export function getCardTitle(type: SelectableCardType): string {
  return {
    CardEvent: i18n.t('features.createCard.selectCardType.event'),
    CardNews: i18n.t('features.createCard.selectCardType.news'),
    CardPromotion: i18n.t('features.createCard.selectCardType.promotion'),
    unselect: i18n.t('features.createCard.formTitle.newCard'),
  }[type];
}

export const DEFAULT_FORM_DATA = {
  title: 'components.cardPromotion.title',
  newsTitle: 'components.cardPreview.newsTitle',
  eventTitle: 'components.cardPreview.eventTitle',
  promotionTitle: 'components.cardPreview.promotionTitle',
  businessName: 'features.merchantUpdate.businessName',
  placeholderImage,
  category: 'components.cardEvent.category',
  description: {
    event: 'components.cardPreview.eventDescription',
    merchant: 'components.cardPreview.merchantDescription',
    news: 'components.cardPreview.newsDescription',
    promotion: 'components.cardPreview.promotionDescription',
  },
  address: 'features.merchantUpdate.streetAddress',
  eventAddress: 'components.cardEvent.streetAddress',
  fullDate: moment().format('HH:mm - DD/MM/yyyy'),
  time: moment().format('HH:mm'),
  streetNumber: 'features.merchantUpdate.streetNumber',
};

export function findCategories(
  categories: CategoryType[],
  categoryId: number,
  userLanguage: LanguageType,
) {
  const res = categories.find((category) => {
    return category.id === parseInt(categoryId.toString(), 10);
  });
  return res ? res.name[userLanguage] : DEFAULT_FORM_DATA.category;
}

export function getImageURL(image: string | File | undefined) {
  if (image && typeof image !== 'string') {
    return URL.createObjectURL(image);
  }
  return image;
}
