import React from 'react';
import style from './style.module.scss';

interface BadgePropTypes {
  title: string;
  color?: string;
  background: string;
}

export default function Badge(params: BadgePropTypes) {
  const { title, color, background } = params;
  return (
    <div className={style.badge} style={{ background, color }}>
      {title}
    </div>
  );
}
