import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PhoneNumberValidate } from '../../../SignIn/components/SignInForm/validate';
import Form from '../../../../components/Form';
import { ActivateUserParamsType } from '../../../../services/api/types/user';
import RouterPath from '../../../../constants/RouterPath';
import style from './style.module.scss';
import { activateUser } from '../../../User/actions';
import { useUserState } from '../../../User/reducers';
import AuthContainer from '../AuthContainer';
import Button from '../../../../components/Button';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import InputGroupTag from '../../../../components/Form/components/InputGroupTag';
import LoginButton from '../../../../components/LoginButton';
import setPageTitle from '../../../../utils/pageTitle';

export default function SignUpSocial() {
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm<ActivateUserParamsType>();
  const [isSubmitError, setSubmitError] = useState(false);
  const [userState, userActions] = useUserState();
  const history = useHistory();
  const windowSize = useWindowSize();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageTitle(t('features.signUpForm.title'));
  }, [t]);

  async function onSubmit(params: ActivateUserParamsType) {
    const { phoneNumber } = params;
    setLoading(true);
    const result = await userActions.activateUser(phoneNumber);
    setLoading(false);
    if (activateUser.fulfilled.match(result)) {
      history.push(RouterPath.OTP);
    } else {
      setSubmitError(true);
    }
  }

  return (
    <AuthContainer title={t('features.signUpForm.title')}>
      <div className={style.signUpForm}>
        <Form onSubmit={handleSubmit(onSubmit)} id="signUpForm">
          <InputGroupTag
            id="phoneNumber"
            type="text"
            name="phoneNumber"
            labelText={t('features.signUpForm.phoneNumber')}
            error={errors.phoneNumber}
            autoComplete={false}
            ref={register(PhoneNumberValidate)}
            useErrorMessage
          />

          {isSubmitError && (
            <span className={style.error}>{t(userState!.apiError!)}</span>
          )}

          {windowSize.width > 800 && (
            <div className={style.buttonContainer}>
              <Button
                type="submit"
                loading={loading}
                title={t('actions.next')}
              />
            </div>
          )}
          {windowSize.width <= 800 && (
            <div className={style.buttonContainer}>
              <LoginButton
                loading={loading}
                title={t('actions.next')}
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
                backgroundColor="white"
                color="#006064"
              />
            </div>
          )}
        </Form>
      </div>
    </AuthContainer>
  );
}
