import React, { useEffect, useState } from 'react';
import { MapPin, Clock } from 'react-feather';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';
import style from './style.module.scss';
import { useUserState } from '../../../../../../User/reducers';
import { useCardsState } from '../../../../../reducers';
import MobileButton from '../MobileButton';
import {
  DEFAULT_FORM_DATA,
  findCategories,
  getImageURL,
} from '../../../../../../../utils/card';
import {
  MOMENT_DATE_FORMATS,
  DATE_FORMATS,
} from '../../../../../../../utils/time';
import CardContainer from '../CardContainer';
import HtmlContent from '../HtmlContent';
import Tabs from '../../../../../../../components/Tabs';
import FacebookLogo from '../../../../../../../assets/svg/facebook_round.svg';
import InstagramLogo from '../../../../../../../assets/svg/instagram.svg';
import EmailButton from '../../../../../../../assets/png/email.png';
import { DAYS_OF_WEEK } from '../../../../../../../components/MerchantCardForm';

export default function CardMerchantPreview() {
  const [userState] = useUserState();
  const [cardsState] = useCardsState();
  const { categories } = cardsState;
  const { userLanguage } = userState;
  const formContext = useFormContext();
  let formData: any;
  if (formContext) {
    formData = formContext.watch();
  }
  const selectedCategory =
    formData &&
    formData.categoryId &&
    cardsState.categories?.find(
      (category) => formData.categoryId.toString() === category.id.toString(),
    );

  const { t } = useTranslation();

  const [coverImage, setCoverImage] = useState<string | undefined>(
    formData?.photoCover,
  );

  const [logo, setLogo] = useState<string | undefined>(formData?.photoCover);
  useEffect(() => {
    if (formData) {
      setCoverImage(getImageURL(formData.photoCover));
    }
    // eslint-disable-next-line
  }, [formData?.photoCover]);

  useEffect(() => {
    if (formData) {
      setLogo(getImageURL(formData.logo));
    }
    // eslint-disable-next-line
  }, [formData?.logo]);

  function onDirectionsClick() {
    if (formData && formData.longitude && formData.latitude) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${formData.latitude},${formData.longitude}`,
        '_blank',
      );
    }
  }

  function getOperationStatus() {
    const now = moment();
    const today =
      formData && formData.allSelected
        ? 'all'
        : now.format('ddd').toLowerCase();
    const todayOpeningTime = formData[`${today}OpeningTime`];
    const todayClosingTime = formData[`${today}ClosingTime`];
    if (
      formData &&
      formData[`${today}Selected`] &&
      todayOpeningTime &&
      todayClosingTime
    ) {
      if (
        moment().format(MOMENT_DATE_FORMATS.time) <
        moment(todayOpeningTime).format(MOMENT_DATE_FORMATS.time)
      ) {
        return (
          <div className={style.locationContainer}>
            <Clock
              className={cls(style.mapPinIcon, style.closedColor)}
              size={16}
            />
            <p>
              <b className={style.closedColor}>
                {t('components.cardMerchant.closed')}
              </b>
              {` • ${t('components.cardMerchant.open')} ${moment(
                todayOpeningTime,
              ).format('HH:mm')}`}
            </p>
          </div>
        );
      }
      if (
        moment().format(MOMENT_DATE_FORMATS.time) >=
          moment(todayOpeningTime).format(MOMENT_DATE_FORMATS.time) &&
        moment().format(MOMENT_DATE_FORMATS.time) <=
          moment(todayClosingTime).format(MOMENT_DATE_FORMATS.time)
      ) {
        return (
          <div className={style.locationContainer}>
            <Clock
              className={cls(style.mapPinIcon, style.openColor)}
              size={16}
            />
            <p>
              <b className={style.openColor}>
                {t('components.cardMerchant.open')}
              </b>
              {` • ${t('components.cardMerchant.closed')} ${moment(
                todayClosingTime,
              ).format(MOMENT_DATE_FORMATS.time)}`}
            </p>
          </div>
        );
      }
    }
    const indexOfToday = DAYS_OF_WEEK.indexOf(today);
    let i = (indexOfToday + 1) % DAYS_OF_WEEK.length;
    let j = 0;
    for (i; j <= 7; i = (i + 1) % DAYS_OF_WEEK.length) {
      if (DAYS_OF_WEEK[i] !== 'all') {
        j += 1;
      }
      if (formData && formData[`${DAYS_OF_WEEK[i]}Selected`]) {
        break;
      }
    }
    const nextOpeningDay = DAYS_OF_WEEK[i];
    if (
      formData &&
      formData[`${nextOpeningDay}Selected`] &&
      formData[`${nextOpeningDay}OpeningTime`] &&
      formData[`${nextOpeningDay}ClosingTime`]
    ) {
      if (nextOpeningDay === 'all') {
        now.add(1, 'days');
      } else {
        now.add(j, 'days');
      }
      const nextOpeningTime = formData[`${nextOpeningDay}OpeningTime`];
      now.set('hours', nextOpeningTime.getHours());
      now.set('minutes', nextOpeningTime.getMinutes());
      return (
        <div className={style.locationContainer}>
          <Clock
            className={cls(style.mapPinIcon, style.closedColor)}
            size={16}
          />
          <p>
            <b className={style.closedColor}>
              {t('components.cardMerchant.closed')}
            </b>
            {` • ${t('components.cardMerchant.open')} ${now.format(
              'HH:mm DD/MM',
            )}`}
          </p>
        </div>
      );
    }
    return (
      <div className={style.locationContainer}>
        <Clock className={cls(style.mapPinIcon, style.closedColor)} size={16} />
        <p>
          <b className={style.closedColor}>
            {t('components.cardMerchant.closed')}
          </b>
        </p>
      </div>
    );
  }

  const someOpeningDaysSelected = DAYS_OF_WEEK.some(
    (day) => formData[`${day}Selected`],
  );

  return (
    <CardContainer hasCoverImage coverImage={coverImage} type="merchant">
      <div className={style.headerContainer}>
        <div className={style.titleContainer}>
          <h2>
            {formData && formData.name && formData.name.length > 0
              ? formData.name
              : t(DEFAULT_FORM_DATA.businessName)}
          </h2>
          <div
            className={style.logo}
            style={{
              backgroundImage: logo
                ? `url(${logo})`
                : `url(${DEFAULT_FORM_DATA.placeholderImage})`,
            }}
          />
        </div>
        <div className={style.locationContainer}>
          <MapPin className={style.mapPinIcon} size={16} />
          <p>
            {`${
              formData &&
              formData.streetNumber &&
              formData.streetNumber.length > 0
                ? formData.streetNumber
                : t(DEFAULT_FORM_DATA.streetNumber)
            } ${
              formData && formData.street && formData.street.length > 0
                ? formData.street
                : t(DEFAULT_FORM_DATA.address)
            }`}
          </p>
        </div>
        {getOperationStatus()}
        <div className={style.badgeList}>
          {formData.subcategoryIds && formData.subcategoryIds.length > 0 ? (
            [...formData.subcategoryIds].sort().map((id: number) => {
              const subcategory = selectedCategory?.subcategories?.find(
                (item) => id.toString() === item.id.toString(),
              )?.name[userLanguage];
              return (
                <div className={style.badgeContainer}>
                  <div className={style.badge}>{subcategory}</div>
                </div>
              );
            })
          ) : (
            <div className={style.badgeContainer}>
              <div className={style.badge}>
                {formData && formData.categoryId && categories
                  ? findCategories(
                      categories,
                      formData.categoryId,
                      userLanguage,
                    )
                  : t(DEFAULT_FORM_DATA.category)}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={style.contentContainer}>
        <div className={style.grid}>
          <MobileButton
            color="#E2F8F4"
            onClick={() => window.open(`tel:${formData.phoneNumber}`)}
          >
            <div className={style.contactButton}>
              {t('features.merchantUpdate.call')}
            </div>
          </MobileButton>
          <MobileButton color="#E2F8F4" onClick={onDirectionsClick}>
            <div className={style.contactButton}>
              {t('features.merchantUpdate.directions')}
            </div>
          </MobileButton>
        </div>
        <Tabs
          tabs={[
            {
              label: t('features.merchantUpdate.about'),
              component: (
                <>
                  <HtmlContent
                    html={
                      formData &&
                      formData.description &&
                      formData.description.length > 0
                        ? formData.description
                        : `<i>${t(DEFAULT_FORM_DATA.description.merchant)}</i>`
                    }
                  />
                  {someOpeningDaysSelected && (
                    <>
                      <div className={style.sectionTitle}>
                        {t('components.cardMerchant.openingHours')}
                      </div>

                      <div className={style.sectionText}>
                        {DAYS_OF_WEEK.map((day) => {
                          if (formData && formData[`${day}Selected`]) {
                            const openingTime = moment(
                              formData[`${day}OpeningTime`],
                            ).format(DATE_FORMATS.time);
                            const closingTime = moment(
                              formData[`${day}ClosingTime`],
                            ).format(DATE_FORMATS.time);
                            return (
                              <div>
                                {`${t(
                                  `date.daysOfWeek.${day}`,
                                )}: ${openingTime} - ${closingTime}`}
                                <br />
                              </div>
                            );
                          }
                          return '';
                        })}
                      </div>
                    </>
                  )}
                  {formData &&
                    (formData.email ||
                      formData.facebookLink ||
                      formData.email) && (
                      <>
                        <div className={style.sectionTitle}>
                          {t('components.cardMerchant.contacts')}
                        </div>
                        <div className={style.sectionText}>
                          <div className={style.row}>
                            {formData.email && (
                              <img
                                src={EmailButton}
                                alt="email"
                                className={style.socialLogo}
                              />
                            )}

                            {formData.facebookLink && (
                              <img
                                src={FacebookLogo}
                                alt="facebook-logo"
                                className={style.socialLogo}
                              />
                            )}

                            {formData.instagramLink && (
                              <img
                                src={InstagramLogo}
                                alt="instagram-logo"
                                className={style.socialLogo}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                </>
              ),
            },
          ]}
        />
      </div>
    </CardContainer>
  );
}
