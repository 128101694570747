import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  areaColors,
  areaLabels,
  areaValues,
  ageLabels,
  ageValues,
  ageColors,
} from './utils';
import { UsersByAreaStats, UserByAgeStats } from '../../features/Card/types';
import PieChart from '../PieChart';
import style from './style.module.scss';

interface StatsProps {
  ageStats?: UserByAgeStats[];
  areaStats?: UsersByAreaStats[];
}

export default function AgeAndAreaPieCharts(props: StatsProps) {
  const { ageStats, areaStats } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={style.chart}>
        <div className={style.pieChart}>
          {ageStats && (
            <PieChart
              title={t('components.ageAndAreaPieChart.ageChartTitle')}
              labels={ageLabels(ageStats)}
              data={ageValues(ageStats)}
              backgroundColor={ageColors(ageStats)}
            />
          )}
        </div>
        <div className={style.pieChart}>
          {areaStats && (
            <PieChart
              title={t('components.ageAndAreaPieChart.areaChartTitle')}
              labels={areaLabels(areaStats)}
              data={areaValues(areaStats)}
              backgroundColor={areaColors(areaStats)}
            />
          )}
        </div>
      </div>
    </>
  );
}
