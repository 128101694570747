import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CouponSelection from './CouponSelection';
import CouponStats from '../../../../../components/CouponStats';
import { MerchantStats } from '../../../types';
import { useCardsState } from '../../../../Card/reducers';
import AgeAndAreaPieCharts from '../../../../../components/AgeAndAreaPieChart';

import style from '../style.module.scss';

type CouponId = '' | number;

interface PropsType {
  merchantId: string;
  merchantStats?: MerchantStats;
}

export default function AllCouponStats(props: PropsType) {
  const { t } = useTranslation();
  const [cardState, cardsActions] = useCardsState();
  const { merchantId, merchantStats } = props;
  const [couponId, setCouponId] = useState<CouponId>('');
  const isAllCouponsSelected = couponId === '';

  async function handleCouponIdChange(id: CouponId) {
    setCouponId(id);
    if (id !== '') {
      await cardsActions.getCardStats({ id, merchantId });
    }
  }

  const clickedStats = getClickedStats();
  const redeemedStats = getRedeemedStats();

  function getClickedStats() {
    if (!isAllCouponsSelected) return cardState.cardStats?.totalUniqUserClicked;
    if (
      !merchantStats?.totalCouponCards ||
      merchantStats?.totalCouponCards === 0 ||
      !merchantStats.totalUserClickedCoupons ||
      merchantStats.totalUserClickedCoupons === 0
    )
      return 0;
    return (
      merchantStats.totalUserClickedCoupons / merchantStats?.totalCouponCards
    );
  }

  function getRedeemedStats() {
    if (!isAllCouponsSelected)
      return cardState.cardStats?.totalPromotionRedemptions;
    if (
      !merchantStats?.totalCouponCards ||
      merchantStats?.totalCouponCards === 0 ||
      !merchantStats.totalPromotionRedemptions ||
      merchantStats.totalPromotionRedemptions === 0
    )
      return 0;
    return (
      merchantStats.totalPromotionRedemptions / merchantStats.totalCouponCards
    );
  }

  function getConversionRate() {
    if (
      clickedStats &&
      redeemedStats &&
      clickedStats !== 0 &&
      redeemedStats !== 0
    )
      return (redeemedStats / clickedStats) * 100;
    return 0;
  }

  function getRedeemedWithinMonth() {
    return isAllCouponsSelected
      ? merchantStats?.totalWithinMonth
      : cardState.cardStats?.totalWithinMonth;
  }

  return (
    <>
      <div className={style.chartTitle}>
        {t('features.merchantStats.allCouponsTitle')}
      </div>

      <CouponSelection
        listCoupons={merchantStats?.listCoupons}
        handleCouponIdChange={handleCouponIdChange}
        value={couponId}
      />

      <CouponStats
        isAllCouponsSelected={isAllCouponsSelected}
        clicked={clickedStats}
        totalRedeemed={redeemedStats}
        conversionRate={getConversionRate()}
        redeemedWithinMonth={getRedeemedWithinMonth()}
      />

      <div className={style.statsNote}>
        {isAllCouponsSelected
          ? t('features.merchantStats.allCouponsStatsNote')
          : t('features.merchantStats.couponStatsNote')}
      </div>

      <AgeAndAreaPieCharts
        ageStats={
          isAllCouponsSelected
            ? merchantStats?.redeemedByUserAgeSegments
            : cardState.cardStats?.redeemedByUserAgeSegments
        }
        areaStats={
          isAllCouponsSelected
            ? merchantStats?.redeemedByUserAreaSegments
            : cardState.cardStats?.redeemedByUserAreaSegments
        }
      />
    </>
  );
}
