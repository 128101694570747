import Api from './api';
import { CreateCardResponseType, FetchMerchantParamsType } from './types/card';
import { OauthTokenType } from '../../features/User/types';
import { AppDispatch } from '../../store';
import { CardStats, CardType, MerchantType } from '../../features/Card/types';
import { MerchantUpdateParamsType } from './types/merchant';
import { ApiResType } from './types/api';
import {
  GetMerchantCardsParams,
  GetMerchantStatsParams,
} from './types/requestParams';
import { GetCardStatsResponse } from './types/requestResponse';
import { DAYS_OF_WEEK } from '../../components/MerchantCardForm';

export default class MerchantCardApi extends Api {
  path: string;

  constructor(dispatch: AppDispatch, userToken?: OauthTokenType) {
    super(dispatch, userToken);
    this.path = '/cms/merchants';
  }

  async getMerchantCards(
    params: GetMerchantCardsParams,
  ): Promise<ApiResType<MerchantType[]>> {
    const response = await this.request<MerchantType[]>({
      path: this.path,
      method: 'GET',
      params,
    });
    return response;
  }

  async updateMerchant(
    params: MerchantUpdateParamsType,
  ): Promise<CreateCardResponseType> {
    const body = this.merchantFormDataConverter(params);
    const response = await this.request<CardType>({
      path: `${this.path}/${params.id}`,
      method: 'PATCH',
      body,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }

  async fetchMerchant(
    params: FetchMerchantParamsType,
  ): Promise<ApiResType<MerchantType>> {
    const res = await this.request<MerchantType>({
      path: `${this.path}/${params.merchantId}`,
      method: 'GET',
    });
    return res;
  }

  async createMerchant(
    params: MerchantUpdateParamsType,
  ): Promise<CreateCardResponseType> {
    const body = this.merchantFormDataConverter(params);
    const response = await this.request<CardType>({
      path: `${this.path}`,
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }

  async fetchMerchantStats(
    params: GetMerchantStatsParams,
  ): Promise<GetCardStatsResponse> {
    const response = await this.request<CardStats>({
      path: `/cms/merchants/${params.merchantId}/stats`,
      method: 'GET',
    });
    return response;
  }

  private merchantFormDataConverter(
    params: MerchantUpdateParamsType,
  ): FormData {
    const body = this.toFormData(params);
    if (params.facebookLink) {
      body.set('social[facebook]', params.facebookLink);
    }
    if (params.instagramLink) {
      body.set('social[instagram]', params.instagramLink);
    }
    DAYS_OF_WEEK.forEach((day) => {
      if (!params[`${day}Selected` as keyof MerchantUpdateParamsType]) {
        if (params[`${day}OpeningTime` as keyof MerchantUpdateParamsType])
          body.delete(`${day}_opening_time`);
        if (params[`${day}ClosingTime` as keyof MerchantUpdateParamsType])
          body.delete(`${day}_closing_time`);
      }
    });
    return body;
  }
}
