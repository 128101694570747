import { MAX_FILE_SIZE_KB } from './form/validate';

const Compress = require('compress.js');

export default async function resizeImageFile(file: File): Promise<File> {
  if (file && file.size > MAX_FILE_SIZE_KB) {
    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: MAX_FILE_SIZE_KB / 1024, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    return Compress.convertBase64ToFile(base64str, imgExt);
  }
  return file;
}
