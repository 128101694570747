import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ReactComponent as Onboarding } from '../../assets/svg/onboarding.svg';
import CardList from '../../components/CardList';
import { useCardsState } from '../Card/reducers';
import { useUserState } from '../User/reducers';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { PER_PAGE } from '../../services/api/types/api';
import Button from '../../components/Button';
import RouterPath from '../../constants/RouterPath';
import useWindowSize from '../../utils/hooks/useWindowSize';
import LoginButton from '../../components/LoginButton';
import CTA from './CTA';
import Loader from '../../components/Loader';
import style from './style.module.scss';
import setPageTitle from '../../utils/pageTitle';

export default function MerchantCardList() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [cardsState, cardsActions] = useCardsState();
  const { merchants, totalMerchants } = cardsState;
  const [userState, userActions] = useUserState();
  const { user } = userState;
  const [dialogOpen, setDialogOpen] = useState<boolean>(
    !user?.reachedOnboardingScreen,
  );
  const windowSize = useWindowSize();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function onDialogClose() {
    setDialogOpen(false);
    if (userState.user) {
      await userActions.updateUserInfo({
        userId: userState.user!.id,
        reachedOnboardingScreen: true,
      });
    }
  }

  const onPageChange = async (currentCardPage: number) => {
    setIsLoading(true);
    const params = {
      merchantId: id,
      page: currentCardPage,
      perPage: PER_PAGE,
    };
    await cardsActions.getMerchantCards(params);
    setIsLoading(false);
    setCurrentPage(currentCardPage);
  };

  useEffect(() => {
    onPageChange(currentPage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageTitle(t('features.merchantCardList.title'));
  }, [t]);

  if (isLoading) return <Loader />;

  return (
    <>
      <Dialog
        open={dialogOpen}
        PaperProps={{ id: style.modalContainer }}
        BackdropProps={{ id: style.backdrop }}
      >
        <div className={style.svgContainer}>
          <Onboarding width={windowSize.width > 800 ? undefined : 150} />
        </div>
        <div className={style.dialogContainer}>
          <div className={style.dialog}>
            <div className={style.dialogTitleContainer}>
              <div
                className={style.dialogCloseButton}
                onClick={onDialogClose}
                aria-hidden
              >
                <Close color="inherit" />
              </div>
              <div className={style.dialogTitle}>
                <h2>{t('components.onboarding.title')}</h2>
              </div>
              <div className={style.dialogTitleFiller} />
            </div>
            <div
              className={style.dialogContent}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('components.onboarding.content'),
              }}
            />
            <div className={style.dialogButtonContainer}>
              <LoginButton
                backgroundColor="#006064"
                title={t('components.onboarding.downloadButton')}
                onClick={() => window.open('/econnect_guideline.pdf', '_blank')}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <NavBar user={user} />

      <div className={style.section}>
        {totalMerchants > 0 ? (
          <>
            <div className={style.header}>
              <h1 className={style.title}>
                {t('features.merchantCardList.title')}
              </h1>
              <div className={style.buttonContainer}>
                <Button
                  title={t('features.createMerchant.createNewBusiness')}
                  outlined
                  onClick={() => history.push(RouterPath.CREATE_MERCHANT)}
                />
              </div>
            </div>

            <CardList
              merchants={merchants}
              onPageChange={onPageChange}
              total={totalMerchants}
              noCardMessage={t('components.cardList.noBusiness')}
              currentPage={currentPage}
            />
          </>
        ) : (
          <CTA
            onButtonClick={() => history.push(RouterPath.CREATE_MERCHANT)}
            buttonTitle={t('features.createMerchant.createNewBusiness')}
          />
        )}
      </div>
      <Footer />
    </>
  );
}
