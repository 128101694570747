import Api from './api';
import { OauthTokenType } from '../../features/User/types';
import { AppDispatch } from '../../store';
import { CategoryType, FetchCategoriesResponseType } from './types/category';

export default class CategoryApi extends Api {
  path: string;

  constructor(dispatch: AppDispatch, userToken?: OauthTokenType) {
    super(dispatch, userToken);
    this.path = 'cms/categories';
  }

  async categories(): Promise<FetchCategoriesResponseType> {
    const res = await this.request<CategoryType[]>({
      path: this.path,
      method: 'GET',
    });
    return res;
  }
}
