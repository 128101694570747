import React, { ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SwipeableDrawer } from '@material-ui/core';
import Logo from '../Logo';
import { UserType } from '../../features/User/types';
import Trigger from '../Trigger';
import { MenuPropTypes } from '../Menu';
import Button from '../Button';
import style from './style.module.scss';
import RouterPath from '../../constants/RouterPath';
import Link from '../Link';
import LanguageSwitch from '../LanguageSwitch';

interface NavBarPropsType {
  children?: ReactNode;
  user: UserType | undefined;
}

export default function NavBar(params: NavBarPropsType) {
  const [menuSelected, setMenuSelected] = useState(false);
  const { t } = useTranslation();
  const { user, children } = params;
  const history = useHistory();
  const toggleMenu = () => {
    setMenuSelected(!menuSelected);
  };

  function generateActionOptions(): MenuPropTypes[] {
    return [
      {
        title: t('components.navBar.signOut'),
        onClick: signOut,
        klassName: style.signOutOption,
      },
    ];
  }

  const username = `${user?.firstName} ${user?.lastName}`;

  function signOut() {
    history.push(RouterPath.SIGNUP);
  }

  return (
    <nav className={style.menuNav}>
      <ul>
        <div className={style.logo}>
          <Logo type="dark" />
        </div>

        {/* Desktop */}
        <div className={style.needHelpDesktop}>
          <Link
            href="/econnect_guideline.pdf"
            linkLabel={t('components.navBar.needHelp')}
            blank
          />
        </div>
        <Trigger options={generateActionOptions()}>
          <div className={style.userProfile}>
            <li>{user?.avatar && <img src={user?.avatar} alt="" />}</li>
            <li>{username}</li>
          </div>
        </Trigger>

        <div className={style.menuLanguages}>
          <LanguageSwitch />
        </div>

        {/* Mobile */}
        <div className={style.drawer}>
          <FontAwesomeIcon
            className={style.menuButton}
            icon={faBars}
            onClick={toggleMenu}
          />
          <SwipeableDrawer
            anchor="right"
            open={menuSelected}
            onClose={toggleMenu}
            onOpen={toggleMenu}
          >
            <div className={style.menuItems}>
              <div className={style.userInfo}>
                <li>{username}</li>
                {user?.avatar && <img src={user?.avatar} alt="" />}
              </div>
              <div className={style.createMerchant}>
                <Button
                  outlined
                  title={t('features.createMerchant.createNewBusiness')}
                  onClick={() => history.push(RouterPath.CREATE_MERCHANT)}
                />
              </div>
              {children && <div className={style.userActions}>{children}</div>}
              <div className={style.signOut}>
                <Button
                  outlined
                  title={t('components.navBar.signOut')}
                  onClick={signOut}
                />
              </div>
              <div className={style.needHelp}>
                <Link
                  href="/econnect_guideline.pdf"
                  linkLabel={t('components.navBar.needHelp')}
                  blank
                />
              </div>
            </div>
          </SwipeableDrawer>
        </div>
      </ul>
    </nav>
  );
}
