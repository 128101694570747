import React from 'react';
import { SVGCardPropTypes } from '../../../features/Card/components/CreateCard/components/CardTypeSelection';

export default function SvgNewsCard(props: SVGCardPropTypes) {
  const { backgroundColor, primaryColor, secondaryColor } = props;
  return (
    <svg width={169} height={214} fill="none">
      <rect
        x={0.5}
        y={0.5}
        width={168}
        height={213}
        rx={11.5}
        fill={backgroundColor}
        stroke={secondaryColor}
      />
      <path stroke={primaryColor} strokeWidth={8} d="M13 82h107" />
      <path
        stroke={secondaryColor}
        strokeWidth={8}
        strokeLinecap="round"
        d="M17 66h19"
      />
      <path stroke={secondaryColor} strokeWidth={8} d="M34 100h45" />
      <path
        stroke={primaryColor}
        strokeWidth={4}
        d="M13 118h146M13 151h146M13 129h146M13 162h146M13 140h146M13 173h146"
      />
      <circle
        cx={20}
        cy={100}
        r={6.5}
        fill={secondaryColor}
        stroke={secondaryColor}
      />
      <path
        d="M.5 12C.5 5.649 5.649.5 12 .5h145c6.351 0 11.5 5.149 11.5 11.5v38.5H.5V12z"
        fill={secondaryColor}
        stroke={secondaryColor}
      />
      <path
        d="M89.5 29.5v-7a1 1 0 00-1-1h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1zm-6.25-2.75l1.25 1.503L86.25 26l2.25 3h-7l1.75-2.25z"
        fill={backgroundColor}
      />
      <path
        d="M79 20v-1h-1v1h1zm12 0h1v-1h-1v1zm0 12v1h1v-1h-1zm-12 0h-1v1h1v-1zm0-11h12v-2H79v2zm11-1v12h2V20h-2zm1 11H79v2h12v-2zm-11 1V20h-2v12h2z"
        fill={secondaryColor}
      />
    </svg>
  );
}
