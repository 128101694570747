import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthContainer from '../AuthContainer';
import Form from '../../../../components/Form';
import InputGroupTag from '../../../../components/Form/components/InputGroupTag';
import CheckboxGroupTag from '../../../../components/Form/components/CheckboxGroupTag';
import Button from '../../../../components/Button';
import { SignUpParamsType } from '../../../../services/api/types/user';
import {
  PasswordValidate,
  PhoneNumberValidate,
} from '../../../SignIn/components/SignInForm/validate';
import { EmailValidator, NameValidator } from './validate';
import { RequiredCheckedValidate } from '../../../../utils/form/validate';
import { signUp } from '../../../User/actions';
import RouterPath from '../../../../constants/RouterPath';
import { useUserState } from '../../../User/reducers';
import style from './style.module.scss';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import LoginButton from '../../../../components/LoginButton';
import setPageTitle from '../../../../utils/pageTitle';

export default function SignUpPhone() {
  const { register, control, errors, handleSubmit, watch } = useForm<
    SignUpParamsType
  >();
  const { t } = useTranslation();
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userState, userActions] = useUserState();
  const history = useHistory();
  const windowSize = useWindowSize();

  useEffect(() => {
    setPageTitle(t('features.signUpForm.title'));
  }, [t]);

  async function onSubmit(data: SignUpParamsType) {
    setLoading(true);
    const result = await userActions.signUp(data);
    setLoading(false);
    if (signUp.fulfilled.match(result)) {
      history.push(RouterPath.OTP);
    } else {
      setSubmitError(true);
    }
  }

  const PasswordConfirmationValidator = {
    validate: (value: string) =>
      value === watch('password') || t('errors.passwordConfirmation'),
    required: t('errors.required'),
  };

  return (
    <AuthContainer title={t('features.signUpForm.title')} backButton>
      <div className={style.form}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.nameRow}>
            <InputGroupTag
              id="lastName"
              name="lastName"
              labelText={`${t('features.signUpForm.lastName')} *`}
              error={errors.lastName}
              ref={register(NameValidator)}
            />
            <InputGroupTag
              id="firstName"
              name="firstName"
              labelText={`${t('features.signUpForm.firstName')} *`}
              error={errors.firstName}
              ref={register(NameValidator)}
            />
          </div>
          <InputGroupTag
            id="phoneNumber"
            name="phoneNumber"
            labelText={`${t('features.signUpForm.phoneNumber')} *`}
            error={errors.phoneNumber}
            ref={register(PhoneNumberValidate)}
          />
          <InputGroupTag
            id="email"
            name="email"
            labelText={`${t('features.signUpForm.email')}`}
            error={errors.email}
            ref={register(EmailValidator)}
          />
          <InputGroupTag
            id="password"
            name="password"
            labelText={`${t('features.signUpForm.password')} *`}
            error={errors.password}
            ref={register(PasswordValidate)}
            type="password"
          />
          <InputGroupTag
            id="passwordConfirmation"
            name="passwordConfirmation"
            labelText={`${t('features.signUpForm.passwordConfirmation')} *`}
            error={errors.passwordConfirmation}
            type="password"
            // @ts-ignore
            ref={register(PasswordConfirmationValidator)}
          />
          <CheckboxGroupTag
            id="termOfServices"
            name="termOfServices"
            control={control}
            rules={RequiredCheckedValidate}
            defaultValue={false}
            error={errors.termOfServices}
            link={{
              href: '/econnect_terms_and_conditions.pdf',
              linkLabel: t('features.createMerchant.agreeTermDescription'),
              blank: true,
            }}
          />
          {submitError && (
            <span className={style.error}>{t(userState!.apiError!)}</span>
          )}
          <div className={style.buttonContainer}>
            {windowSize.width > 800 ? (
              <Button
                loading={loading}
                type="submit"
                title={t('actions.next')}
              />
            ) : (
              <LoginButton
                loading={loading}
                backgroundColor="white"
                color="#006064"
                title={t('actions.next')}
                onClick={() => handleSubmit(onSubmit)()}
              />
            )}
          </div>
        </Form>
      </div>
    </AuthContainer>
  );
}
