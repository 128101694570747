import { createAsyncThunk } from '@reduxjs/toolkit';
import { SignInPayloadType } from '../types';
import UserApi from '../../../services/api/user';
import {
  SignInParamsType,
  SignInWithThirdPartiesParamsType,
  SignUpParamsType,
} from '../../../services/api/types/user';
import { RootState } from '../../../store';
import { UpdateUserInfoParams } from '../../../services/api/types/requestParams';

export const signIn = createAsyncThunk(
  'user/requestSignIn',
  async (params: SignInParamsType, { dispatch, rejectWithValue }) => {
    const userApi = new UserApi(dispatch);
    const res = await userApi.signIn(params);

    if (res.status === 'success') {
      return Promise.resolve({
        user: res.result.data.user,
        token: res.result.data.token,
      } as SignInPayloadType);
    }
    return rejectWithValue(res.errors);
  },
);

export const signInWithThirdParties = createAsyncThunk(
  'user/signInWithThirdParties',
  async (
    params: SignInWithThirdPartiesParamsType,
    { dispatch, rejectWithValue },
  ) => {
    const userApi = new UserApi(dispatch);
    const res = await userApi.signInWithThirdParties(params);

    if (res.status === 'success') {
      return Promise.resolve({
        user: res.result.data,
        token: res.result.data.token,
      } as SignInPayloadType);
    }
    return rejectWithValue(res.errors);
  },
);

export const activateUser = createAsyncThunk(
  'user/activateUser',
  async (phoneNumber: string, { dispatch, getState, rejectWithValue }) => {
    const userState = (getState() as RootState).user;
    const { user, userToken } = userState;
    const userApi = new UserApi(dispatch, userToken);
    const res = await userApi.activateUser({
      phoneNumber,
      userId: user!.id,
    });
    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return rejectWithValue(res.errors);
  },
);

export const verifyOTP = createAsyncThunk(
  'user/verifyOTP',
  async (OTP: string, { dispatch, getState, rejectWithValue }) => {
    const userState = (getState() as RootState).user;
    const { user, userToken } = userState;
    const userApi = new UserApi(dispatch, userToken);
    const res = await userApi.verifyOTP({
      OTP,
      userId: user!.id,
    });
    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return rejectWithValue(res.errors);
  },
);

export const signUp = createAsyncThunk(
  'user/signUp',
  async (params: SignUpParamsType, { dispatch, rejectWithValue }) => {
    const userApi = new UserApi(dispatch);
    const res = await userApi.signUp(params);

    if (res.status === 'success') {
      return Promise.resolve({
        user: res.result.data,
        token: res.result.data.token,
      } as SignInPayloadType);
    }
    return rejectWithValue(res.errors);
  },
);

export const requestForgotPassword = createAsyncThunk(
  'user/requestForgotPassword',
  async (phoneNumber: string, { dispatch, rejectWithValue }) => {
    const userApi = new UserApi(dispatch);
    const res = await userApi.requestForgotPassword(phoneNumber);

    if (res.status === 'success') {
      return Promise.resolve({ ...res.result.data.user, phoneNumber });
    }
    return rejectWithValue(res.errors);
  },
);

export const signInOtp = createAsyncThunk(
  'user/signInOtp',
  async (otp: string, { dispatch, getState, rejectWithValue }) => {
    const userState = (getState() as RootState).user;
    const { user } = userState;
    const userApi = new UserApi(dispatch);
    const res = await userApi.signInOtp({ otp, userId: user!.id });

    if (res.status === 'success') {
      return Promise.resolve({
        user: res.result.data,
        token: res.result.data.token,
      } as SignInPayloadType);
    }
    return rejectWithValue(res.errors);
  },
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (password: string, { dispatch, getState, rejectWithValue }) => {
    const userState = (getState() as RootState).user;
    const { user } = userState;
    const userApi = new UserApi(dispatch);
    const res = await userApi.resetPassword({
      password,
      passwordConfirmation: password,
      passwordResetToken: user!.resetPasswordToken!,
    });

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return rejectWithValue(res.errors);
  },
);

export const updateUserInfo = createAsyncThunk(
  'user/updateUserInfo',
  async (
    params: UpdateUserInfoParams,
    { dispatch, getState, rejectWithValue },
  ) => {
    const userState = (getState() as RootState).user;
    const { userToken } = userState;
    const userApi = new UserApi(dispatch, userToken);
    const res = await userApi.updateUserInfo(params);

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return rejectWithValue(res.errors);
  },
);
