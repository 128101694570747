import React, { useContext, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavBar from '../../../../components/NavBar';
import { useUserState } from '../../../User/reducers';
import { FormPageType, SelectableCardType } from './types';
import RouterPath from '../../../../constants/RouterPath';
import FormTitle from './components/FormTitle';
import SelectCardType from './components/SelectCardType';
import CardForm from './components/CardForm';
import style from './style.module.scss';
import CardPreview from './components/CardPreview';
import { useCardsState } from '../../reducers';
import CreateCardContainer from './components/CreateCardContainer';
import useBeforeUnload from '../../../../utils/hooks/useBeforeUnload';
import Footer from '../../../../components/Footer';
import { DialogContext } from '../../../../components/Dialog/provider';
import setPageTitle from '../../../../utils/pageTitle';

export default function CreateCard() {
  const [userState] = useUserState();
  const { merchantId } = useParams();
  const { t } = useTranslation();
  const [, cardsActions] = useCardsState();
  const history = useHistory();
  const { user } = userState;
  const dialogContext = useContext(DialogContext);
  const closeFormUrl = RouterPath.MERCHANT_SHOW.replace(
    ':merchantId',
    merchantId,
  );
  const [currentPage, changeCurrentPage] = useState<FormPageType>(1);
  const [cardType, setCardType] = useState<SelectableCardType>('unselect');

  function showConfirmExitDialog(onExitPress: () => void) {
    dialogContext.current?.show({
      message: t('dialogs.exitCreateOrUpdateCardMessage'),
      type: 'warning',
      negativeTitle: t('actions.back'),
      positiveTitle: t('actions.exit'),
      onPositivePress: onExitPress,
    });
  }

  function onClosePress() {
    showConfirmExitDialog(() => {
      history.push(closeFormUrl);
    });
  }

  function goFirstPage() {
    showConfirmExitDialog(() => {
      changeCurrentPage(1);
    });
  }

  function goSecondPage() {
    changeCurrentPage(2);
  }

  useBeforeUnload(
    t('dialogs.exitCreateOrUpdateCardMessage'),
    currentPage === 2,
  );

  function init() {
    cardsActions.setCard(undefined);
    cardsActions.fetchMerchant({ id: merchantId });
    cardsActions.fetchCategories();
    cardsActions.fetchEcoparkRegions();
  }

  useEffect(init, []);

  useEffect(() => {
    setPageTitle(t('features.createCard.formTitle.newCard'));
  }, [t]);

  return (
    <>
      <NavBar user={user} />
      {currentPage === 1 && (
        <CreateCardContainer
          title={
            <FormTitle
              cardType={cardType}
              closeFormUrl={closeFormUrl}
              currentPage={currentPage}
              goFirstPage={goFirstPage}
            />
          }
          form={
            <div className={style.titleContainer}>
              <div className={style.typeOfContent}>
                {t('features.createCard.selectCardType.typeOfContent')}
              </div>
              <SelectCardType
                cardType={cardType}
                setCardType={setCardType}
                goSecondPage={goSecondPage}
              />
            </div>
          }
          example={<CardPreview cardType={cardType} type="illustration" />}
        />
      )}

      {currentPage === 2 && (
        <CardForm
          cardType={cardType}
          merchantId={parseInt(merchantId, 10)}
          closeFormUrl={closeFormUrl}
          goFirstPage={goFirstPage}
          currentPage={currentPage}
          onClosePress={onClosePress}
        />
      )}
      <Footer />
    </>
  );
}
