import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { InfoRounded, LoyaltyOutlined } from '@material-ui/icons';
import style from '../style.module.scss';

interface PropsType {
  type: 'normal' | 'conversionRate' | 'topRedemption';
  number: number;
  details: string;
}

export default function StatsGroup(props: PropsType) {
  const { t } = useTranslation();
  const { type, number, details } = props;
  const roundedNumber = Number.isInteger(number) ? number : number.toFixed(2);
  const numberText =
    type === 'conversionRate' ? `${roundedNumber}%` : roundedNumber;
  const containerClass =
    type === 'topRedemption' ? style.topRedemptionContainer : style.container;

  function renderTooltipTitle(text: string) {
    return <span className={style.tooltipTitle}>{text}</span>;
  }

  return (
    <div className={containerClass}>
      <span className={style.number}>
        <span className={style.numberValue}>{numberText}</span>

        {type === 'conversionRate' && (
          <Tooltip
            title={renderTooltipTitle(
              t('components.couponStats.conversionRateNote'),
            )}
            arrow
            placement="top-start"
          >
            <InfoRounded className={style.infoIcon} />
          </Tooltip>
        )}

        {type === 'topRedemption' && (
          <Tooltip
            title={renderTooltipTitle(
              t('components.couponStats.topRedemptionNote'),
            )}
            arrow
            placement="top-start"
          >
            <LoyaltyOutlined className={style.loyaltyIcon} fontSize="large" />
          </Tooltip>
        )}
      </span>
      <span className={style.details}>{details}</span>
    </div>
  );
}
