import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import style from './style.module.scss';
import InputGroupTag from '../../../../components/Form/components/InputGroupTag';
import {
  EmailValidate,
  RequiredValidate,
  TitleValidate,
} from '../../../../utils/form/validate';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import { MerchantUpdateParamsType } from '../../../../services/api/types/merchant';
import { MerchantPhoneNumberValidate } from '../../../SignIn/components/SignInForm/validate';
import DropdownGroupTag from '../../../../components/Form/components/DropdownGroupTag';
import { Multilanguage } from '../../../../services/api/types/api';
import { useUserState } from '../../../User/reducers';

interface LegalInformationFormProps {
  onSubmit: (data: MerchantUpdateParamsType) => void;
  params?: MerchantUpdateParamsType;
}

export default function LegalInformationForm(props: LegalInformationFormProps) {
  const [userState] = useUserState();
  const { onSubmit, params } = props;
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm<
    MerchantUpdateParamsType
  >();

  return (
    <div className={style.container}>
      <div className={style.description}>
        {t('features.createMerchant.legalDescription')}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputGroupTag
          id="business-name"
          name="businessName"
          labelText={t('features.createMerchant.legalName')}
          placeholder={t('features.createMerchant.placeholders.legalName')}
          error={errors.businessName}
          autoComplete={false}
          ref={register(TitleValidate)}
          defaultValue={params?.businessName}
        />

        <InputGroupTag
          id="business-phone-number"
          name="businessPhoneNumber"
          labelText={t('features.createMerchant.businessPhoneNumber')}
          error={errors.businessPhoneNumber}
          autoComplete={false}
          ref={register(MerchantPhoneNumberValidate)}
          defaultValue={params?.businessPhoneNumber}
        />

        <InputGroupTag
          id="business-email"
          name="businessEmail"
          labelText={t('features.createMerchant.businessEmail')}
          error={errors.businessEmail}
          autoComplete={false}
          ref={register(EmailValidate)}
          defaultValue={params?.businessEmail}
        />

        <InputGroupTag
          id="tax-id"
          name="taxId"
          labelText={t('features.createMerchant.taxId')}
          autoComplete={false}
          ref={register}
          error={errors.taxId}
          defaultValue={params?.taxId}
        />

        <DropdownGroupTag
          id="creator_relation"
          name="creatorRelation"
          ref={register(RequiredValidate)}
          title={t('features.createMerchant.creatorRelation')}
          data={CREATOR_RELATIONS}
          renderItem={(item) => (
            <option key={item.type} value={item.type}>
              {item.name[userState.userLanguage]}
            </option>
          )}
        />

        <div>
          <Button
            title={t('actions.next')}
            klassName={style.submitButton}
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
}

export interface CreatorRelationType {
  type: 'owner' | 'store_manager' | 'marketing_manager' | 'sales' | 'employee';
  name: Multilanguage;
}

const CREATOR_RELATIONS: CreatorRelationType[] = [
  {
    type: 'owner',
    name: {
      en: 'Owner',
      vn: 'Chủ cửa hàng',
    },
  },
  {
    type: 'store_manager',
    name: {
      en: 'Store Manager',
      vn: 'Quản lý cửa hàng',
    },
  },
  {
    type: 'marketing_manager',
    name: {
      en: 'Marketing Manager',
      vn: 'Nhân viên Marketing',
    },
  },
  {
    type: 'sales',
    name: {
      en: 'Sales',
      vn: 'Nhân viên phát triển kinh doanh',
    },
  },
  {
    type: 'employee',
    name: {
      en: 'Employee',
      vn: 'Nhân viên',
    },
  },
];
