import i18n from '../../../../i18n';

export const PhoneNumberValidate = {
  required: i18n.t('errors.required'),
  pattern: {
    value: /(^84|^0)[0-9]{9}$/i,
    message: i18n.t('errors.phoneFormat'),
  },
};

export const MerchantPhoneNumberValidate = {
  required: i18n.t('errors.required'),
  pattern: {
    value: /(^84|^0)[0-9]{9,12}$/i,
    message: i18n.t('errors.phoneFormat'),
  },
};

export const PasswordValidate = {
  required: i18n.t('errors.required'),
  validate: (value: string) => {
    if (value.length < 6) {
      return i18n.t('errors.minLength', { length: 6 });
    }
    if (value.length > 128) {
      return i18n.t('errors.maxLength', { length: 128 });
    }
    return true;
  },
};
