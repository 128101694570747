import i18n from '../../i18n';

// TODO: remove the comment below when there are more functions
// eslint-disable-next-line import/prefer-default-export
export const OTPValidator = {
  required: i18n.t('errors.required'),
  maxLength: {
    value: 6,
    message: i18n.t('errors.OTP'),
  },
};
