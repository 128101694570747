import Api from './api';
import { OauthTokenType } from '../../features/User/types';
import { AppDispatch } from '../../store';
import {
  FetchEcoparkRegionsResponseType,
  RegionType,
} from './types/ecoparkRegion';

export default class EcoparkRegionApi extends Api {
  path: string;

  constructor(dispatch: AppDispatch, userToken?: OauthTokenType) {
    super(dispatch, userToken);
    this.path = 'ecopark_regions';
  }

  async fetchRegions(): Promise<FetchEcoparkRegionsResponseType> {
    const res = await this.request<RegionType[]>({
      path: `${this.path}?filter=true`,
      method: 'GET',
      params: {
        filter: true,
      },
    });
    return res;
  }
}
