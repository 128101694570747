import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PayloadAction } from '@reduxjs/toolkit';
import { AddEditorParamsType } from '../../../../services/api/types/requestParams';
import Form from '../../../../components/Form';
import InputGroupTag from '../../../../components/Form/components/InputGroupTag';
import { MerchantPhoneNumberValidate } from '../../../SignIn/components/SignInForm/validate';
import Button from '../../../../components/Button';
import { useMerchantState } from '../../../Merchant/reducers';
import {
  addMerchantEditor,
  validateNewMerchantEditor,
} from '../../../Merchant/actions';
import { DialogContext } from '../../../../components/Dialog/provider';
import style from './style.module.scss';
import { ValidateNewEditorResponseType } from '../../../../services/api/types/requestResponse';
import { ApiSuccessType } from '../../../../services/api/types/api';

interface FormProps {
  reloadEditors: () => void;
}

interface ValidateResponseType {
  type: string;
  payload: PayloadAction<ApiSuccessType<ValidateNewEditorResponseType>>;
}

export default function AddEditorForm(props: FormProps) {
  const { t } = useTranslation();
  const { merchantId } = useParams();
  const { register, errors, handleSubmit } = useForm<AddEditorParamsType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [, merchantActions] = useMerchantState();
  const dialogContext = useContext(DialogContext);
  const { reloadEditors } = props;
  const [submitError, setSubmitError] = useState<any>(undefined);

  async function onSubmit(data: AddEditorParamsType) {
    setLoading(true);

    const params: AddEditorParamsType = { ...data, merchantId };
    const validateResponse = await merchantActions.validateNewMerchantEditor(
      params,
    );

    if (validateNewMerchantEditor.fulfilled.match(validateResponse)) {
      const newUser = validateResponse.payload.result.data;
      const userInfo = `${newUser.firstName} ${newUser.lastName} (${data.phoneNumber})`;
      dialogContext.current?.show({
        message: t('features.merchantEditor.inviteConfirm', {
          userInfo,
          businessName: newUser.merchantName,
        }),
        type: 'default',
        positiveTitle: t('actions.accept'),
        negativeTitle: t('actions.back'),
        onPositivePress: async () => {
          setLoading(true);

          const result = await merchantActions.addMerchantEditor(params);
          if (addMerchantEditor.fulfilled.match(result)) reloadEditors();
          else {
            setLoading(false);
          }
        },
        onNegativePress: () => setLoading(false),
      });
    } else {
      setLoading(false);
      setSubmitError(validateResponse.payload);
      setTimeout(() => setSubmitError(undefined), 5000);
    }
  }

  return (
    <div className={style.form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.contentContainer}>
          <div>
            <InputGroupTag
              klassName={style.inputGroupTag}
              id="phone-number"
              type="text"
              name="phoneNumber"
              labelText={t('features.merchantEditor.phoneNumber')}
              autoComplete={false}
              error={errors.phoneNumber}
              ref={register(MerchantPhoneNumberValidate)}
            />
            {submitError && (
              <span className={style.error}>{t(`${submitError}`)}</span>
            )}
          </div>
          <div className={style.buttonContainer}>
            <Button
              klassName={style.submitButton}
              title={t('features.merchantEditor.invite')}
              type="submit"
              loading={loading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
