export default function setPageTitle(title?: string) {
  let pageTitle;
  if (title && title.length > 0) {
    pageTitle = `${title} - econnect`;
  } else {
    pageTitle = 'econnect';
  }

  document.title = pageTitle;
}
