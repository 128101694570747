import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PromotionCreateDataType } from '../../services/api/types/card';
import InputGroupTag from '../Form/components/InputGroupTag';
import DateTimeInputGroupTag from '../Form/components/DateTimeInputGroupTag';
import CKEditorGroupTag from '../Form/components/CKEditorGroupTag';
import {
  DescriptionValidate,
  RequiredValidate,
  TITLE_MAX_LENGTH,
  TitleValidate,
} from '../../utils/form/validate';
import CardForm from '../CardForm';
import PromotionTypeInput from './components/PromotionTypeInput';

interface PropsType {
  onSubmit: (data: PromotionCreateDataType) => void;
  submitError: string;
  loading: boolean;
}

export default function CardPromotionForm(props: PropsType) {
  const { t } = useTranslation();
  const { onSubmit, submitError, loading } = props;
  const methods = useFormContext<PromotionCreateDataType>();
  const { register, control, errors, handleSubmit, setValue, watch } = methods;
  const { title } = watch();

  return (
    <CardForm
      onSubmit={handleSubmit(onSubmit)}
      setValue={setValue}
      loading={loading}
      submitError={submitError}
      errors={errors}
      register={register}
    >
      <InputGroupTag
        id="title"
        name="title"
        labelText={t('components.cardPromotion.title')}
        maxLength={TITLE_MAX_LENGTH}
        error={errors.title}
        autoComplete={false}
        ref={register(TitleValidate)}
        defaultValue={title}
      />

      <DateTimeInputGroupTag
        id="expiry-time"
        name="expiry"
        type="date"
        control={control}
        labelText={t('components.cardPromotion.expiryTime')}
        error={errors.expiry}
        rules={RequiredValidate}
        placeHolder={t('placeholders.clickToSelect')}
      />

      <PromotionTypeInput
        register={register}
        error={errors}
        control={control}
      />

      <CKEditorGroupTag
        name="description"
        labelText={t('components.cardPromotion.description')}
        error={errors.description}
        rules={DescriptionValidate}
        control={control}
      />
    </CardForm>
  );
}
