import React from 'react';
import { useTranslation } from 'react-i18next';
import { Coupon } from '../../../types';
import style from '../style.module.scss';

interface PropsType {
  listCoupons?: Coupon[];
  handleCouponIdChange: (id: any) => void;
  value: any;
}

export default function CouponSelection(props: PropsType) {
  const { t } = useTranslation();
  const { listCoupons, handleCouponIdChange, value } = props;

  return (
    <div className={style.couponSelection}>
      <span>{t('features.merchantStats.couponSelections')}</span>

      <select
        onChange={(e) => handleCouponIdChange(e.target.value)}
        value={value}
      >
        <option value="">
          {t('features.merchantStats.allCouponSelected')}
        </option>
        {listCoupons &&
          listCoupons.map((item) => (
            <option key={item.id} value={item.id}>
              {item.title}
            </option>
          ))}
      </select>
    </div>
  );
}
